import { useEffect, useState } from "react";
import cssSpacingStyles from "../../../../../Global/Styles/spacing";
import { FormStatuses } from "../../../../../Global/Types/commonTypes";
import {
  ExcellenceChartProps,
  KpiWidgetData,
} from "../../../../ExcellenceWidgets/EditExcellenceChartForms/excellenceChartFormUtils";
import KpiWidget from "../../../../ExcellenceWidgets/KpiWidget/KpiWidget";
import {
  KpiDataSchema,
  KpiWidgetButtonTypes,
  KpiWidgetConfiguration,
} from "../../../../ExcellenceWidgets/KpiWidget/kpiWidgetTypes";
import { Box, useTheme } from "@mui/material";
import { useLazyQuery } from "@apollo/client";
import { graphQlQueryExcellenceIndicatorData } from "../../../../../GraphQL/Excellence/graphQLQueriesExcellence";
import Alert from "../../../../MaterialUI/Alert";
import LoadingBackdrop from "../../../../MaterialUI/LoadingBackdrop";
import kpiWidgetDefaultData from "../../../../ExcellenceWidgets/ExcellenceDefaultConfig/kpiWidgetDefaultData";
import { endOfMonth, startOfMonth, startOfYear, subMonths, subYears } from "date-fns";
import cssLayoutStyles from "../../../../../Global/Styles/layout";
import { defaultKpiData } from "../../../../ExcellenceWidgets/KpiWidget/KpiWidgetUtils";
import { useLanguageContext } from "../../../../../context/LanguageContext";
import {
  buildGraphqlFilters,
  GraphqlFilter,
} from "../../../../SmallComponents/GraphqlFilters/graphqlFiltersUtils";

interface ExcellenceIndicatorProps extends ExcellenceChartProps {
  data: KpiWidgetData | null;
  configuration: KpiWidgetConfiguration;
  schema: KpiDataSchema | null;
}

const ExcellenceKpi: React.FC<ExcellenceIndicatorProps> = ({
  className,
  data,
  configuration,
  schema,
  resetChartFetch,
  dataFetchFromParent,
  isStatic,
  tempFilters,
  refetchOnFilters,
}) => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = {
    ...cssSpacingStyles(theme),
    ...cssLayoutStyles,
  };
  const [updatedData, setUpdatedData] = useState<KpiWidgetData | null>(data);
  const [fetchStatus, setFetchStatus] = useState<FormStatuses>(null);
  const [chartToUse, setChartToUse] = useState<boolean>(true);
  const [initialFetchDone, setInitialFetchDone] = useState<boolean>(false);

  const [getIndicatorData, { loading: loadingPieData }] = useLazyQuery(
    graphQlQueryExcellenceIndicatorData
  );

  useEffect(() => {
    (async () => {
      if (initialFetchDone) {
        if (schema) {
          await handleFetch(schema, tempFilters);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchOnFilters]);

  useEffect(() => {
    setChartToUse((prev) => !prev);
  }, [updatedData]);

  useEffect(() => {
    (async () => {
      if (!dataFetchFromParent && schema) {
        await handleFetch(schema, configuration.graphqlFilters);
        setInitialFetchDone(true);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFetchFromParent, resetChartFetch]);

  useEffect(() => {
    if (dataFetchFromParent) {
      setUpdatedData(data);
    }
  }, [dataFetchFromParent, data]);

  const handleFetch = async (
    dataSchema: KpiDataSchema,
    graphqlFilters?: GraphqlFilter[] | undefined
  ) => {
    try {
      setFetchStatus("loading");
      const result = await getIndicatorData({
        variables: {
          input: {
            ...dataSchema,
            filters: buildGraphqlFilters(graphqlFilters),
          },
        },
      });

      if (!result.data || result.error) {
        throw new Error(JSON.stringify(result));
      }
      const resultData = result.data.indicatorData.data;

      setUpdatedData(resultData);
      setFetchStatus(null);
    } catch (err) {
      console.log("err ", err);
      setFetchStatus("error");
    }
  };

  const handleButtonClick = async (type: KpiWidgetButtonTypes) => {
    let startDate = new Date();
    let endDate = new Date();

    let selectedData: number[] = [];
    const currentMonth = new Date().getMonth() + 1;
    const monthsFromLastYear = 12 + currentMonth;
    const totalDataPoints = defaultKpiData.length;

    const dataPointsPerMonth = totalDataPoints / monthsFromLastYear;

    switch (type) {
      case "lastMonth": {
        startDate = startOfMonth(subMonths(new Date(), 1));
        endDate = endOfMonth(subMonths(new Date(), 1));
        if (!schema) {
          const lastMonthDataIndex = Math.floor(
            dataPointsPerMonth * (monthsFromLastYear - 2)
          );
          const currentMonthDataIndex = Math.floor(
            dataPointsPerMonth * (monthsFromLastYear - 1)
          );
          selectedData = defaultKpiData.slice(
            lastMonthDataIndex,
            currentMonthDataIndex
          );
        }
        break;
      }
      case "thisMonth": {
        startDate = startOfMonth(new Date());
        endDate = new Date();
        if (!schema) {
          selectedData = defaultKpiData.slice(
            Math.floor(dataPointsPerMonth * (monthsFromLastYear - 1)),
            totalDataPoints
          );
        }
        break;
      }
      case "lastYear": {
        startDate = startOfYear(subYears(new Date(), 1));
        endDate = subYears(new Date(), 1);
        if (!schema) {
          selectedData = defaultKpiData.slice(
            0,
            Math.floor(12 * dataPointsPerMonth)
          );
        }
        break;
      }
      case "thisYear": {
        startDate = startOfYear(new Date());
        endDate = new Date();
        if (!schema) {
          selectedData = defaultKpiData.slice(
            Math.floor(12 * dataPointsPerMonth),
            totalDataPoints
          );
        }
        break;
      }
    }

    if (!schema) {
      const defaultDataUpdate: KpiWidgetData = {
        ...kpiWidgetDefaultData,
        chart: selectedData,
      };
      setUpdatedData(defaultDataUpdate);
    } else {
      const dataSchema = {
        ...schema,
        startTime: startDate.toISOString(),
        endTime: endDate.toISOString(),
      };

      await handleFetch(dataSchema);
    }
  };

  return (
    <Box component="div" css={[styles.width100, styles.height100, styles.relative]}>
      {chartToUse &&
        <KpiWidget
          className={className}
          configuration={configuration}
          // data={updatedData || kpiWidgetDefaultData}
          data={schema && updatedData ? updatedData : kpiWidgetDefaultData}
          handleButtonClick={handleButtonClick}
          dataSchema={schema}
        />
      }
      {!chartToUse &&
        <KpiWidget
          className={className}
          configuration={configuration}
          // data={updatedData || kpiWidgetDefaultData}
          data={schema && updatedData ? updatedData : kpiWidgetDefaultData}
          handleButtonClick={handleButtonClick}
          dataSchema={schema}
        />
      }

      {isStatic ? null : <LoadingBackdrop loading={loadingPieData} />}

      <Alert
        css={styles.reverseContentBreak}
        message={t("Something went wrong")}
        showAlert={fetchStatus === "error"}
        severity={"error"}
      />
    </Box>
  );
};

export default ExcellenceKpi;
