import React, { useEffect, useRef, useState } from "react";
import { Box, Grid, IconButton, Stack, Typography, useTheme } from "@mui/material";
import { ResponsiveRadialBar } from "@nivo/radial-bar";
import { OEERadialChartType, RadialChartConfigType, TimePeriodType, getChartColor, getDatesForPeriod } from "../../oEEUtils";
import cssSpacingStyles from "../../../../../Global/Styles/spacing";
import cssComponentsStyles from "../../../../../Global/Styles/components";
import { format } from "date-fns";
import { useLanguageContext } from "../../../../../context/LanguageContext";
import callApi from "../../../../../Api/callApi";
import { getOverallOEEChartData } from "../../../../../Api/OEE/apiOEEGetQueries";
import { useAuthedContext } from "../../../../../context/AuthContext";
import { PeriodModeDates } from "../../../../SmallComponents/DynamicGridLayout.tsx/dynamicGridExcellenceUtils";
import ChartPeriodViewer from "../../../../SmallComponents/DynamicGridLayout.tsx/ChartPeriodViewer";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import { GetQueryOEERadialChartsDataTypeWithTarget } from "../../../../../Api/OEE/apiOEESnippets";
import { FormStatuses } from "../../../../../Global/Types/commonTypes";
import Alert from "../../../../MaterialUI/Alert";
import { SerializedStyles } from "@emotion/react";

interface OEERadialChartProps {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  config: RadialChartConfigType;
  onUpdateConfig?: (updatedConfig: Partial<RadialChartConfigType>) => void;
  isStatic?: boolean;
}

const OEERadialChart: React.FC<OEERadialChartProps> = ({ config, onUpdateConfig, isStatic }) => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = {
    ...cssSpacingStyles(theme),
    ...cssComponentsStyles(theme),
  };
  const { setAuthedUser } = useAuthedContext();
   const [period, setPeriod] = useState<TimePeriodType | null>(config.period || "M");
  const [dates, setDates] = useState<PeriodModeDates | null>({
    startDate: new Date(config.startDate),
    endDate: new Date(config.endDate),
  });
  const [isChartPeriodViewerVisible, setIsChartPeriodViewerVisible] =
    useState<boolean>(false);
  const [selectedTimeChartData, setSelectedTimeChartData] = useState<OEERadialChartType>({
    chart_data: [],
  });
  const [chartValues, setChartValues] = useState<Record<string, number>>();
  const [formStatus, setFormStatus] = useState<FormStatuses>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);

  useEffect(() => {
    fetchChartData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config]);

  useEffect(() => {
    if (dates?.startDate && dates?.endDate && onUpdateConfig) {
      onUpdateConfig({
        startDate: dates.startDate.toISOString(),
        endDate: dates.endDate.toISOString(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dates]);

  const firstUpdate = useRef(true);

  const fetchChartData = async () => {
    try {
      setFormStatus(null);
      setAlertMessage(null);
      if (config?.startDate && config.endDate) {
        let startDate = config.startDate;
        let endDate = config.endDate;
  
        if(firstUpdate.current) {
          firstUpdate.current = false
          const dates = getDatesForPeriod(config.period || "month")

          setDates({startDate: dates.start, endDate: dates.end})
        }

        const formattedStartDate = new Date(startDate).toISOString();
        const formattedEndDate = new Date(endDate).toISOString();

        const data = await callApi<GetQueryOEERadialChartsDataTypeWithTarget>({
          query: getOverallOEEChartData(formattedStartDate, formattedEndDate),
          auth: { setAuthedUser },
        });

        setSelectedTimeChartData(data);
        const values = data.chart_data.reduce((acc, curr) => {
          acc[curr.id] = curr.data[0].y;
          return acc;
        }, {} as Record<string, number>);
        setChartValues(values);
      }
    } catch (error) {
      console.error("Error fetching Radial chart data: ", error);
      setFormStatus("error");
      setAlertMessage("Something went wrong");
    }
  };

  const toggleChartPeriodViewer = () => {
    setIsChartPeriodViewerVisible((prev) => !prev);
  };

  return (
    <>
      <Grid container justifyContent="center" alignItems="center" spacing={2}>
        {isChartPeriodViewerVisible && dates ? (
          <Grid item>
            <Stack alignItems="flex-end">
              <ChartPeriodViewer
                dates={dates}
                setDates={setDates}
                period={period}
                setPeriod={setPeriod}
              />
            </Stack>
          </Grid>
        ) : dates ? (
          <Grid item>
            <Typography variant="body2" color={theme.palette.grey[700]}>
              {`${format(dates.startDate, "dd.MM.yyyy")} - ${format(
                dates.endDate,
                "dd.MM.yyyy"
              )}`}
            </Typography>
          </Grid>
        ) : null}

        {!isStatic && <Grid item>
          <IconButton
            aria-label="toggle period viewer"
            onClick={toggleChartPeriodViewer}
            size="small"
          >
            <SwapHorizIcon
              css={styles.greyIcon}
              style={{
                color: isChartPeriodViewerVisible ? theme.palette.primary.main : "",
              }}
            />
          </IconButton>
        </Grid>}
      </Grid>
      {selectedTimeChartData ? (
        <>
          <Box component="div" height="280px" position="relative">
            <ResponsiveRadialBar
              isInteractive={!isStatic}
              data={selectedTimeChartData.chart_data}
              valueFormat=">-.2f"
              padding={0.2}
              cornerRadius={3}
              endAngle={360}
              maxValue={100}
              innerRadius={0.4}
              colors={({ id }) => {
                const mainId = (id as string).split(".")[0];
                return getChartColor(mainId);
              }}
              borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
              radialAxisStart={null}
              enableRadialGrid={false}
              enableCircularGrid={false}
              layers={["bars", "labels"]}
              margin={{ top: 0, right: 10, bottom: 0, left: 10 }}
              theme={{
                text: {
                  fill: theme.palette.common.black,
                },
                tooltip: {
                  container: {
                    background: theme.palette.common.white,
                  },
                },
              }}
            />
            <Box
              component="div"
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                textAlign: "center",
              }}
            >
              <Typography variant="h3" style={{ color: theme.palette.common.black }}>
                {selectedTimeChartData.oee}%
              </Typography>
            </Box>
          </Box>

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            mt={2}
            mr={2}
            ml={2}
          >
            <Box
              component="div"
              textAlign="center"
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Typography variant="body2">{t("Availability")}</Typography>
              <Typography variant="h4" style={{ color: getChartColor("Availability") }}>
                {chartValues?.Availability}%
              </Typography>
            </Box>
            <Box
              component="div"
              textAlign="center"
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Typography variant="body2">{t("Performance")}</Typography>
              <Typography variant="h4" style={{ color: getChartColor("Performance") }}>
                {chartValues?.Performance}%
              </Typography>
            </Box>
            <Box
              component="div"
              textAlign="center"
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Typography variant="body2">{t("Quality")}</Typography>
              <Typography variant="h4" style={{ color: getChartColor("Quality") }}>
                {chartValues?.Quality}%
              </Typography>
            </Box>
            <Box
              component="div"
              textAlign="center"
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Typography variant="body2">{t("Target")}</Typography>
              <Typography variant="h4" color={theme.palette.common.black}>
                {config.target}%
              </Typography>
            </Box>
          </Stack>
        </>
      ) : (
        !alertMessage && (
          <Typography variant="body2" mt={2} mb={1}>
            {t("Loading...")}
          </Typography>
        )
      )}
      <Box component="div" mt={1}>
        <Alert
          message={alertMessage || ""}
          showAlert={!!alertMessage}
          severity={formStatus}
        />
      </Box>
    </>
  );
};

export default OEERadialChart;
