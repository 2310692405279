import { Grid, Stack, useTheme } from "@mui/material";
import cssSpacingStyles from "../../../../../Global/Styles/spacing";
import { useState } from "react";
import {
  BarChartForLossesByType,
  BarChartForLossesByTypeConfigType,
} from "../../oEEUtils";
import Button from "../../../../MaterialUI/Button";
import { useLanguageContext } from "../../../../../context/LanguageContext";
import cssLayoutStyles from "../../../../../Global/Styles/layout";
import { OEEChartFormProps } from "../oeeDashboardUtils";
import { DynamicGridOEEChartOptions } from "../../../../SmallComponents/DynamicGridLayout.tsx/dynamicGridUtils";
import TextField from "../../../../MaterialUI/FormFields/TextFields";

interface EditBarChartLossesByTypeFormProps extends Omit<OEEChartFormProps, "data"> {
  data: BarChartForLossesByType;
  config: BarChartForLossesByTypeConfigType;
}

const EditBarChartLossesByTypeForm: React.FC<EditBarChartLossesByTypeFormProps> = ({
  data,
  config,
  widgetTitle,
  handleSaveChanges,
}) => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = {
    ...cssSpacingStyles(theme),
    ...cssLayoutStyles,
  };
  const [title, setTitle] = useState<string>(widgetTitle);

  const handleSubmit = () => {
    const updatedChart: DynamicGridOEEChartOptions = {
      barChartLossesByType: {
        data,
        config,
      },
    };
    handleSaveChanges(updatedChart, title);
  };

  return (
    <Stack alignItems="center" justifyContent="center">
      <Grid container spacing={3} mb={3}>
        <Grid item xs={6}>
          <TextField
            label={t("Title")}
            onChange={(e) => setTitle(e.target.value)}
            value={title}
          />
        </Grid>
      </Grid>

      <Button css={styles.widthLimit50} onClick={handleSubmit}>
        {t("Save Changes")}
      </Button>
    </Stack>
  );
};

export default EditBarChartLossesByTypeForm;
