import { IconButton, Stack, Typography } from "@mui/material";
import Crop169OutlinedIcon from "@mui/icons-material/Crop169Outlined";
import { useLanguageContext } from "../../../../context/LanguageContext";
import DataUsageOutlinedIcon from "@mui/icons-material/DataUsageOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import FormatAlignLeftOutlinedIcon from "@mui/icons-material/FormatAlignLeftOutlined";
import InsertChartOutlinedIcon from "@mui/icons-material/InsertChartOutlined";
import LegendToggleOutlinedIcon from "@mui/icons-material/LegendToggleOutlined";
import LeaderboardOutlinedIcon from "@mui/icons-material/LeaderboardOutlined";
import SignalCellularAltOutlinedIcon from "@mui/icons-material/SignalCellularAltOutlined";
import ViewTimelineOutlinedIcon from "@mui/icons-material/ViewTimelineOutlined";
import { OEEChartKeyType } from "./oeeDashboardUtils";

interface AddOEEChartIconButtonProps {
  type: OEEChartKeyType;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
}

const AddOEEChartIconButton: React.FC<AddOEEChartIconButtonProps> = ({
  type,
  onClick,
  disabled,
}) => {
  const { t } = useLanguageContext();

  switch (type) {
    // OEE widgets
    case "bulletChart": {
      return (
        <Stack alignItems="center">
          <IconButton aria-label="bullet chart" onClick={onClick} disabled={disabled}>
            <Crop169OutlinedIcon />
          </IconButton>
          <Typography
            variant="caption"
            align="center"
            whiteSpace="pre-line"
            color={disabled ? "textSecondary" : "textPrimary"}
          >
            {t("Bullet Chart")}
          </Typography>
        </Stack>
      );
    }
    case "radialChart": {
      return (
        <Stack alignItems="center">
          <IconButton aria-label="radial chart" onClick={onClick} disabled={disabled}>
            <DataUsageOutlinedIcon />
          </IconButton>
          <Typography
            variant="caption"
            align="center"
            whiteSpace="pre-line"
            color={disabled ? "textSecondary" : "textPrimary"}
          >
            {t("Overall")}
          </Typography>
        </Stack>
      );
    }
    case "availabilityBarChart": {
      return (
        <Stack alignItems="center">
          <IconButton aria-label="radial chart" onClick={onClick} disabled={disabled}>
            <BarChartOutlinedIcon />
          </IconButton>
          <Typography
            variant="caption"
            align="center"
            whiteSpace="pre-line"
            color={disabled ? "textSecondary" : "textPrimary"}
          >
            {t("Availability")}
          </Typography>
        </Stack>
      );
    }
    case "assetByHourBarChart": {
      return (
        <Stack alignItems="center">
          <IconButton aria-label="radial chart" onClick={onClick} disabled={disabled}>
            <FormatAlignLeftOutlinedIcon />
          </IconButton>
          <Typography
            variant="caption"
            align="center"
            whiteSpace="pre-line"
            color={disabled ? "textSecondary" : "textPrimary"}
          >
            {t("Asset By Hour")}
          </Typography>
        </Stack>
      );
    }
    case "paretoBarChart": {
      return (
        <Stack alignItems="center">
          <IconButton aria-label="radial chart" onClick={onClick} disabled={disabled}>
            <InsertChartOutlinedIcon />
          </IconButton>
          <Typography
            variant="caption"
            align="center"
            whiteSpace="pre-line"
            color={disabled ? "textSecondary" : "textPrimary"}
          >
            {t("Pareto")}
          </Typography>
        </Stack>
      );
    }
    case "lineChartForAsset": {
      return (
        <Stack alignItems="center">
          <IconButton aria-label="radial chart" onClick={onClick} disabled={disabled}>
            <LegendToggleOutlinedIcon />
          </IconButton>
          <Typography
            variant="caption"
            align="center"
            whiteSpace="pre-line"
            color={disabled ? "textSecondary" : "textPrimary"}
          >
            {t("Asset Chart")}
          </Typography>
        </Stack>
      );
    }
    case "barChartForAssetByType": {
      return (
        <Stack alignItems="center">
          <IconButton aria-label="radial chart" onClick={onClick} disabled={disabled}>
            <LeaderboardOutlinedIcon />
          </IconButton>
          <Typography
            variant="caption"
            align="center"
            whiteSpace="pre-line"
            color={disabled ? "textSecondary" : "textPrimary"}
          >
            {t("Asset By Type")}
          </Typography>
        </Stack>
      );
    }
    case "barChartLossesByType": {
      return (
        <Stack alignItems="center">
          <IconButton aria-label="radial chart" onClick={onClick} disabled={disabled}>
            <SignalCellularAltOutlinedIcon />
          </IconButton>
          <Typography
            variant="caption"
            align="center"
            whiteSpace="pre-line"
            color={disabled ? "textSecondary" : "textPrimary"}
          >
            {t("Losses By Type")}
          </Typography>
        </Stack>
      );
    }
    case "barChartLossesByPeriod": {
      return (
        <Stack alignItems="center">
          <IconButton aria-label="radial chart" onClick={onClick} disabled={disabled}>
            <ViewTimelineOutlinedIcon />
          </IconButton>
          <Typography
            variant="caption"
            align="center"
            whiteSpace="pre-line"
            color={disabled ? "textSecondary" : "textPrimary"}
          >
            {t("Losses By Period")}
          </Typography>
        </Stack>
      );
    }
    default:
      return (
        <Typography variant="caption" color="error">
          {t("Invalid Chart Type")}
        </Typography>
      );
  }
};

export default AddOEEChartIconButton;
