import {
  Box,
  ClickAwayListener,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { SelectGroupedOption } from "../../../Global/Types/commonTypes";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useEffect, useState } from "react";
import SelectGrouped from "../../MaterialUI/FormFields/SelectGrouped";
import { formatDateAndTime } from "../../../Global/Utils/commonFunctions";
import {
  add,
  endOfDay,
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  endOfYear,
  startOfDay,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
  subDays,
  subMonths,
  subQuarters,
  subWeeks,
  subYears,
} from "date-fns";
import { PeriodModeDates } from "./dynamicGridExcellenceUtils";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import useTheme from "@mui/material/styles/useTheme";
import cssComponentsStyles from "../../../Global/Styles/components";
import { TimePeriodType } from "../../PageComponents/OEE/oEEUtils";

const PERIOD_OPTIONS: SelectGroupedOption[] = [
  {
    groupName: "Period from now",
    options: [
      { value: "h", description: "Hour" },
      { value: "d", description: "Day" },
      { value: "w", description: "Week" },
      { value: "M", description: "Month" },
      { value: "y", description: "Year" },
    ],
  },
  {
    groupName: "Calendar period",
    options: [
      { value: "day", description: "Day" },
      { value: "week", description: "Week" },
      { value: "month", description: "Month" },
      { value: "quarter", description: "Quarter" },
      { value: "year", description: "Year" },
    ],
  },
];

interface ChartPeriodViewerProps {
  dates: PeriodModeDates;
  setDates: React.Dispatch<React.SetStateAction<PeriodModeDates | null>>;
  isOEEComponent?: boolean;
  period: TimePeriodType | null;
  setPeriod: React.Dispatch<React.SetStateAction<TimePeriodType | null>>;
}

const ChartPeriodViewer: React.FC<ChartPeriodViewerProps> = ({
  dates,
  setDates,
  period,
  setPeriod
}) => {
  const theme = useTheme();
  const styles = {
    ...cssComponentsStyles(theme),
  };
  const [interval, setInterval] = useState<number>(0);
  const [openTooltip, setOpenTooltip] = useState(false);

  useEffect(() => {
    const currentDate = new Date();
    let startDate = new Date();
    let endDate = new Date();
    const startDateInterval = -(interval + 1);
    const endDateInterval = -interval;

    switch (period) {
      case "h": {
        startDate = add(currentDate, { hours: startDateInterval });
        endDate = add(currentDate, { hours: endDateInterval });
        break;
      }
      case "d": {
        startDate = add(currentDate, { days: startDateInterval });
        endDate = add(currentDate, { days: endDateInterval });
        break;
      }
      case "w": {
        startDate = add(currentDate, { weeks: startDateInterval });
        endDate = add(currentDate, { weeks: endDateInterval });
        break;
      }
      case "M": {
        startDate = add(currentDate, { months: startDateInterval });
        endDate = add(currentDate, { months: endDateInterval });
        break;
      }
      case "y": {
        startDate = add(currentDate, { years: startDateInterval });
        endDate = add(currentDate, { years: endDateInterval });
        break;
      }
      case "day": {
        startDate = startOfDay(subDays(currentDate, interval));
        endDate = endOfDay(subDays(currentDate, interval));
        break;
      }
      case "week": {
        startDate = startOfWeek(subWeeks(currentDate, interval), { weekStartsOn: 1 });
        endDate = endOfWeek(subWeeks(currentDate, interval), { weekStartsOn: 1 });
        break;
      }
      case "month": {
        startDate = startOfMonth(subMonths(currentDate, interval));
        endDate = endOfMonth(subMonths(currentDate, interval));
        break;
      }
      case "quarter": {
        startDate = startOfQuarter(subQuarters(currentDate, interval));
        endDate = endOfQuarter(subQuarters(currentDate, interval));
        break;
      }
      case "year": {
        startDate = startOfYear(subYears(currentDate, interval));
        endDate = endOfYear(subYears(currentDate, interval));
        break;
      }
      default: {
      }
    }

    setDates({ startDate, endDate });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period, interval]);

  const handleChangePeriod = (value: TimePeriodType) => {
    setPeriod(value);
    setInterval(0);
  };

  const handleChangeInterval = (direction: "back" | "next") => {
    if (direction === "back") {
      setInterval((prev) => prev + 1);
    }
    if (direction === "next") {
      if (interval >= 0) {
        setInterval((prev) => prev - 1);
      }
    }
  };

  return (
    <Stack spacing={1.25} direction="row" alignItems="center">
      <IconButton
        aria-label="previous period"
        onClick={() => handleChangeInterval("back")}
      >
        <ArrowBackIcon />
      </IconButton>

      <Box component="div" style={{ minWidth: "90px" }}>
        <SelectGrouped
          groupedOptions={PERIOD_OPTIONS}
          value={period}
          onChange={(e) => handleChangePeriod(e.target.value as TimePeriodType)}
        />
      </Box>

      <IconButton
        aria-label="next period"
        disabled={interval <= 0}
        onClick={() => handleChangeInterval("next")}
      >
        <ArrowForwardIcon />
      </IconButton>

      <ClickAwayListener onClickAway={() => setOpenTooltip(false)}>
        <div>
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            onClose={() => setOpenTooltip(false)}
            open={openTooltip}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            arrow
            title={
              <Typography variant="body1" whiteSpace="pre-line">
                {` Start date: ${formatDateAndTime(dates.startDate)} 
               End date: ${formatDateAndTime(dates.endDate)}`}
              </Typography>
            }
          >
            <IconButton size="small" onClick={() => setOpenTooltip(true)}>
              <InfoOutlinedIcon
                style={{ marginRight: "3px" }}
                css={[styles.greyIcon, styles.infoIcon]}
              />
            </IconButton>
          </Tooltip>
        </div>
      </ClickAwayListener>
    </Stack>
  );
};

export default ChartPeriodViewer;
