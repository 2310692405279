import { Box, Grid, IconButton, Stack, Typography, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import {
  calculateAveragePercentage,
  getDatesForPeriod,
  LineChartForAssetConfigType,
  OEESingleAssetDataType,
  TimePeriodType,
} from "../../oEEUtils";
import cssSpacingStyles from "../../../../../Global/Styles/spacing";
import cssComponentsStyles from "../../../../../Global/Styles/components";
import { format } from "date-fns";
import { ResponsiveLine } from "@nivo/line";
import { PeriodModeDates } from "../../../../SmallComponents/DynamicGridLayout.tsx/dynamicGridExcellenceUtils";
import ChartPeriodViewer from "../../../../SmallComponents/DynamicGridLayout.tsx/ChartPeriodViewer";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import { FormStatuses } from "../../../../../Global/Types/commonTypes";
import { GetQueryOEEChartByAsset } from "../../../../../Api/OEE/apiOEESnippets";
import callApi from "../../../../../Api/callApi";
import { getOEEByAsset } from "../../../../../Api/OEE/apiOEEGetQueries";
import { useAuthedContext } from "../../../../../context/AuthContext";
import { useLanguageContext } from "../../../../../context/LanguageContext";
import Alert from "../../../../MaterialUI/Alert";
import { extractUnitSymbol } from "../../../../SmallComponents/UnitsOfMeasureDropdown/unitsOfMeasureDropdownUtils";
import { SerializedStyles } from "@emotion/react";

interface OEELineChartForSingleAssetProps {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  config: LineChartForAssetConfigType;
  onUpdateConfig?: (updatedConfig: Partial<LineChartForAssetConfigType>) => void;
  isStatic?: boolean;
}

const OEELineChartForSingleAsset: React.FC<OEELineChartForSingleAssetProps> = ({
  config,
  onUpdateConfig,
  isStatic
}) => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = {
    ...cssSpacingStyles(theme),
    ...cssComponentsStyles(theme),
  };
  const { setAuthedUser } = useAuthedContext();
  const [barChartData, setBarChartData] = useState<OEESingleAssetDataType[]>([]);
  const [period, setPeriod] = useState<TimePeriodType | null>(config.period || "M");
  const [dates, setDates] = useState<PeriodModeDates | null>({
    startDate: new Date(config.startDate),
    endDate: new Date(config.endDate),
  });
  const [isChartPeriodViewerVisible, setIsChartPeriodViewerVisible] =
    useState<boolean>(false);
  const [formStatus, setFormStatus] = useState<FormStatuses>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);

  const gridLineColor =
    theme.palette.mode === "light" ? theme.palette.grey[200] : theme.palette.grey[900];

  useEffect(() => {
    fetchOEEData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config]);

  useEffect(() => {
    if(onUpdateConfig) {
      onUpdateConfig({
        period: period
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period])

  useEffect(() => {
    if (dates?.startDate && dates?.endDate && onUpdateConfig) {
      onUpdateConfig({
        startDate: dates.startDate.toISOString(),
        endDate: dates.endDate.toISOString(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dates]);

  const firstUpdate = useRef(true);

  const fetchOEEData = async () => {
    try {
      setFormStatus(null);
      setAlertMessage(null);

      if (config?.startDate && config.endDate) {
        let startDate = config.startDate;
        let endDate = config.endDate;
        
        if(firstUpdate.current) {
          firstUpdate.current = false
          const dates = getDatesForPeriod(config.period || "month")
          setDates({startDate: dates.start, endDate: dates.end})
        } 
        const formattedStartDate = format(new Date(startDate), "yyyy-MM-dd");
        const formattedEndDate = format(new Date(endDate), "yyyy-MM-dd");

        const data = await callApi<GetQueryOEEChartByAsset>({
          query: getOEEByAsset(config.assetId, formattedStartDate, formattedEndDate),
          auth: { setAuthedUser },
        });

        setBarChartData(data.chart_data);
      }
    } catch (error) {
      console.log("There was an error fetching assets ", error);
      setFormStatus("error");
      setAlertMessage("Something went wrong");
    }
  };

  const toggleChartPeriodViewer = () => {
    setIsChartPeriodViewerVisible((prev) => !prev);
  };

  const markers: any =
    config.high === null && config.low === null
      ? []
      : [
          ...(config.low !== null
            ? [
                {
                  axis: "y" as const,
                  legend: t("Low"),
                  legendPosition: "right",
                  lineStyle: {
                    stroke: "#BD4F4F",
                    strokeWidth: 1,
                  },
                  textStyle: {
                    fill: "#BD4F4F",
                    fontSize: "13px",
                  },
                  value: config.low,
                },
              ]
            : []),
          ...(config.high !== null
            ? [
                {
                  axis: "y" as const,
                  legend: t("High"),
                  legendPosition: "right",
                  lineStyle: {
                    stroke: "#33A161",
                    strokeWidth: 1,
                  },
                  textStyle: {
                    fill: "#33A161",
                    fontSize: "13px",
                  },
                  value: config.high,
                },
              ]
            : []),
        ];

  return (
    <Box component="div" ml={2}>
      <Grid container justifyContent="flex-end" alignItems="center">
        <Grid item mr={2}>
          <Grid container alignItems="center" spacing={2}>
            {isChartPeriodViewerVisible && dates ? (
              <Grid item>
                <Stack alignItems="flex-end">
                  <ChartPeriodViewer
                    dates={dates}
                    setDates={setDates}
                    period={period}
                    setPeriod={setPeriod}
                  />
                </Stack>
              </Grid>
            ) : dates ? (
              <Grid item>
                <Typography variant="body2" color={theme.palette.grey[700]}>
                  {`${format(dates.startDate, "dd.MM.yyyy")} - ${format(
                    dates.endDate,
                    "dd.MM.yyyy"
                  )}`}
                </Typography>
              </Grid>
            ) : null}

            {!isStatic && <Grid item>
              <IconButton
                aria-label="toggle period viewer"
                onClick={toggleChartPeriodViewer}
                size="small"
              >
                <SwapHorizIcon
                  css={styles.greyIcon}
                  style={{
                    color: isChartPeriodViewerVisible ? theme.palette.primary.main : "",
                  }}
                />
              </IconButton>
            </Grid>}
          </Grid>
        </Grid>
      </Grid>

      <Stack alignItems="flex-end" mr={1} mt={1}>
        <Typography variant="h2">
          {calculateAveragePercentage(barChartData)?.percentage?.toFixed(2)}%
        </Typography>
      </Stack>

      {barChartData.length > 0 ? (
        <Box component="div" height="240px">
          <ResponsiveLine
            isInteractive={!isStatic}
            data={barChartData}
            margin={{ top: 20, right: 50, bottom: 30, left: 40 }}
            enablePoints={false}
            enableGridX={false}
            colors={"#387CB8"}
            theme={{
              text: {
                fill: theme.palette.common.black,
              },
              tooltip: {
                container: {
                  background: theme.palette.common.white,
                },
              },
              grid: {
                line: {
                  stroke: gridLineColor,
                  strokeWidth: 1,
                },
              },
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              format: (value) => `${value}${extractUnitSymbol(config.unit || "")}`,
            }}
            lineWidth={2.5}
            markers={markers}
          />
        </Box>
      ) : (
        !alertMessage && (
          <Typography variant="body2" mt={2} mb={1}>
            {t("Loading...")}
          </Typography>
        )
      )}
      <Box component="div" mt={1}>
        <Alert
          message={alertMessage || ""}
          showAlert={!!alertMessage}
          severity={formStatus}
        />
      </Box>
    </Box>
  );
};

export default OEELineChartForSingleAsset;
