import React, { useEffect, useRef, useState } from "react";
import { Box, Grid, IconButton, Typography, useTheme } from "@mui/material";
import {
  assetDataColors,
  BarChartAssetByHoursConfigType,
  calculateWorkingAndNonWorkingMinutes,
  HourData,
  TransformedHourData,
} from "../../oEEUtils";
import { ResponsiveBar } from "@nivo/bar";
import { parseSecondsToHMS } from "../../../../../Global/Utils/commonFunctions";
import cssSpacingStyles from "../../../../../Global/Styles/spacing";
import cssComponentsStyles from "../../../../../Global/Styles/components";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { format, subDays, addDays, isToday } from "date-fns";
import { FormStatuses } from "../../../../../Global/Types/commonTypes";
import callApi from "../../../../../Api/callApi";
import { GetQueryOEEAssetStatusHourly } from "../../../../../Api/OEE/apiOEESnippets";
import { getAssetStatusHourly } from "../../../../../Api/OEE/apiOEEGetQueries";
import { useAuthedContext } from "../../../../../context/AuthContext";
import { useLanguageContext } from "../../../../../context/LanguageContext";
import Alert from "../../../../MaterialUI/Alert";
import { SerializedStyles } from "@emotion/react";
import DatePicker from "../../../../MaterialUI/DateTimePickers/DatePicker";

interface OEEBarChartAssetByHourProps {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  config: BarChartAssetByHoursConfigType;
  onUpdateConfig?: (updatedConfig: Partial<BarChartAssetByHoursConfigType>) => void;
  isStatic?: boolean;
}

const OEEBarChartAssetByHour: React.FC<OEEBarChartAssetByHourProps> = ({
  config,
  onUpdateConfig,
  isStatic
}) => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = {
    ...cssSpacingStyles(theme),
    ...cssComponentsStyles(theme),
  };
  const { setAuthedUser } = useAuthedContext();
  const [barChartData, setBarChartData] = useState<HourData[]>([]);
  const [formStatus, setFormStatus] = useState<FormStatuses>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);

  const firstUpdate = useRef(true)
  const date = isStatic ? new Date() : config.date

  const fetchOEEData = async () => {
    try {
      setFormStatus(null);
      setAlertMessage(null);

     if(firstUpdate.current && !isStatic) {
        firstUpdate.current = false;
        handleDateChange(new Date());
      }

      const formattedDate = format(new Date(date), "yyyy-MM-dd");
      const data = await callApi<GetQueryOEEAssetStatusHourly>({
        query: getAssetStatusHourly(config.assetId, formattedDate),
        auth: { setAuthedUser },
      });

      setBarChartData(data.chart_data);
    } catch (error) {
      console.log("There was an error fetching assets ", error);
      setFormStatus("error");
      setAlertMessage("Something went wrong");
    }
  };

  useEffect(() => {
    fetchOEEData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config]);

  const handleDateChange = (newDate: Date) => {
    if(onUpdateConfig) {
      onUpdateConfig({ date: newDate.toISOString() });
    }
  };

  // const handlePrevDay = () => handleDateChange(subDays(date, 1));
  // const handleNextDay = () => handleDateChange(addDays(date, 1));

  const handlePrevDay = () => {
    const prevDay = subDays(new Date(date), 1); // subDays takes a Date
    if(onUpdateConfig) {
      onUpdateConfig({ date: prevDay.toISOString() });
    }
  };

  const handleNextDay = () => {
    const nextDay = addDays(new Date(date), 1);
    if(onUpdateConfig) {
      onUpdateConfig({ date: nextDay.toISOString() });
    }
  };

  const transformedData = barChartData.map((entry) => {
    const transformedEntry: TransformedHourData = { hour: entry.hour };
    entry.values.forEach((item, index) => {
      transformedEntry[`${item.category} ${index + 1}`] = item.value / 60;
    });
    return transformedEntry;
  });

  const sortedData = transformedData.slice().sort((a, b) => a.hour.localeCompare(b.hour));

  return (
    <Box component="div" ml={2}>
      <Grid container justifyContent={isStatic ? "flex-end" : "flex-end"} alignItems="center" mb={1}>
        <Grid item mr={2}>
          {!isStatic && <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <IconButton aria-label="previous day" onClick={handlePrevDay} size="small">
                <ArrowBackIosIcon css={styles.greyIcon} fontSize="small" />
              </IconButton>
            </Grid>
            <Grid item>
              <DatePicker
                label="Date"
                value={new Date(date)}
                onChange={(val) => {
                  if (val) {
                    handleDateChange(val);
                  }
                }}
              />
            </Grid>
            <Grid item>
              <IconButton
                aria-label="next day"
                onClick={handleNextDay}
                size="small"
                disabled={isToday(new Date(date))}
              >
                <ArrowForwardIosIcon css={styles.greyIcon} fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>}
        </Grid>
        {isStatic && 
        <Grid item>
          <Typography variant="body2" color={theme.palette.grey[700]}>
            {`${format(new Date(date), "dd.MM.yyyy")}`}
          </Typography>
        </Grid>}
      </Grid>
      {barChartData.length > 0
        ? sortedData.map((entry, index) => {
            const entryKeys = Object.keys(entry).filter((key) => key !== "hour");
            const { actualWorkingMinutes, maxPossibleWorkingMinutes } =
              calculateWorkingAndNonWorkingMinutes(entry);

            return (
              <Box
                component="div"
                key={index}
                height={index === 0 || index === sortedData.length - 1 ? "35px" : "25px"}
              >
                <ResponsiveBar
                  isInteractive={!isStatic}
                  data={[entry]}
                  keys={entryKeys}
                  indexBy="hour"
                  margin={{
                    top: index === 0 ? 20 : 0,
                    right: 60,
                    bottom: index === sortedData.length - 1 ? 20 : 0,
                    left: 40,
                  }}
                  layout="horizontal"
                  colors={({ id }) =>
                    assetDataColors[
                      (id as string).split(" ")[0] as keyof typeof assetDataColors
                    ]
                  }
                  theme={{
                    text: {
                      fill: theme.palette.common.black,
                    },
                    tooltip: {
                      container: {
                        background: theme.palette.common.white,
                      },
                    },
                  }}
                  padding={0.2}
                  label={({ id }) =>
                    id.toString().includes("Working") ||
                    id.toString().includes("SpeedLoss")
                      ? ""
                      : id.toString().split(" ")[0]
                  }
                  tooltip={({ id, value }) => (
                    <div
                      style={{
                        padding: "3px 10px",
                        background: theme.palette.common.white,
                        border: `1px solid ${theme.palette.divider}`,
                      }}
                    >
                      <strong>{id.toString().split(" ")[0]}</strong>:{" "}
                      {parseSecondsToHMS(value)}
                    </div>
                  )}
                  axisTop={
                    index === 0
                      ? {
                          tickValues: [15, 30, 45, 60],
                          tickSize: 5,
                          tickPadding: 5,
                          tickRotation: 0,
                          legendPosition: "middle",
                          legendOffset: -36,
                        }
                      : null
                  }
                  axisRight={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legendPosition: "middle",
                    legendOffset: 40,
                    format: () =>
                      `${actualWorkingMinutes} / ${maxPossibleWorkingMinutes}`,
                  }}
                  axisBottom={
                    index === sortedData.length - 1
                      ? {
                          tickSize: 5,
                          tickPadding: 5,
                          tickRotation: 0,
                          legendPosition: "middle",
                          legendOffset: 32,
                        }
                      : null
                  }
                  axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legendPosition: "middle",
                    legendOffset: -40,
                  }}
                />
              </Box>
            );
          })
        : !alertMessage && (
            <Typography variant="body2" mt={2} mb={1}>
              {t("Loading...")}
            </Typography>
          )}
      <Box component="div" mt={1}>
        <Alert
          message={alertMessage || ""}
          showAlert={!!alertMessage}
          severity={formStatus}
        />
      </Box>
    </Box>
  );
};

export default OEEBarChartAssetByHour;
