import { Box, Grid, Stack, useTheme } from "@mui/material";
import { Formik, FormikHelpers } from "formik";
import {
  AddNewLossFormValues,
  EditNewLossFormDataValues,
  ModalTitle,
  applyCapitalizationOnOptions,
  formatLossesDate,
  getCategoryType,
  typeOptions,
} from "../oEEUtils";
import {
  YUP_DATE_REQUIRED,
  YUP_NUMBER_BETWEEN_0_100,
  YUP_REQUIRED_STRING,
  YUP_STRING,
} from "../../../../Global/Constants/yupConstants";
import { object } from "yup";
import { useEffect, useState } from "react";
import {
  AutocompleteGroupedOption,
  AutocompleteOption,
  FormStatuses,
  SelectOption,
} from "../../../../Global/Types/commonTypes";
import cssLayoutStyles from "../../../../Global/Styles/layout";
import cssSpacingStyles from "../../../../Global/Styles/spacing";
import cssComponentsStyles from "../../../../Global/Styles/components";
import Button from "../../../MaterialUI/Button";
import Alert from "../../../MaterialUI/Alert";
import Select from "../../../MaterialUI/FormFields/Select";
import Autocomplete from "../../../MaterialUI/FormFields/Autocomplete";
import AutocompleteGrouped from "../../../MaterialUI/FormFields/AutocompleteGrouped";
import DateAndTimePicker from "../../../MaterialUI/DateTimePickers/DateAndTimePicker";
import TextField from "../../../MaterialUI/FormFields/TextFields";
import {
  GetQueryOEEMappedAssetsToCategoriesSnippet,
  GetQueryOEEMappedCategoriesWithSubcategoriesSnippet,
} from "../../../../Api/OEE/apiOEESnippets";
import { PostQueryCreateLossInput } from "../../../../Api/OEE/apiOEEInputs";
import callApi from "../../../../Api/callApi";
import { postQueryCreateLoss, putQueryLoss } from "../../../../Api/OEE/apiOEEPostQueries";
import { useAuthedContext } from "../../../../context/AuthContext";
import { getQueryMappedAssetToCategory } from "../../../../Api/OEE/apiOEEGetQueries";
import Checkbox from "../../../MaterialUI/FormFields/Checkbox";
import { useDetectFormsUnsavedChanges } from "../../../../Global/Hooks/useDetectFormsUnsavedChanges";
import { useLanguageContext } from "../../../../context/LanguageContext";

const fieldValidation = object({
  asset: YUP_REQUIRED_STRING,
  type: YUP_REQUIRED_STRING,
  category: YUP_REQUIRED_STRING,
  subcategory_id: YUP_REQUIRED_STRING,
  quantity: YUP_NUMBER_BETWEEN_0_100,
  start_time: YUP_DATE_REQUIRED,
  end_time: YUP_DATE_REQUIRED,
  note: YUP_STRING,
});

interface OEEAddLossFormProps {
  assetTypeOptions: AutocompleteOption[];
  oEEMainCategoriesOptions: AutocompleteGroupedOption[];
  oEEMappedCategoriesWithSubcategories: GetQueryOEEMappedCategoriesWithSubcategoriesSnippet;
  setModalTitle: React.Dispatch<React.SetStateAction<ModalTitle | null>>;
  setOpenInfo?: React.Dispatch<React.SetStateAction<boolean>>;
  fetchOEEData: () => void;
  lossData?: EditNewLossFormDataValues;
  lossId?: string;
  handleSetUnsavedChanges: (unsavedChanges: boolean) => void;
  setUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
}

const OEEAddLossForm: React.FC<OEEAddLossFormProps> = ({
  assetTypeOptions,
  oEEMainCategoriesOptions,
  oEEMappedCategoriesWithSubcategories,
  setModalTitle,
  setOpenInfo,
  fetchOEEData,
  lossData,
  lossId,
  handleSetUnsavedChanges,
  setUnsavedChanges,
}) => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = {
    ...cssLayoutStyles,
    ...cssSpacingStyles(theme),
    ...cssComponentsStyles(theme),
  };
  const { setAuthedUser } = useAuthedContext();
  const [formStatus, setFormStatus] = useState<FormStatuses>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [proceedWithoutLossPeriod, setProceedWithoutLossPeriod] = useState<boolean>(
    () => {
      if (!lossData) {
        return false;
      }
      const result = !(lossData.end_time && lossData.start_time);
      return result;
    }
  );
  const [selectedSubcategories, setSelectedSubcategories] = useState<
    AutocompleteGroupedOption[]
  >([]);
  const [selectedCategories, setSelectedCategories] = useState<
    AutocompleteGroupedOption[]
  >([]);
  const [filteredSubcategories, setFilteredSubcategories] = useState<
    AutocompleteGroupedOption[]
  >([]);
  const [filteredCategories, setFilteredCategories] = useState<
    AutocompleteGroupedOption[]
  >([]);
  const [selectedTypes, setSelectedTypes] = useState<AutocompleteOption[]>([]);
  const [selectedAsset, setSelectedAsset] = useState<string>("");
  const [isQualityCategory, setIsQualityCategory] = useState<boolean>(false);

  const initialValues: AddNewLossFormValues = {
    asset:
      assetTypeOptions.find((asset) => asset.description === lossData?.asset)
        ?.description || selectedAsset,
    type: lossData?.type || "",
    category: lossData?.maincategory || "",
    subcategory_id: lossData?.subcategory || "",
    quantity: lossData?.quantity || 0,
    start_time: lossData?.start_time ? new Date(lossData.start_time) : null,
    end_time: lossData?.end_time ? new Date(lossData.end_time) : null,
    note: lossData?.note || "",
  };

  useEffect(() => {
    const transformedSubcategories = oEEMappedCategoriesWithSubcategories.flatMap(
      (category) =>
        category.subcategories.map((subcategory) => ({
          value: subcategory.id,
          groupName: category.name,
          description: subcategory.name,
        }))
    );
    setFilteredSubcategories(transformedSubcategories);
  }, [oEEMappedCategoriesWithSubcategories]);

  useEffect(() => {
    if (selectedCategories.length > 0) {
      const filtered = oEEMappedCategoriesWithSubcategories.flatMap((category) =>
        selectedCategories.some(
          (selectedCategory) => selectedCategory.description === category.name
        )
          ? category.subcategories.map((subcategory) => ({
              value: subcategory.id,
              groupName: category.name,
              description: subcategory.name,
            }))
          : []
      );
      setFilteredSubcategories(filtered);
    } else {
      const transformedSubcategories = oEEMappedCategoriesWithSubcategories.flatMap(
        (category) =>
          category.subcategories.map((subcategory) => ({
            value: subcategory.id,
            groupName: category.name,
            description: subcategory.name,
          }))
      );
      setFilteredSubcategories(transformedSubcategories);
    }
  }, [selectedCategories, oEEMappedCategoriesWithSubcategories]);

  useEffect(() => {
    const isQuality = selectedSubcategories.some((subcategory) =>
      oEEMappedCategoriesWithSubcategories.some(
        (category) =>
          category.name === subcategory.groupName && category.type === "quality"
      )
    );
    setIsQualityCategory(isQuality);
  }, [selectedSubcategories, oEEMappedCategoriesWithSubcategories]);

  useEffect(() => {
    if (selectedTypes.length > 0) {
      const filtered = oEEMainCategoriesOptions.filter((category) =>
        selectedTypes.some((type) => type.value === category.groupName)
      );
      setFilteredCategories(filtered);
    } else {
      setFilteredCategories(oEEMainCategoriesOptions);
    }
  }, [selectedTypes, oEEMainCategoriesOptions]);

  const handleFormSubmit = async (values: AddNewLossFormValues) => {
    try {
      setFormStatus("loading");
      setAlertMessage(null);

      const requests = selectedSubcategories.map(async (subcategory) => {
        if (
          !values.asset ||
          !values.type ||
          !values.category ||
          !values.subcategory_id ||
          !values.note
        ) {
          throw new Error("All required fields must be filled out");
        }

        const categoryType = getCategoryType(
          values.category,
          oEEMainCategoriesOptions,
          lossData
        );

        const formattedValues: PostQueryCreateLossInput = {
          asset_id: values.asset,
          category: subcategory.value,
          note: values.note,
          ...(categoryType === "quality" && { quantity: values.quantity }),
          ...(proceedWithoutLossPeriod === false && {
            start_time: values.start_time ? formatLossesDate(values.start_time) : "",
            end_time: values.end_time ? formatLossesDate(values.end_time) : "",
          }),
        };

        if (lossData && lossId) {
          return callApi<string>({
            query: putQueryLoss(formattedValues, lossId),
            auth: { setAuthedUser },
          });
        } else {
          return callApi<string>({
            query: postQueryCreateLoss(formattedValues),
            auth: { setAuthedUser },
          });
        }
      });

      await Promise.all(requests);

      setFormStatus("success");
      setUnsavedChanges(false);
      fetchOEEData();
      setModalTitle(null);
      if (setOpenInfo !== undefined) {
        setOpenInfo(false);
      }
    } catch (error) {
      console.error("OEEAddLossForm handleFormSubmit error:", error);
      setFormStatus("error");
      setAlertMessage(t("Something went wrong"));
    }
  };

  const handleOnSubcategoriesChange = (
    val: AutocompleteGroupedOption[],
    setFieldValue: FormikHelpers<AddNewLossFormValues>["setFieldValue"]
  ) => {
    setSelectedSubcategories(val || []);
    if (val && val.length > 0) {
      const mainCategory = oEEMainCategoriesOptions.find(
        (category) => category.description === val[0].groupName
      );
      if (
        mainCategory &&
        !selectedCategories.some(
          (category) => category.description === mainCategory.description
        )
      ) {
        setSelectedCategories([...selectedCategories, mainCategory]);
        setFieldValue(
          "category",
          [...selectedCategories, mainCategory].map((cat) => cat.value).join("")
        );
        const type = typeOptions.find((type) => type.value === mainCategory.groupName);
        if (type && !selectedTypes.some((t) => t.value === type.value)) {
          setSelectedTypes([...selectedTypes, type]);
          setFieldValue("type", [...selectedTypes, type].map((t) => t.value).join(""));
        }
      }
      setFieldValue("subcategory_id", val[0].value);
    } else {
      setFieldValue("subcategory_id", "");
    }
  };

  const handleOnCategoriesChange = (
    val: AutocompleteGroupedOption[],
    setFieldValue: FormikHelpers<AddNewLossFormValues>["setFieldValue"]
  ) => {
    setSelectedCategories(val || []);
    if (val.length === 0) {
      const transformedSubcategories = oEEMappedCategoriesWithSubcategories.flatMap(
        (category) =>
          category.subcategories.map((subcategory) => ({
            value: subcategory.id,
            groupName: category.name,
            description: subcategory.name,
          }))
      );
      setFilteredSubcategories(transformedSubcategories);
      setSelectedSubcategories([]);
      setFieldValue("category", "");
    } else {
      const filtered = oEEMappedCategoriesWithSubcategories.flatMap((category) =>
        val.some((selectedCategory) => selectedCategory.description === category.name)
          ? category.subcategories.map((subcategory) => ({
              value: subcategory.id,
              groupName: category.name,
              description: subcategory.name,
            }))
          : []
      );
      setFilteredSubcategories(filtered);
      const filteredSelectedSubcategories = selectedSubcategories.filter((subcategory) =>
        val.some((category) => category.description === subcategory.groupName)
      );
      setSelectedSubcategories(filteredSelectedSubcategories);
      setFieldValue("category", val.map((cat) => cat.value).join(""));
      setFieldValue(
        "subcategory_id",
        filteredSelectedSubcategories.map((subcat) => subcat.value).join("")
      );

      const uniqueTypes = [...new Set(val.map((category) => category.groupName))];

      const filteredTypeOptions = typeOptions.filter((option) =>
        uniqueTypes.includes(option.description)
      );

      setSelectedTypes(
        filteredTypeOptions.map((option) => ({
          value: option.value,
          description: option.description,
        }))
      );
      setFieldValue("type", filteredTypeOptions.map((option) => option.value).join(", "));
    }
  };

  const handleOnTypesChange = (
    val: SelectOption[],
    setFieldValue: FormikHelpers<AddNewLossFormValues>["setFieldValue"]
  ) => {
    setSelectedTypes(val || []);
    setFieldValue("type", val.map((type) => type.value).join(", "));
    if (val.length > 0) {
      const filteredCategories = oEEMainCategoriesOptions.filter((category) =>
        val.some((type) => type.value === category.groupName)
      );
      setFilteredCategories(filteredCategories);
      const filteredSelectedCategories = selectedCategories.filter((category) =>
        filteredCategories.some(
          (filteredCategory) => filteredCategory.value === category.value
        )
      );
      setSelectedCategories(filteredSelectedCategories);
      setFieldValue(
        "category",
        filteredSelectedCategories.map((cat) => cat.value).join("")
      );
      const filteredSelectedSubcategories = selectedSubcategories.filter((subcategory) =>
        filteredSelectedCategories.some(
          (category) => category.description === subcategory.groupName
        )
      );
      setSelectedSubcategories(filteredSelectedSubcategories);
      setFieldValue(
        "subcategory_id",
        filteredSelectedSubcategories.map((subcat) => subcat.value).join("")
      );
    } else {
      setFilteredCategories(oEEMainCategoriesOptions);
      setSelectedCategories([]);
      setFilteredSubcategories([]);
      setSelectedSubcategories([]);
      setFieldValue("category", "");
      setFieldValue("subcategory_id", "");
    }
  };

  const handleOnAssetChange = async (
    val: string,
    setFieldValue: FormikHelpers<AddNewLossFormValues>["setFieldValue"]
  ) => {
    setSelectedAsset(val);
    setFieldValue("asset", val);

    const assetsAddedToCategories =
      await callApi<GetQueryOEEMappedAssetsToCategoriesSnippet>({
        query: getQueryMappedAssetToCategory(val),
        auth: { setAuthedUser },
      });

    const allTypes = new Set();
    assetsAddedToCategories.main_categories.forEach((mainCategory) => {
      mainCategory.sub_categories.forEach((subCategory) => {
        allTypes.add(subCategory.type);
      });
    });

    const subcategoryIds = new Set();
    assetsAddedToCategories.main_categories.forEach((mainCategory) => {
      mainCategory.sub_categories.forEach((subCategory) => {
        allTypes.add(subCategory.type);
        subcategoryIds.add(subCategory.id);
      });
    });

    const subcategories = oEEMappedCategoriesWithSubcategories.flatMap((category) =>
      category.subcategories
        .filter((subcategory) => subcategoryIds.has(subcategory.id))
        .map((subcategory) => ({
          value: subcategory.id,
          groupName: category.name,
          description: subcategory.name,
        }))
    );

    setFilteredSubcategories(subcategories);
  };

  return (
    <Box component="div" pr={2}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        validationSchema={fieldValidation}
      >
        {({ handleSubmit, handleChange, touched, errors, values, setFieldValue }) => {
          useDetectFormsUnsavedChanges(initialValues, values, handleSetUnsavedChanges);

          useEffect(
            () => {
              if (lossData) {
                const assetOption = assetTypeOptions.find(
                  (option) => option.description === lossData.asset
                );
                const categoryOption = oEEMainCategoriesOptions.find(
                  (option) => option.description === lossData.maincategory
                );
                const subcategoryOption = oEEMappedCategoriesWithSubcategories
                  .flatMap((category) => category.subcategories)
                  .find((subcat) => subcat.name === lossData.subcategory);
                const typeOption = typeOptions.find(
                  (type) => type.value === lossData.type
                );

                if (assetOption) {
                  setSelectedAsset(assetOption.value);
                  setFieldValue("asset", assetOption.value);
                }
                if (typeOption) {
                  setSelectedTypes([typeOption!]);
                }
                if (categoryOption) {
                  setSelectedCategories([categoryOption]);
                }
                if (subcategoryOption && categoryOption) {
                  setSelectedSubcategories([
                    {
                      description: subcategoryOption.name,
                      groupName: categoryOption.description,
                      value: subcategoryOption.id,
                    },
                  ]);
                }
              }
            },
            // eslint-disable-next-line react-hooks/exhaustive-deps
            []
          );

          return (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Select
                    selectOptions={assetTypeOptions}
                    name="asset"
                    label={t("Select Loss Asset")}
                    error={touched["asset"] && !!errors["asset"]}
                    helperText={touched["asset"] && errors["asset"]}
                    onChange={(e) => handleOnAssetChange(e.target.value, setFieldValue)}
                    value={values.asset}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Autocomplete
                    css={styles.width100}
                    label={t("Type")}
                    multiple
                    options={typeOptions}
                    value={selectedTypes}
                    handleOnChange={(val) => handleOnTypesChange(val, setFieldValue)}
                    disabled={formStatus === "loading"}
                  />
                </Grid>

                <Grid item xs={12}>
                  <AutocompleteGrouped
                    css={styles.width100}
                    label={t("Loss Category")}
                    multiple
                    options={applyCapitalizationOnOptions(filteredCategories)}
                    value={selectedCategories}
                    handleOnChange={(val) => handleOnCategoriesChange(val, setFieldValue)}
                    disabled={formStatus === "loading"}
                  />
                </Grid>

                <Grid item xs={12}>
                  <AutocompleteGrouped
                    css={styles.width100}
                    label={t("Subcategories")}
                    multiple
                    options={filteredSubcategories}
                    value={selectedSubcategories}
                    handleOnChange={(val) =>
                      handleOnSubcategoriesChange(val, setFieldValue)
                    }
                    disabled={formStatus === "loading"}
                  />
                </Grid>
                {isQualityCategory && (
                  <>
                    <Grid item xs={12}>
                      <TextField
                        name="quantity"
                        label={t("Quantity")}
                        numberField={true}
                        error={touched["quantity"] && !!errors["quantity"]}
                        helperText={touched["quantity"] && errors["quantity"]}
                        onChange={handleChange}
                        value={values.quantity}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Checkbox
                        label={t("Proceed without loss period")}
                        checked={proceedWithoutLossPeriod}
                        onChange={(e) => setProceedWithoutLossPeriod(e.target.checked)}
                      />
                    </Grid>
                  </>
                )}

                {!proceedWithoutLossPeriod && (
                  <>
                    <Grid item xs={6}>
                      <DateAndTimePicker
                        name="start_time"
                        label={t("Start")}
                        error={touched["start_time"] && !!errors["start_time"]}
                        helperText={touched["start_time"] && errors["start_time"]}
                        onChange={(value) => setFieldValue("start_time", value)}
                        value={values.start_time}
                        css={styles.width100}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <DateAndTimePicker
                        name="end_time"
                        label={t("End")}
                        error={touched["end_time"] && !!errors["end_time"]}
                        helperText={touched["end_time"] && errors["end_time"]}
                        onChange={(value) => setFieldValue("end_time", value)}
                        value={values.end_time}
                        css={styles.width100}
                      />
                    </Grid>
                  </>
                )}

                <Grid item xs={12}>
                  <TextField
                    name="note"
                    label={t("Explanation")}
                    error={touched["note"] && !!errors["note"]}
                    helperText={touched["note"] && errors["note"]}
                    onChange={handleChange}
                    value={values.note}
                  />
                </Grid>
              </Grid>

              <Stack spacing={1} justifyContent={"center"} alignItems={"center"} mt={2}>
                <Button
                  type="submit"
                  onClick={() => handleFormSubmit(values)}
                  loading={formStatus === "loading"}
                  css={[styles.width100, styles.widthLimit10]}
                >
                  {lossData ? t("Edit") : t("Add")} {t("Loss")}
                </Button>

                <Alert
                  message={alertMessage || ""}
                  showAlert={!!alertMessage}
                  severity={formStatus}
                />
              </Stack>
            </form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default OEEAddLossForm;
