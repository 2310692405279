import { OEEBulletChartType } from "../../../../Api/OEE/apiOEEDataTypes";
import { OEEWidget } from "../../../../Api/OEE/apiOEESnippets";
import { WidgetGridItem } from "../../../LargeComponents/WidgetsGrid/widgetsGridUtils";
import {
  AvailabilityBarChartConfig,
  BarChartAssetByHoursConfigType,
  BarChartAssetByHoursType,
  BarChartForAssetByTypeConfigType,
  BarChartForAssetsByTypeDataType,
  BarChartForLossesByPeriodConfigType,
  BarChartForLossesByType,
  BarChartForLossesByTypeConfigType,
  BarChartLossesByPeriod,
  BulletChartConfigType,
  LineChartForAssetConfigType,
  OEEAssetChartType,
  OEERadialChartType,
  OEESingleAssetDataChart,
  ParetoBarChartConfigType,
  ParetoChartType,
  RadialChartConfigType,
} from "../oEEUtils";
import { DynamicGridHandleSaveOEEChartData } from "../../../SmallComponents/DynamicGridLayout.tsx/dynamicGridUtils";

export type OEEGridLayoutSettings = {
  name: string;
  id: string | number;
  can_add: boolean;
  can_edit: boolean;
  can_delete: boolean;
};

export const modalTitleTranslations = {
  "Edit layout configuration": "Edit layout configuration",
  "Confirm layout delete": "Confirm layout delete",
} as const;

export type ModalTitle = keyof typeof modalTitleTranslations;

export type OEEGridItemData = {
  widget: {
    oeeChart?: OEEChartOptions;
  };
  loading?: boolean;
  title: string;
  id?: string | number;
};

export type OEEChartKeyType = keyof OEEChartOptions;

export type OEEChartOptions = {
  bulletChart?: BulletChartDataType;
  radialChart?: RadialChartDataType;
  availabilityBarChart?: AvailabilityBarChartDataType;
  assetByHourBarChart?: AssetByHourBarChartDataType;
  paretoBarChart?: ParetoBarChartDataType;
  lineChartForAsset?: LineChartForAssetDataType;
  barChartForAssetByType?: BarChartForAssetByTypeDataType;
  barChartLossesByType?: BarChartForLossesByTypeDataType;
  barChartLossesByPeriod?: BarChartLossesByPeriodDataType;
};

export type BulletChartDataType = {
  data: OEEBulletChartType;
  config: BulletChartConfigType;
};
export type RadialChartDataType = {
  data: OEERadialChartType;
  config: RadialChartConfigType;
};
export type AvailabilityBarChartDataType = {
  data: OEEAssetChartType;
  config: AvailabilityBarChartConfig;
};
export type AssetByHourBarChartDataType = {
  data: BarChartAssetByHoursType;
  config: BarChartAssetByHoursConfigType;
};
export type ParetoBarChartDataType = {
  data: ParetoChartType;
  config: ParetoBarChartConfigType;
};
export type LineChartForAssetDataType = {
  data: OEESingleAssetDataChart;
  config: LineChartForAssetConfigType;
};
export type BarChartForAssetByTypeDataType = {
  data: BarChartForAssetsByTypeDataType;
  config: BarChartForAssetByTypeConfigType;
};
export type BarChartForLossesByTypeDataType = {
  data: BarChartForLossesByType;
  config: BarChartForLossesByTypeConfigType;
};
export type BarChartLossesByPeriodDataType = {
  data: BarChartLossesByPeriod;
  config: BarChartForLossesByPeriodConfigType;
};

export type ExtractChartConfig<T> = T extends { config: infer C } ? C : never;

export const oeeHandlePrepareWidgetsData = (
  data: OEEWidget[]
): WidgetGridItem<OEEGridItemData>[] => {
  const result = data.map((widget) => {
    const chart = widget?.content?.widget?.oeeChart;
    const loading = Boolean(chart?.bulletChart);

    return {
      layout: widget.content?.layout,
      loading,
      id: widget.id,
      widget: widget.content.widget,
      title: widget.content.title,
    };
  });

  return result;
};

export interface OEEChartFormProps {
  handleSaveChanges: DynamicGridHandleSaveOEEChartData;
  widgetTitle: string;
}
