import { TrendDirection } from "../IndicatorWidget/indicatorWidgetUtils";
import { KpiWidgetConfiguration } from "../KpiWidget/kpiWidgetTypes";

const kpiWidgetDefaultConfiguration: KpiWidgetConfiguration = {
  positiveDirection: TrendDirection.UP,
  valueType: "Average",
  title: "Kpi Title",
  subtitle: "Subtitle",
  unitsOfMeasure: "",
  symbol: "",
  decimalPoints: 0,
  tolerance: 5,
};

export default kpiWidgetDefaultConfiguration;
