import useTheme from "@mui/material/styles/useTheme";
import { SerializedStyles, css } from "@emotion/react";
import {
  IndicatorWidgetConfiguration,
} from "./indicatorWidgetTypes";
import { Box, Stack, Theme, Typography } from "@mui/material";
import { IndicatorWidgetData } from "../EditExcellenceChartForms/excellenceChartFormUtils";
import indicatorWidgetDefaultData from "../ExcellenceDefaultConfig/indicatorWidgetDefaultData";
import { formatNumber } from "../../../Global/Utils/commonFunctions";
import { useSubscription } from "@apollo/client";
import { INDICATOR_LIVE_DATA_SUBSCRIPTION } from "../../../GraphQL/Excellence/graphQLSubscriptions";
import { useLanguageContext } from "../../../context/LanguageContext";

const cssStyles = (theme: Theme) => ({
  stackContainerStyle: css({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 20,
  }),
  subtitleStyle: css({
    color: theme.palette.text.secondary,
    marginTop: 4,
  }),
});

interface IndicatorWidgetProps {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  configuration: IndicatorWidgetConfiguration;
}

const IndicatorWidget: React.FC<IndicatorWidgetProps> = ({
  configuration,
}) => {
  const theme = useTheme();
  const styles = {
    ...cssStyles(theme),
  };
  const { t } = useLanguageContext();

  const { data, loading } = useSubscription<IndicatorWidgetData>(INDICATOR_LIVE_DATA_SUBSCRIPTION, {
    variables: {
      input: {
        parameterId: configuration?.selectedParameter?.parameterId,
        container: configuration?.selectedParameter?.container
      },
    },
  });
  const dataToUse = data || indicatorWidgetDefaultData;
  const displayedValue = getDisplayedValue(dataToUse, configuration);

  return (
    <Stack p={2} spacing={4}>
      {loading && (
        <Typography component="p" variant="body1">
          {t("Loading...")}
        </Typography>
      )}
      {!loading && (
        <>
          <Stack css={styles.stackContainerStyle}>
            <Stack spacing={3}>
              <Typography variant="h1">{displayedValue}</Typography>
              <Box component="div">
                <Typography variant="h4">{configuration.title}</Typography>
                <Typography variant="body2" css={styles.subtitleStyle}>
                  {configuration.subtitle}
                </Typography>
              </Box>
            </Stack>
          </Stack>
        </>
      )}
    </Stack>
  );
};

export default IndicatorWidget;

const getDisplayedValue = (
  data: IndicatorWidgetData,
  configuration: IndicatorWidgetConfiguration
) => {
  if (!data) {
    return;
  }
  const val = data.simpleIndicatorLiveData.value;

  const getFormattedValue = () => {
    try {
      const result = formatNumber(
        val,
        configuration.fixedDecimalValue ? configuration.fixedDecimalValue : true,
        configuration.decimalPoints
      );
      if (!result) {
        return `${val}`;
      }
      return `${result}`;
    } catch (err) {
      return val;
    }
  };

  const formattedValue = getFormattedValue();

  return `${formattedValue} ${configuration.symbol.replace(/[()]/g, "")}`;
};
