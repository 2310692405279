import { format } from "date-fns";
import {
  GetQueryOEEAddedAssetsToCategoriesSnippet,
  GetQueryOEEAseetsFunctionalLocationsSnippet,
  GetQueryOEEMainCategoriesSnippet,
} from "../../../Api/OEE/apiOEESnippets";
import {
  AutocompleteGroupedOption,
  SelectOption,
} from "../../../Global/Types/commonTypes";
import { capitalizeFirstLetterOfEachWord } from "../../../Global/Utils/commonFunctions";
import {
  add,
  endOfDay,
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  endOfYear,
  startOfDay,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
  subDays,
  subMonths,
  subQuarters,
  subWeeks,
  subYears,
} from "date-fns";
import { getAssetStatusHourly } from "../../../Api/OEE/apiOEEGetQueries";

export const modalTitleTranslations = {
  "Edit OEE Chart": "Edit OEE Chart",
  "Copy OEE Chart": "Copy OEE Chart",
  "Delete OEE Chart": "Delete OEE Chart",
} as const;

export type OEEModalTitle = keyof typeof modalTitleTranslations;

export const getDefaultDates = () => ({
  startDate: startOfMonth(new Date()),
  endDate: new Date(),
});

export const getAssetsOptions = (
  oEEAssets: GetQueryOEEAseetsFunctionalLocationsSnippet
) => {
  const options = oEEAssets.map((asset) => ({
    value: asset.id,
    description: `${asset.functional_location}`,
  }));

  return options;
};

export const getAssetsToCategoriesOptions = (
  oEEAssetsToCategories: GetQueryOEEAddedAssetsToCategoriesSnippet
) => {
  const options = oEEAssetsToCategories.map((asset) => ({
    value: asset.asset_id,
    description: `${asset.functional_location} - ${asset.name}`,
  }));

  return options;
};

export const getOEEMainCategories = (
  mainCategories: GetQueryOEEMainCategoriesSnippet
): AutocompleteGroupedOption[] => {
  const groupedOptions = mainCategories.reduce((acc, category) => {
    const type = category.type;
    if (!acc[type]) {
      acc[type] = [];
    }
    acc[type].push({
      value: category.id,
      description: category.name,
      groupName: type,
    });
    return acc;
  }, {} as Record<string, AutocompleteGroupedOption[]>);

  const options: AutocompleteGroupedOption[] = Object.values(groupedOptions).flat();

  return options;
};

export const customParetoMarkers = [
  { value: 4000 },
  { value: 7500 },
  { value: 8500 },
  { value: 8600 },
  { value: 8700 },
  { value: 8900 },
  { value: 9200 },
  { value: 9400 },
];

export function extractValues(selectedSubcategories: AutocompleteGroupedOption[]) {
  return selectedSubcategories.map((item) => item.value);
}

export const getCategoryType = (
  value: string,
  oEEMainCategoriesOptions: AutocompleteGroupedOption[],
  lossData: EditNewLossFormDataValues | undefined
) => {
  if (lossData) {
    const category = oEEMainCategoriesOptions.find(
      (category) => category.description === value
    );
    return category?.groupName;
  }
  const category = oEEMainCategoriesOptions.find((category) => category.value === value);
  return category?.groupName;
};

export const formatLossesDate = (dateString: Date) => {
  const date = new Date(dateString);
  return format(date, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");
};

export const formatAssetDateToISO = (date: Date): string => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export const getDefaultSubcategoryValidFromDate = () => {
  const nextYear = new Date().getFullYear() + 1;
  return new Date(`${nextYear}-01-01`);
};

export const formatDate = (date: Date): string => {
  return date.toISOString().split("T")[0];
};

export const isCurrentYear = (date: Date) => {
  const currentYear = new Date().getFullYear();
  return date.getFullYear() === currentYear;
};

export const isNextYear = (date: Date) => {
  const nextYear = new Date().getFullYear() + 1;
  return date.getFullYear() === nextYear;
};

export const applyCapitalizationOnOptions = (options: AutocompleteGroupedOption[]) => {
  return options.map((option) => ({
    ...option,
    groupName: capitalizeFirstLetterOfEachWord(option.groupName.replace(/_/g, " ")),
  }));
};

export type HourData = {
  hour: string;
  values: { category: string; value: number }[];
};

export type BarChartAssetByHoursType = {
  asset: string;
  chartData: HourData[];
};

export type TransformedHourData = {
  hour: string;
  [key: string]: any;
};

export const assetDataColors = {
  Production: "#33A161",
  ProductChangeover: "#873838",
  Downtime: "#BD4F4F",
  SpeedLoss: "#FAC833",
  Break: "#555555",
};

export type HourDataBulletChart = {
  id: string;
  ranges: number[];
  measures: number[];
  markers: number[];
};

type DataPoint = {
  x: string;
  y: number;
};

type RadialBarDatum = {
  id: string;
  data: DataPoint[];
};

export type OEERadialChartType = {
  chart_data: RadialBarDatum[];
  oee?: number;
  target?: number;
};

export type RadialChartData = {
  name: string;
  value: number;
  fill: string;
};

export type ParetoChartDataType = {
  category: string;
  total_duration: number;
};

export type ParetoChartType = {
  asset: string;
  chartData: ParetoChartDataType[];
};

export type TimeRanges = "lastWeek" | "lastMonth";

export type AddNewSubcategoryFormValues = {
  name: string;
  description: string;
  valid_from: string | Date | null;
  main_category_id: string;
};

export type ConfigureNewAssetFormValues = {
  assetType: string;
  type: string;
  mainCategory_id: string;
  subcategory_id: string;
  inactive_from?: Date | null;
};

export type AddNewLossFormValues = {
  asset: string;
  type: string;
  category: string;
  subcategory_id: string;
  quantity: number;
  start_time: Date | null;
  end_time: Date | null;
  note: string;
};

export type EditNewLossFormDataValues = {
  id: string;
  asset: string;
  end_time: Date | null;
  start_time: Date | null;
  maincategory: string;
  subcategory: string;
  type: string;
  note: string;
  quantity: number;
};

export type AddNewBulletChart = {
  title: string;
  assetsIds: string;
};

export const oEEModalTitleTranslations = {
  "Add Subcategory": "Add Subcategory",
  "Add Asset": "Add Asset",
  "Add Loss": "Add Loss",
  "Add Dashboard": "Add Dashboard",
} as const;

export type ModalTitle = keyof typeof oEEModalTitleTranslations;

export const typeOptions = [
  { value: "availability", description: "Availability" },
  { value: "performance", description: "Performance" },
  { value: "quality", description: "Quality" },
  { value: "outside_plant_control", description: "Outside Plant Control" },
  { value: "undeclared", description: "Undeclared" },
];

export type OEEAssetChart = {
  date: string;
  workingTime: number;
};

export type OEEAssetChartType = OEEAssetChart[];

export type OEEAssetChartByType = {
  chart_data: OEEAssetChartType;
};

export type BarChartForAssetsDataType = {
  asset: string;
  Undeclared?: number;
  Availability?: number;
  Performance?: number;
  Quality?: number;
  "Outside Plant Control"?: number;
};

export type BarChartForAssetsByTypeDataType = BarChartForAssetsDataType[];

export type BarChartLossesByMainAndSubCategory = {
  type?: string;
  category?: string;
  subcategory?: string;
  duration?: number;
};

export type BarChartForLossesByType = BarChartLossesByMainAndSubCategory[];

export type BarChartLossesByPeriodType = {
  date: string;
  Undeclared: number;
  Availability: number;
  Performance: number;
  Quality: number;
  "Outside Plant Control": number;
};

export type BarChartLossesByPeriod = BarChartLossesByPeriodType[];

export type OEESingleAssetData = {
  x: string;
  y: number;
};

export type OEESingleAssetDataType = {
  id: string;
  data: OEESingleAssetData[];
};

export type OEESingleAssetDataChart = {
  chart_data: OEESingleAssetDataType[];
  high: number | null;
  low: number | null;
};

export type OEESingleAssetDataTypeWithPercentageType = {
  percentage: number;
  chartData: OEESingleAssetDataType[];
};

export type OEEChartsButtonTypes = "thisWeek" | "thisMonth";

export const calculateWorkingAndNonWorkingMinutes = (entry: TransformedHourData) => {
  let nonWorkingMinutes = 0;
  let speedLossMinutes = 0;

  for (const key of Object.keys(entry)) {
    if (key.includes("Production")) {
      continue;
    } else if (key.includes("SpeedLoss")) {
      speedLossMinutes += entry[key];
    } else if (key !== "hour") {
      nonWorkingMinutes += entry[key];
    }
  }

  const maxPossibleWorkingMinutes = 60 - nonWorkingMinutes;
  const actualWorkingMinutes = maxPossibleWorkingMinutes - speedLossMinutes;

  return {
    actualWorkingMinutes,
    maxPossibleWorkingMinutes,
  };
};

export const generateShades = (baseColor: string, count: number): string[] => {
  const shades: string[] = [];
  const baseR = parseInt(baseColor.slice(1, 3), 16);
  const baseG = parseInt(baseColor.slice(3, 5), 16);
  const baseB = parseInt(baseColor.slice(5, 7), 16);

  for (let i = 0; i < count; i++) {
    const factor = 1 - i / (count + 1);
    const r = Math.round(baseR * factor)
      .toString(16)
      .padStart(2, "0");
    const g = Math.round(baseG * factor)
      .toString(16)
      .padStart(2, "0");
    const b = Math.round(baseB * factor)
      .toString(16)
      .padStart(2, "0");
    shades.push(`#${r}${g}${b}`);
  }
  return shades;
};

export interface ColorMappingType {
  [key: string]: {
    baseColor: string;
    categories: { [key: string]: string };
    subcategories: { [key: string]: string };
  };
}

const createColorMapping = (): ColorMappingType => {
  const colorMapping: ColorMappingType = {
    Availability: {
      baseColor: "#BD4F4F",
      categories: {
        "Scheduled Downtime": "",
        "Unscheduled Downtime": "",
      },
      subcategories: {
        "Preventive Maintenance": "",
        "Equipment Failures": "",
        "Material Shortage": "",
        "Electrical Error": "",
        "Mechanical Error": "",
        Inspection: "",
      },
    },
    Quality: {
      baseColor: "#387CB8",
      categories: {
        Defects: "",
      },
      subcategories: {
        "Material Defects": "",
      },
    },
    Performance: {
      baseColor: "#33A161",
      categories: {
        "Small Stops": "",
        "Slow Cycles": "",
      },
      subcategories: {
        "Software/Application Stops": "",
        "Equipment Wear and Tear": "",
        "Slowdown due to Queueing": "",
      },
    },
    "Outside Plant Control": {
      baseColor: "#FAC833",
      categories: {},
      subcategories: {},
    },
    Undeclared: {
      baseColor: "#8C8C8C",
      categories: {
        Undeclared: "#CCCCCC",
      },
      subcategories: {
        Undeclared: "#E0E0E0",
      },
    },
  };

  Object.keys(colorMapping).forEach((type) => {
    const typeMapping = colorMapping[type];
    const categoryKeys = Object.keys(typeMapping.categories);
    const subcategoryKeys = Object.keys(typeMapping.subcategories);

    if (categoryKeys.length > 0) {
      const categoryShades = generateShades(typeMapping.baseColor, categoryKeys.length);
      categoryKeys.forEach((category, index) => {
        typeMapping.categories[category] = categoryShades[index];
      });
    }

    if (subcategoryKeys.length > 0) {
      const subcategoryShades = generateShades(
        typeMapping.baseColor,
        subcategoryKeys.length
      );
      subcategoryKeys.forEach((subcategory, index) => {
        typeMapping.subcategories[subcategory] = subcategoryShades[index];
      });
    }
  });

  return colorMapping;
};

export const colorMapping: ColorMappingType = createColorMapping();

export const getChartColor = (key: string): string => {
  if (colorMapping[key]) {
    return colorMapping[key].baseColor;
  }

  for (const type in colorMapping) {
    if (colorMapping[type].subcategories[key]) {
      const subcategoryKeys = Object.keys(colorMapping[type].subcategories);
      const index = subcategoryKeys.indexOf(key);
      const shades = generateShades(colorMapping[type].baseColor, subcategoryKeys.length);
      return shades[index];
    }
  }

  for (const type in colorMapping) {
    if (colorMapping[type].categories[key]) {
      const categoryKeys = Object.keys(colorMapping[type].categories);
      const index = categoryKeys.indexOf(key);
      const shades = generateShades(colorMapping[type].baseColor, categoryKeys.length);
      return shades[index];
    }
  }

  return colorMapping["Undeclared"].baseColor;
};

export const getOEEChartColors = (data: BarChartLossesByMainAndSubCategory) => {
  const { category } = data;
  const { subcategory } = data;
  const { type } = data;

  if (type) {
    if (colorMapping[type]) {
      return colorMapping[type].baseColor;
    }
  }
  if (category) {
    for (const type in colorMapping) {
      if (colorMapping[type].categories[category]) {
        return colorMapping[type].categories[category];
      }
    }
  }
  if (subcategory) {
    for (const type in colorMapping) {
      if (colorMapping[type].subcategories[subcategory]) {
        return colorMapping[type].subcategories[subcategory];
      }
    }
  }

  return colorMapping["Undeclared"].baseColor;
};

export type TimePeriodType = "h" | "d" | "w" | "M" | "y" | "hour" | "day" | "week" | "month" | "quarter" | "year";
export type LossesChartType = "sub_category" | "main_category" | "type";
export const chartTypeOptions: SelectOption[] = [
  {
    value: "type",
    description: "By Type",
  },
  {
    value: "main_category",
    description: "By Main Category",
  },
  {
    value: "sub_category",
    description: "By Subcategory",
  },
];

export type oEETypes = "availability" | "quality" | "performance";
export const OEETypesOptions: SelectOption[] = [
  {
    value: "availability",
    description: "Availability",
  },
  {
    value: "quality",
    description: "Quality",
  },
  {
    value: "performance",
    description: "Performance",
  },
];

export const calculateAveragePercentage = (nestedData: OEESingleAssetDataType[]) => {
  if (
    !nestedData ||
    nestedData.length === 0 ||
    !nestedData[0].data ||
    nestedData[0].data.length === 0
  ) {
    return null;
  }

  const data = nestedData[0].data;

  const yValues = data.map((item) => item.y);

  const average = yValues.reduce((sum, value) => sum + value, 0) / yValues.length;

  const minY = Math.min(...yValues);
  const maxY = Math.max(...yValues);

  const percentage = ((average - minY) / (maxY - minY)) * 100;

  return { average, percentage };
};

export const getDatesForPeriod = (period: string) => {
  const currentDate = new Date();
  const dates = {
    start: new Date(),
    end: new Date
  }
  const interval = 0;
  const startDateInterval = -(interval + 1);
  const endDateInterval = -interval;

   switch (period) {
        case "h": {
          dates.start = add(currentDate, { hours: startDateInterval });
          dates.end = add(currentDate, { hours: endDateInterval });
          break;
        }
        case "d": {
          dates.start = add(currentDate, { days: startDateInterval });
          dates.end = add(currentDate, { days: endDateInterval });
          break;
        }
        case "w": {
          dates.start = add(currentDate, { weeks: startDateInterval });
          dates.end = add(currentDate, { weeks: endDateInterval });
          break;
        }
        case "M": {
          dates.start = add(currentDate, { months: startDateInterval });
          dates.end = add(currentDate, { months: endDateInterval });
          break;
        }
        case "y": {
          dates.start = add(currentDate, { years: startDateInterval });
          dates.end = add(currentDate, { years: endDateInterval });
          break;
        }
        case "day": {
          dates.start = startOfDay(subDays(currentDate, interval));
          dates.end = endOfDay(subDays(currentDate, interval));
          break;
        }
        case "week": {
          dates.start = startOfWeek(subWeeks(currentDate, interval), { weekStartsOn: 1 });
          dates.end = endOfWeek(subWeeks(currentDate, interval), { weekStartsOn: 1 });
          break;
        }
        case "month": {
          dates.start = startOfMonth(subMonths(currentDate, interval));
          dates.end = endOfMonth(subMonths(currentDate, interval));
          break;
        }
        case "quarter": {
          dates.start = startOfQuarter(subQuarters(currentDate, interval));
          dates.end = endOfQuarter(subQuarters(currentDate, interval));
          break;
        }
        case "year": {
          dates.start = startOfYear(subYears(currentDate, interval));
          dates.end = endOfYear(subYears(currentDate, interval));
          break;
        }
        default: {
        }
      }

    return dates;
}

export const getChartGetQueryMethod = (chartType: string) => {
  switch (chartType) {
    case "barChartLossesByPeriod": {
      return getAssetStatusHourly
    }
  }
}

export type BarChartAssetByHoursConfigType = {
  date: string;
  assetId: string;
};

export type BulletChartConfigType = {
  assetId: string;
};

export type RadialChartConfigType = {
  period: TimePeriodType | null;
  startDate: string;
  endDate: string;
  target: number;
};

export type AvailabilityBarChartConfig = {
  period: TimePeriodType | null;
  assetId: string;
  startDate: string;
  endDate: string;
};

export type ParetoBarChartConfigType = {
  period: TimePeriodType | null;
  assetId: string;
  startDate: string;
  endDate: string;
};

export type LineChartForAssetConfigType = {
  period: TimePeriodType | null;
  assetId: string;
  startDate: string;
  endDate: string;
  unit: string | null;
  low: number | null;
  high: number | null;
};

export type BarChartForAssetByTypeConfigType = {
  period: TimePeriodType | null;
  type: LossesChartType;
  startDate: string;
  endDate: string;
};

export type BarChartForLossesByTypeConfigType = {
  period: TimePeriodType | null;
  type: LossesChartType;
  startDate: string;
  endDate: string;
};

export type BarChartForLossesByPeriodConfigType = {
  period: TimePeriodType | null;
  type: LossesChartType;
  startDate: string;
  endDate: string;
};
