import { Grid, Stack, useTheme } from "@mui/material";
import cssSpacingStyles from "../../../../../Global/Styles/spacing";
import { useState } from "react";
import { AutocompleteOption } from "../../../../../Global/Types/commonTypes";
import Select from "../../../../MaterialUI/FormFields/Select";
import {
  AvailabilityBarChartConfig,
  OEEAssetChartType,
} from "../../oEEUtils";
import Button from "../../../../MaterialUI/Button";
import { useLanguageContext } from "../../../../../context/LanguageContext";
import cssLayoutStyles from "../../../../../Global/Styles/layout";
import { OEEChartFormProps } from "../oeeDashboardUtils";
import { DynamicGridOEEChartOptions } from "../../../../SmallComponents/DynamicGridLayout.tsx/dynamicGridUtils";
import TextField from "../../../../MaterialUI/FormFields/TextFields";

interface EditAvailabilityChartFormProps extends Omit<OEEChartFormProps, "data"> {
  data: OEEAssetChartType;
  config: AvailabilityBarChartConfig;
  assetTypeOptions: AutocompleteOption[];
}

const EditAvailabilityChartForm: React.FC<EditAvailabilityChartFormProps> = ({
  data,
  config,
  widgetTitle,
  handleSaveChanges,
  assetTypeOptions,
}) => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = {
    ...cssSpacingStyles(theme),
    ...cssLayoutStyles,
  };
  const [selectedAsset, setSelectedAsset] = useState<string>(config.assetId);
  const [title, setTitle] = useState<string>(widgetTitle);

  const handleAssetChange = (e: React.ChangeEvent<{ value: string }>) => {
    const selectedValue = e.target.value as string;
    const selectedOption = assetTypeOptions.find(
      (option) => option.value === selectedValue
    );
    if (selectedOption) {
      setSelectedAsset(selectedOption.value);
    }
  };

  const handleSubmit = () => {
    const updatedChart: DynamicGridOEEChartOptions = {
      availabilityBarChart: {
        data,
        config: {
          ...config,
          assetId: selectedAsset,
        },
      },
    };
    handleSaveChanges(updatedChart, title);
  };

  return (
    <Stack alignItems="center" justifyContent="center">
      <Grid container spacing={3} mb={3}>
        <Grid item xs={6}>
          <TextField
            label={t("Title")}
            onChange={(e) => setTitle(e.target.value)}
            value={title}
          />
        </Grid>
        <Grid item xs={6}>
          <Select
            selectOptions={assetTypeOptions}
            label={t("Select Asset")}
            onChange={(e) => handleAssetChange(e)}
            value={selectedAsset}
          />
        </Grid>
      </Grid>

      <Button css={styles.widthLimit50} onClick={handleSubmit}>
        {t("Save Changes")}
      </Button>
    </Stack>
  );
};

export default EditAvailabilityChartForm;
