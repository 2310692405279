import { Stack } from "@mui/material";
import { useState } from "react";
import Button from "../../../MaterialUI/Button";
import { FormStatuses } from "../../../../Global/Types/commonTypes";
import { useLanguageContext } from "../../../../context/LanguageContext";
import { Invoice } from "./InvoiceTypes";
import { object } from "yup";
import { YUP_REQUIRED_STRING } from "../../../../Global/Constants/yupConstants";
import { Formik } from "formik";
import TextField from "../../../MaterialUI/FormFields/TextFields";

const fieldValidation = object({
  address: YUP_REQUIRED_STRING,
});

interface EditInvoiceProps {
  invoice: Invoice;
  onSubmit?: () => void;
}

const EditInvoice: React.FC<EditInvoiceProps> = ({
  invoice,
  onSubmit
}) => {
  const { t } = useLanguageContext();
  // const { setAuthedUser } = useAuthedContext();

  const [formStatus, setFormStatus] = useState<FormStatuses>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  console.log(alertMessage);

  const handleFormSubmit = async (values: Invoice) => {
    try {
      console.log('111111 values', values)
    } catch (err) {
      console.log("UpdateDynamicGridLayoutForm err ", err);
      setFormStatus("error");
      setAlertMessage(t("Something went wrong"));
    }
  };

  return (
    <Formik
      initialValues={invoice}
      onSubmit={handleFormSubmit}
      validationSchema={fieldValidation}
    >
      {({ handleSubmit, handleChange, touched, errors, values }) => (
        <form onSubmit={handleSubmit}>
          <Stack
            spacing={2}
            alignItems="center"
            justifyContent="center"
          >
            <TextField
              name="currency"
              label={t("Currency")}
              error={touched["currency"] && !!errors["currency"]}
              helperText={touched["currency"] && errors["currency"]}
              onChange={handleChange}
              value={values.currency}
            />
            <TextField
              name="customer_name"
              label={t("Customer name")}
              error={touched["customer_name"] && !!errors["customer_name"]}
              helperText={touched["customer_name"] && errors["customer_name"]}
              onChange={handleChange}
              value={values.customer_name}
            />
            <TextField
              name="customer_vat_number"
              label={t("Customer VAT Number")}
              error={touched["customer_vat_number"] && !!errors["customer_vat_number"]}
              helperText={touched["customer_vat_number"] && errors["customer_vat_number"]}
              onChange={handleChange}
              value={values.customer_vat_number}
            />
            <TextField
              name="date"
              label={t("Date")}
              error={touched["date"] && !!errors["date"]}
              helperText={touched["date"] && errors["date"]}
              onChange={handleChange}
              value={values.date}
            />
            <TextField
              name="discount"
              label={t("Discount")}
              error={touched["discount"] && !!errors["discount"]}
              helperText={touched["discount"] && errors["discount"]}
              onChange={handleChange}
              value={values.discount}
            />
            <TextField
              name="due_date"
              label={t("Due Date")}
              error={touched["due_date"] && !!errors["due_date"]}
              helperText={touched["due_date"] && errors["due_date"]}
              onChange={handleChange}
              value={values.due_date}
            />
            <TextField
              name="invoice_number"
              label={t("Invoice Number")}
              error={touched["invoice_number"] && !!errors["invoice_number"]}
              helperText={touched["invoice_number"] && errors["invoice_number"]}
              onChange={handleChange}
              value={values.invoice_number}
            />
            <TextField
              name="net_total"
              label={t("NET Total")}
              error={touched["net_total"] && !!errors["net_total"]}
              helperText={touched["net_total"] && errors["net_total"]}
              onChange={handleChange}
              value={values.net_total}
            />
            <TextField
              name="protocol_id"
              label={t("Protocol ID")}
              error={touched["protocol_id"] && !!errors["protocol_id"]}
              helperText={touched["protocol_id"] && errors["protocol_id"]}
              onChange={handleChange}
              value={values.protocol_id}
            />
            <TextField
              name="quantity"
              label={t("Quantity")}
              error={touched["quantity"] && !!errors["quantity"]}
              helperText={touched["quantity"] && errors["quantity"]}
              onChange={handleChange}
              value={values.quantity}
            />
            <TextField
              name="status"
              label={t("Status")}
              error={touched["status"] && !!errors["status"]}
              helperText={touched["status"] && errors["status"]}
              onChange={handleChange}
              value={values.status}
            />
            <TextField
              name="supplier_name"
              label={t("Suplier Name")}
              error={touched["supplier_name"] && !!errors["supplier_name"]}
              helperText={touched["supplier_name"] && errors["supplier_name"]}
              onChange={handleChange}
              value={values.supplier_name}
            />
            <TextField
              name="supplier_vat_number"
              label={t("Suplier VAT Number")}
              error={touched["supplier_vat_number"] && !!errors["supplier_vat_number"]}
              helperText={touched["supplier_vat_number"] && errors["supplier_vat_number"]}
              onChange={handleChange}
              value={values.supplier_vat_number}
            />
            <TextField
              name="total_excise_amount"
              label={t("Total Excise Amount")}
              error={touched["total_excise_amount"] && !!errors["total_excise_amount"]}
              helperText={touched["total_excise_amount"] && errors["total_excise_amount"]}
              onChange={handleChange}
              value={values.total_excise_amount}
            />
            <TextField
              name="unit_price"
              label={t("Unit price")}
              error={touched["unit_price"] && !!errors["unit_price"]}
              helperText={touched["unit_price"] && errors["unit_price"]}
              onChange={handleChange}
              value={values.unit_price}
            />
            <TextField
              name="vat_amount"
              label={t("VAT amount")}
              error={touched["vat_amount"] && !!errors["vat_amount"]}
              helperText={touched["vat_amount"] && errors["vat_amount"]}
              onChange={handleChange}
              value={values.vat_amount}
            />

            <Button type="submit" loading={formStatus === "loading"} onClick={onSubmit}>
              Update Invoice
            </Button>
          </Stack>
        </form>
      )}
    </Formik>
  );
};

export default EditInvoice;
