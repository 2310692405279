import { Box, Grid, IconButton, Stack, Typography, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import {
  BarChartForLossesByType,
  BarChartForLossesByTypeConfigType,
  BarChartLossesByMainAndSubCategory,
  LossesChartType,
  TimePeriodType,
  chartTypeOptions,
  getChartColor,
  getDatesForPeriod,
} from "../../oEEUtils";
import { ComputedDatum, ResponsiveBar } from "@nivo/bar";
import cssSpacingStyles from "../../../../../Global/Styles/spacing";
import cssComponentsStyles from "../../../../../Global/Styles/components";
import { PeriodModeDates } from "../../../../SmallComponents/DynamicGridLayout.tsx/dynamicGridExcellenceUtils";
import ChartPeriodViewer from "../../../../SmallComponents/DynamicGridLayout.tsx/ChartPeriodViewer";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import { format } from "date-fns";
import Select from "../../../../MaterialUI/FormFields/Select";
import callApi from "../../../../../Api/callApi";
import { useAuthedContext } from "../../../../../context/AuthContext";
import { getOEELossesByCategory } from "../../../../../Api/OEE/apiOEEGetQueries";
import { GetQueryOEELossesByCategory } from "../../../../../Api/OEE/apiOEESnippets";
import { parseSecondsToHMS } from "../../../../../Global/Utils/commonFunctions";
import { useLanguageContext } from "../../../../../context/LanguageContext";
import { FormStatuses } from "../../../../../Global/Types/commonTypes";
import Alert from "../../../../MaterialUI/Alert";
import { SerializedStyles } from "@emotion/react";

interface OEEBarChartLossesByTypeProps {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  config: BarChartForLossesByTypeConfigType;
  onUpdateConfig?: (updatedConfig: Partial<BarChartForLossesByTypeConfigType>) => void;
  isStatic?: boolean;
}

const OEEBarChartLossesByType: React.FC<OEEBarChartLossesByTypeProps> = ({
  config,
  onUpdateConfig,
  isStatic
}) => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = {
    ...cssSpacingStyles(theme),
    ...cssComponentsStyles(theme),
  };
  const { setAuthedUser } = useAuthedContext();
  const [barChartData, setBarChartData] = useState<BarChartForLossesByType>([]);
  const [period, setPeriod] = useState<TimePeriodType | null>(config.period || "M");
  const [dates, setDates] = useState<PeriodModeDates | null>({
    startDate: new Date(config.startDate),
    endDate: new Date(config.endDate),
  });
  const [isChartPeriodViewerVisible, setIsChartPeriodViewerVisible] =
    useState<boolean>(false);
  const [selectedLossesTypeChart, setSelectedLossesTypeChart] = useState<LossesChartType>(
    config.type
  );
  const [formStatus, setFormStatus] = useState<FormStatuses>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);

  useEffect(() => {
    if (dates?.startDate && dates?.endDate && onUpdateConfig) {
      onUpdateConfig({
        startDate: dates.startDate.toISOString(),
        endDate: dates.endDate.toISOString(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dates]);

  useEffect(() => {
    if(onUpdateConfig) {
      onUpdateConfig({
        period: period
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period])

  useEffect(() => {
    fetchOEEData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config]);

  const firstUpdate = useRef(true)

  const fetchOEEData = async () => {
    try {
      setFormStatus(null);
      setAlertMessage(null);
      if (config?.startDate && config.endDate) {
        let startDate = config.startDate;
        let endDate = config.endDate;
  
        if(firstUpdate.current) {
          firstUpdate.current = false
          const dates = getDatesForPeriod(config.period || "month")

          setDates({startDate: dates.start, endDate: dates.end})
        }

        const formattedStartDate = new Date(startDate).toISOString();
        const formattedEndDate = new Date(endDate).toISOString();

        const data = await callApi<GetQueryOEELossesByCategory>({
          query: getOEELossesByCategory(
            config.type,
            formattedStartDate,
            formattedEndDate
          ),
          auth: { setAuthedUser },
        });

        setBarChartData(data);
      }
    } catch (error) {
      console.log("There was an error fetching assets ", error);
      setFormStatus("error");
      setAlertMessage("Something went wrong");
    }
  };

  const handleChangeType = (type: LossesChartType) => {
    if (dates?.startDate && dates?.endDate && onUpdateConfig) {
      onUpdateConfig({
        type: type,
      });
    }
  };

  const changeConfig = (type: LossesChartType) => {
    setSelectedLossesTypeChart(type);
    handleChangeType(type);
  };

  const toggleChartPeriodViewer = () => {
    setIsChartPeriodViewerVisible((prev) => !prev);
  };

  return (
    <Box component="div" ml={2}>
      <Grid container justifyContent="space-between" alignItems="center">
        {
        !isStatic ? 
          <Grid item>
          <Select
            selectOptions={chartTypeOptions}
            value={selectedLossesTypeChart}
            label={t("Losses Type")}
            onChange={(e) => changeConfig(e.target.value as LossesChartType)}
          />
        </Grid>
        :
        <Grid item>
          <Typography variant="caption" color={theme.palette.grey[700]}>
                    Losses Type
          </Typography>
          <Typography variant="body1" color={theme.palette.common.black}>
                    {chartTypeOptions.find(type => type.value == selectedLossesTypeChart)?.description}
          </Typography>
      </Grid>
        }

        <Grid item mr={2}>
          <Grid container alignItems="center" spacing={2}>
            {isChartPeriodViewerVisible && dates ? (
              <Grid item>
                <Stack alignItems="flex-end">
                  <ChartPeriodViewer
                    dates={dates}
                    setDates={setDates}
                    period={period}
                    setPeriod={setPeriod}
                  />
                </Stack>
              </Grid>
            ) : dates ? (
              <Grid item>
                <Typography variant="body2" color={theme.palette.grey[700]}>
                  {`${format(dates.startDate, "dd.MM.yyyy")} - ${format(
                    dates.endDate,
                    "dd.MM.yyyy"
                  )}`}
                </Typography>
              </Grid>
            ) : null}

            {!isStatic && <Grid item>
              <IconButton
                aria-label="toggle period viewer"
                onClick={toggleChartPeriodViewer}
                size="small"
              >
                <SwapHorizIcon
                  css={styles.greyIcon}
                  style={{
                    color: isChartPeriodViewerVisible ? theme.palette.primary.main : "",
                  }}
                />
              </IconButton>
            </Grid>}
          </Grid>
        </Grid>
      </Grid>

      {barChartData.length > 0 ? (
        <Box component="div" height="250px">
          <ResponsiveBar
            isInteractive={!isStatic}
            data={barChartData}
            keys={["duration"]}
            indexBy="category"
            margin={{ top: 20, right: 20, bottom: 30, left: 130 }}
            layout="horizontal"
            colors={({ data }: ComputedDatum<BarChartLossesByMainAndSubCategory>) =>
              getChartColor(data.category as string)
            }
            theme={{
              text: {
                fill: theme.palette.common.black,
              },
              tooltip: {
                container: {
                  background: theme.palette.common.white,
                },
              },
            }}
            padding={0.2}
            valueFormat={(value) => parseSecondsToHMS(value)}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              format: (value) => parseSecondsToHMS(value),
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
            }}
          />
        </Box>
      ) : (
        !alertMessage && (
          <Typography variant="body2" mt={2} mb={1}>
            {t("Loading...")}
          </Typography>
        )
      )}
      <Box component="div" mt={1}>
        <Alert
          message={alertMessage || ""}
          showAlert={!!alertMessage}
          severity={formStatus}
        />
      </Box>
    </Box>
  );
};

export default OEEBarChartLossesByType;
