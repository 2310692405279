import ExcellenceChartFormWrapper from "../EditExcellenceChartForms/ExcellenceChartFormWrapper";
import {
  ExcellenceChartFormProps,
  ExcellenceParamMapping,
  KpiWidgetData,
} from "../EditExcellenceChartForms/excellenceChartFormUtils";
import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { DynamicGridChartOptions } from "../../SmallComponents/DynamicGridLayout.tsx/dynamicGridUtils";
import EditKpiWidgetConfig from "./EditKpiWidgetConfig";
import useTheme from "@mui/material/styles/useTheme";
import {
  KpiDataSchema,
  KpiWidgetConfiguration,
} from "./kpiWidgetTypes";
import { useLazyQuery } from "@apollo/client";
import cssLayoutStyles from "../../../Global/Styles/layout";
import cssSpacingStyles from "../../../Global/Styles/spacing";
import { FormStatuses } from "../../../Global/Types/commonTypes";
import { graphQlQueryExcellenceKpiData } from "../../../GraphQL/Excellence/graphQLQueriesExcellence";
import { getUnitsOfMeasure } from "../../../Api/Excellence/apiExcellenceGetQueries";
import { GetUnitsOfMeasureSnippet } from "../../../Api/Excellence/apiExcellenceSnippets";
import callApi from "../../../Api/callApi";
import { useAuthedContext } from "../../../context/AuthContext";
import Alert from "../../MaterialUI/Alert";
import LoadingBackdrop from "../../MaterialUI/LoadingBackdrop";
import ExcellenceKpi from "../../PageComponents/Excellence/ExcellenceCharts/ExcellenceKpi/ExcellenceKpi";
import EditKpiWidgetDataSchema from "./EditKpiWidgetDataSchema";
import kpiWidgetDefaultData from "../ExcellenceDefaultConfig/kpiWidgetDefaultData";
import { useLanguageContext } from "../../../context/LanguageContext";
import useExcellenceChartUnsavedChanges from "../../../Global/Hooks/useExcellenceChartUnsavedChanges";
import {
  buildGraphqlFilters,
  GraphqlFilter,
} from "../../SmallComponents/GraphqlFilters/graphqlFiltersUtils";

interface EditKpiWidgetFormProps
  extends Omit<ExcellenceChartFormProps, "dataSchema"> {
  config: KpiWidgetConfiguration;
  data: KpiWidgetData;
  isColumnChart?: boolean;
  dataSchema: KpiDataSchema | null;
}

const EditKpiWidgetForm: React.FC<EditKpiWidgetFormProps> = ({
  chartClass,
  config,
  dataSchema,
  data,
  handleSaveChanges,
  parameters,
  handleSetUnsavedChanges,
}) => {
  const theme = useTheme();
  const styles = {
    ...cssLayoutStyles,
    ...cssSpacingStyles(theme),
  };
  const { t } = useLanguageContext();
  const [tabValue, setTabValue] = useState(0);

  const { updatedConfig, setUpdatedConfig, updatedSchema, setUpdatedSchema } =
    useExcellenceChartUnsavedChanges<
      KpiWidgetConfiguration,
      KpiDataSchema | null
    >({
      initialConfig: {
        ...config,
      },
      initialSchema: dataSchema as KpiDataSchema,
      handleSetUnsavedChanges,
    });

  // tab number 0
  const [fieldAlert, setFieldAlert] = useState<string | null>(null);

  // tab number 1
  const [updatedData, setUpdatedData] = useState<KpiWidgetData>(data);
  const [fetchStatus, setFetchStatus] = useState<FormStatuses>(null);
  const [resetChartFetch, setResetChartFetch] = useState<boolean>(false);
  const [unitsOfMeasure, setUnitsOfMeasure] = useState<GetUnitsOfMeasureSnippet | null>(
    null
  );
  // data tab
  const [graphqlFilters, setGraphqlFilters] = useState<GraphqlFilter[]>(
    config?.graphqlFilters || []
  );
  const [initialSetupComplete, setInitialSetupComplete] = useState<boolean>(false);
  const [paramMapping, setParamMapping] = useState<ExcellenceParamMapping | null>(null);

  const [getKpiData] = useLazyQuery(graphQlQueryExcellenceKpiData);
  const { setAuthedUser } = useAuthedContext();

  useEffect(() => {
    const mapping: ExcellenceParamMapping = {};
    parameters?.parameters.forEach((param) => {
      if (param.id && !mapping?.[param.id]) {
        mapping[param.id] = {
          connection: param.container,
          paramID: param.id,
          paramName: param.name,
          connectionName: param.containerName,
          type: param.type,
        };
      }
    });
    setParamMapping(mapping);
  }, [parameters]);

  useEffect(() => {
    (async () => {
      try {
        const units = await callApi<GetUnitsOfMeasureSnippet>({
          query: getUnitsOfMeasure,
          auth: { setAuthedUser },
        });
        setUnitsOfMeasure(units);
      } catch (err) {
        console.error(err);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if ( graphqlFilters && graphqlFilters.length ) {
      setUpdatedConfig((prev) => ({
        ...prev,
        graphqlFilters
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [graphqlFilters]);

  useEffect(() => {
    (async () => {
      if (updatedSchema) {
        try {
          setFetchStatus("loading");
          const result = await getKpiData({
            variables: {
              input: { ...updatedSchema, filters: buildGraphqlFilters(graphqlFilters) },
            },
          });
          if (!result.data || result.error) {
            throw new Error(JSON.stringify(result));
          }
          const resultData = result.data.indicatorData.data;

          setUpdatedData(resultData);
          setResetChartFetch((prev) => !prev);
          setFetchStatus(null);
        } catch (err) {
          console.log("err ", err);
          setFetchStatus("error");
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedSchema]);

  const handleSubmit = () => {
    const { title, ...otherConfig } = updatedConfig;
    const updatedChart: DynamicGridChartOptions = {
      kpiWidget: {
        config: { ...{ ...otherConfig, graphqlFilters }, title },
        data: updatedData,
        dataSchema: updatedSchema,
      },
    };

    // do some field checks
    if (!title) {
      setFieldAlert(t("Title is required"));
    } else {
      // all fields checks have passed
      handleSaveChanges(updatedChart, title);
    }
  };

  return (
    <ExcellenceChartFormWrapper
      alertMessage={fieldAlert}
      handleSubmit={handleSubmit}
      tabValue={tabValue}
      setTabValue={setTabValue}
      disabledDataTab={false}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          {tabValue === 0 ? (
            <EditKpiWidgetConfig
              config={updatedConfig}
              setUpdatedConfig={setUpdatedConfig}
              unitsOfMeasure={unitsOfMeasure}
            />
          ) : null}

          {tabValue === 1 ? (
            <EditKpiWidgetDataSchema
              parameters={parameters?.parameters || []}
              dataSchema={updatedSchema}
              handleUpdateDataSchema={(val) => setUpdatedSchema(val)}
              config={updatedConfig}
              setUpdatedConfig={setUpdatedConfig}
              graphqlFilters={graphqlFilters}
              setGraphqlFilters={setGraphqlFilters}
              initialSetupComplete={initialSetupComplete}
              setInitialSetupComplete={setInitialSetupComplete}
              paramMapping={paramMapping}
            />
          ) : null}

          <Alert
            css={[styles.reverseContentBreak, styles.contentBreak]}
            message={t("Something went wrong")}
            showAlert={fetchStatus === "error"}
            severity={"error"}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <ExcellenceKpi
            css={chartClass}
            configuration={updatedConfig}
            data={!updatedSchema ? kpiWidgetDefaultData : updatedData}
            schema={updatedSchema}
            resetChartFetch={resetChartFetch}
            dataFetchFromParent
            parameters={parameters?.parameters}
            tempFilters={[]}
          />
        </Grid>
      </Grid>

      <LoadingBackdrop loading={fetchStatus === "loading"} />
    </ExcellenceChartFormWrapper>
  );
};

export default EditKpiWidgetForm;
