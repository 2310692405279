import {
  WIDGET_GRID_HEADING_MENU_HEIGHT,
  WIDGET_GRID_HEADING_MENU_MARGIN_BOTTOM,
  WidgetGridItem,
} from "./widgetsGridUtils";
import { css } from "@emotion/react";
import useTheme from "@mui/material/styles/useTheme";
import { Box, Stack } from "@mui/material";
import cssComponentsStyles from "../../../Global/Styles/components";
import cssLayoutStyles from "../../../Global/Styles/layout";
import cssSpacingStyles from "../../../Global/Styles/spacing";
import ContentBox from "../../MaterialUI/ContentBox";
import DragHandleOutlinedIcon from "@mui/icons-material/DragHandleOutlined";
import LazyRender from "../../SmallComponents/LazyRender/LazyRender";

const cssStyles = {
  dragIcon: css({
    cursor: "grab",
    minWidth: "24px",
  }),
  staticItem: css({
    "& + #grid-resize-handle": {
      display: "none",
    },
  }),
  headingMenu: css({
    height: WIDGET_GRID_HEADING_MENU_HEIGHT,
    marginBottom: WIDGET_GRID_HEADING_MENU_MARGIN_BOTTOM,
  }),
  headingItem: css({
    width: "33.3%",
  }),
};

type GridItemGeneric = Record<string, any>;

interface WidgetsGridItemProps<T extends GridItemGeneric> {
  gridItem: WidgetGridItem<T>;
  children: JSX.Element | JSX.Element[];
  rightMenu?: JSX.Element | JSX.Element[];
  canMove?: boolean;
  isStatic?: boolean;
}

const WidgetsGridItem: React.FC<WidgetsGridItemProps<GridItemGeneric>> = ({
  gridItem,
  children,
  rightMenu,
  canMove,
  isStatic,
}) => {
  const theme = useTheme();
  const styles = {
    ...cssStyles,
    ...cssLayoutStyles,
    ...cssSpacingStyles(theme),
    ...cssComponentsStyles(theme),
  };

  return (
    <Box component="div" css={[styles.height100, gridItem.isLocked && styles.staticItem]}>
      <ContentBox css={[styles.height100, styles.fullPadding1]}>
        {isStatic ? null : (
          <Stack
            css={styles.headingMenu}
            spacing={2}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box component="div" css={styles.headingItem} />

            <Box
              component="div"
              css={styles.headingItem}
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {gridItem.isLocked ? (
                <Box component="div" />
              ) : (
                <Box component="div" style={!canMove ? { display: "none" } : undefined}>
                  <DragHandleOutlinedIcon
                    sx={{
                      width: "33%",
                    }}
                    css={[styles.dragIcon, styles.greyIcon]}
                    className="drag-handle"
                  />
                </Box>
              )}
            </Box>

            <Box
              component="div"
              css={styles.headingItem}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              {rightMenu}
            </Box>
          </Stack>
        )}

        <Box
          sx={{
            width: "100%",
            height: isStatic ? "100%" : "calc(100% - 10px)",
          }}
          component="div"
        >
          <LazyRender delay={2000}>{children}</LazyRender>
        </Box>
      </ContentBox>
    </Box>
  );
};

export default WidgetsGridItem;
