import cssSpacingStyles from "../../../../../Global/Styles/spacing";
import IndicatorWidget from "../../../../ExcellenceWidgets/IndicatorWidget/IndicatorWidget";
import {
  IndicatorWidgetConfiguration,
} from "../../../../ExcellenceWidgets/IndicatorWidget/indicatorWidgetTypes";
import { Box, useTheme } from "@mui/material";
import cssLayoutStyles from "../../../../../Global/Styles/layout";

interface ExcellenceIndicatorProps {
  configuration: IndicatorWidgetConfiguration;
}

const ExcellenceIndicator: React.FC<ExcellenceIndicatorProps> = ({
  configuration,
}) => {
  const theme = useTheme();
  const styles = {
    ...cssSpacingStyles(theme),
    ...cssLayoutStyles,
  };

  return (
    <Box component="div" css={[styles.width100, styles.height100, styles.relative]}>
      <IndicatorWidget
        configuration={configuration}
      />
    </Box>
  );
};

export default ExcellenceIndicator;
