import useTheme from "@mui/material/styles/useTheme";
import { SerializedStyles, css } from "@emotion/react";
import {
  KpiDataSchema,
  KpiWidgetButtonTypes,
  KpiWidgetConfiguration,
} from "./kpiWidgetTypes";
import { Box, Stack, Theme, Typography } from "@mui/material";
import { KpiWidgetData } from "../EditExcellenceChartForms/excellenceChartFormUtils";
import KpiWidgetChart from "./KpiWidgetChart";
import KpiWidgetAvatarIcon from "./KpiWidgetAvatarIcon";
import KpiButtons from "./KpiButtons";
import kpiWidgetDefaultData from "../ExcellenceDefaultConfig/kpiWidgetDefaultData";
import { formatNumber } from "../../../Global/Utils/commonFunctions";

const cssStyles = (theme: Theme) => ({
  stackContainerStyle: css({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 20,
  }),
  subtitleStyle: css({
    color: theme.palette.text.secondary,
    marginTop: 4,
  }),
});

interface KpiWidgetProps {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  configuration: KpiWidgetConfiguration;
  data: KpiWidgetData;
  handleButtonClick: (type: KpiWidgetButtonTypes) => Promise<void>;
  dataSchema: KpiDataSchema | null;
}

const KpiWidget: React.FC<KpiWidgetProps> = ({
  configuration,
  data,
  handleButtonClick,
  dataSchema,
}) => {
  const theme = useTheme();
  const styles = {
    ...cssStyles(theme),
  };
  const dataToUse = data || kpiWidgetDefaultData;
  const displayedValue = getDisplayedValue(dataToUse, configuration);

  const getTrendDescription = () => {
    const deviation = configuration.tolerance ? `${configuration.tolerance}%` : null;
    const target =
      configuration.targetValue && !isNaN(Number(configuration.targetValue))
        ? Number(configuration.targetValue)
        : null;

    const getDescriptionForValue = (value: number) => {
      if (target !== null && typeof configuration.tolerance === "number") {
        const percentageDeviation = Math.abs((value - target) / target) * 100;

        const withinOrMore =
          configuration.positiveDirection === "DOWN"
            ? percentageDeviation <= configuration.tolerance
              ? "within"
              : "more than"
            : percentageDeviation > configuration.tolerance
            ? "more than"
            : "within";

        if (configuration.tolerance === 0) {
          return "No tolerance available";
        }

        return `${configuration.valueType} ${withinOrMore} Target ${target} (${configuration.tolerance}% Tolerance)`;
      }

      const basicDeviationDirection =
        configuration.positiveDirection === "DOWN" ? "within" : "more than";

      const deviationText = deviation
        ? `Tolerance ${basicDeviationDirection} ${configuration.tolerance}%`
        : "No tolerance available";

      return `${configuration.valueType} ${deviationText}`;
    };

    if (configuration.valueType === "Average") {
      return getDescriptionForValue(dataToUse.average);
    } else {
      return getDescriptionForValue(dataToUse.latest);
    }
  };

  return (
    <Stack p={2} spacing={4}>
      <Stack css={styles.stackContainerStyle}>
        <Box
          component="div"
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <KpiWidgetAvatarIcon
            configuration={configuration}
            trendAverage={dataToUse.trendAverage}
            trendLatest={dataToUse.trendLatest}
            threshold={dataSchema?.threshold || null}
            latest={dataToUse.latest}
            average={dataToUse.average}
          />
          <Typography
            variant="h6"
            sx={{ color: theme.palette.customColors.greyText, fontWeight: 400, ml: 3 }}
          >
            {getTrendDescription()}
          </Typography>
        </Box>
        <KpiButtons handleButtonClick={handleButtonClick} />
      </Stack>

      <Stack css={styles.stackContainerStyle}>
        <Stack spacing={3}>
          <Typography variant="h1">{displayedValue}</Typography>
          <Box component="div">
            <Typography variant="h4">{configuration.title}</Typography>
            <Typography variant="body2" css={styles.subtitleStyle}>
              {configuration.subtitle}
            </Typography>
          </Box>
        </Stack>
        <KpiWidgetChart
          chartData={dataToUse.chart}
          decimalPoints={configuration.decimalPoints}
          fixedDecimalValue={configuration.fixedDecimalValue}
        />
      </Stack>
    </Stack>
  );
};

export default KpiWidget;

const getDisplayedValue = (
  data: KpiWidgetData,
  configuration: KpiWidgetConfiguration
) => {
  const val = configuration.valueType === "Average" ? data.average : data.latest;

  const getFormattedValue = () => {
    try {
      const result = formatNumber(
        val,
        configuration.fixedDecimalValue,
        configuration.decimalPoints
      );
      if (!result) {
        return `${val}`;
      }
      return `${result}`;
    } catch (err) {
      return val;
    }
  };

  const formattedValue = getFormattedValue();

  return `${formattedValue} ${configuration.symbol.replace(/[()]/g, "")}`;
};
