import { Grid, InputAdornment, Stack, useTheme } from "@mui/material";
import { useState } from "react";
import { useLanguageContext } from "../../../../../context/LanguageContext";
import cssSpacingStyles from "../../../../../Global/Styles/spacing";
import cssLayoutStyles from "../../../../../Global/Styles/layout";
import { OEEChartFormProps } from "../oeeDashboardUtils";
import {
  OEERadialChartType,
  RadialChartConfigType,
} from "../../oEEUtils";
import TextField from "../../../../MaterialUI/FormFields/TextFields";
import { DynamicGridOEEChartOptions } from "../../../../SmallComponents/DynamicGridLayout.tsx/dynamicGridUtils";
import Button from "../../../../MaterialUI/Button";

interface EditRadialChartFormProps extends Omit<OEEChartFormProps, "data"> {
  data: OEERadialChartType;
  config: RadialChartConfigType;
}

const EditRadialChartForm: React.FC<EditRadialChartFormProps> = ({
  data,
  config,
  widgetTitle,
  handleSaveChanges,
}) => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = {
    ...cssSpacingStyles(theme),
    ...cssLayoutStyles,
  };
  const [title, setTitle] = useState<string>(widgetTitle);
  const [target, setTarget] = useState<number>(config.target);

  const handleSubmit = () => {
    const updatedChart: DynamicGridOEEChartOptions = {
      radialChart: {
        data,
        config: {
          ...config,
          target,
        },
      },
    };
    handleSaveChanges(updatedChart, title);
  };

  return (
    <Stack alignItems="center" justifyContent="center">
      <Grid container spacing={3} mb={3}>
        <Grid item xs={6}>
          <TextField
            label={t("Title")}
            onChange={(e) => setTitle(e.target.value)}
            value={title}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label={t("Target in Percentage")}
            numberField={true}
            onChange={(e) => setTarget(Number(e.target.value))}
            value={target}
            InputProps={{
              endAdornment: <InputAdornment position="start">%</InputAdornment>,
            }}
          />
        </Grid>
      </Grid>

      <Button css={styles.widthLimit50} onClick={handleSubmit}>
        {t("Save Changes")}
      </Button>
    </Stack>
  );
};

export default EditRadialChartForm;
