import { Box, Breakpoint, Theme, useTheme } from "@mui/material";
import { css } from "@emotion/react";
import cssComponentsStyles from "../../../Global/Styles/components";
import cssSpacingStyles from "../../../Global/Styles/spacing";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Layout, Layouts, Responsive, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import { WidgetGridItem } from "./widgetsGridUtils";
import useGetCurrentMuiBreakpoint from "../../../Global/Hooks/useGetCurrentMuiBreakpoint";
import useContainerDimensions from "../../../Global/Hooks/useContainerDimensions";
import WidgetsGridItem from "./WidgetsGridItem";
import debounce from "lodash.debounce";
import cssLayoutStyles from "../../../Global/Styles/layout";

const cssStyles = (theme: Theme) => ({
  layoutWrapper: css({
    ".react-grid-item.react-grid-placeholder": {
      backgroundColor: theme.palette.primary.main,
    },
  }),
  layoutHeader: css({
    background: theme.palette.common.white,
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
  }),
  resizeHandle: css({
    position: "absolute",
    right: "3px",
    bottom: "-4px",
    transform: "rotate(45deg)",
    cursor: "se-resize",
  }),
});

const ResponsiveGridLayout = WidthProvider(Responsive);
type GridItemGeneric = Record<string, any>;

interface WidgetsGridLayoutContext<T extends GridItemGeneric> {
  gridWidgets: WidgetGridItem<T>[];
  gridHeight: number;
  gridWidth: number;
}
const GridContext = createContext<WidgetsGridLayoutContext<any>>(
  {} as WidgetsGridLayoutContext<any>
);

interface WidgetsGridLayoutProps<T extends GridItemGeneric> {
  dataItems: WidgetGridItem<T>[];
  updateDataItems: (newItems: WidgetGridItem<T>[]) => void;
  renderRightMenu?: (
    item: WidgetGridItem<T>,
    index: number,
    width: number,
    height: number
  ) => JSX.Element | JSX.Element[];
  render: (
    item: WidgetGridItem<T>,
    index: number,
    isUpdatingWidget: boolean
  ) => JSX.Element | JSX.Element[];
  rowHeight?: number;
  canMove?: boolean;
  isStatic?: boolean;
}

const WidgetsGridLayout: React.FC<WidgetsGridLayoutProps<GridItemGeneric>> = ({
  dataItems,
  renderRightMenu,
  render,
  rowHeight = 150,
  canMove,
  updateDataItems,
  isStatic,
}) => {
  const theme = useTheme();
  const styles = {
    ...cssStyles(theme),
    ...cssSpacingStyles(theme),
    ...cssComponentsStyles(theme),
    ...cssLayoutStyles,
  };
  const gridLayoutRef = useRef<HTMLDivElement>(null);
  const breakpoints = theme.breakpoints.values;
  const { width: gridLayoutWidth, width: gridLayoutHeight } =
    useContainerDimensions(gridLayoutRef);
  const currentBreakpoint = useGetCurrentMuiBreakpoint(gridLayoutWidth);

  const [responsiveLayouts, setResponsiveLayouts] = useState<Layouts>(
    handleGetResponsiveLayouts(dataItems, breakpoints)
  );
  const [stateData, setStateData] =
    useState<WidgetGridItem<GridItemGeneric>[]>(dataItems);
  const [isUpdatingWidget, setIsUpdatingWidget] = useState<boolean>(false);

  useEffect(() => {
    setResponsiveLayouts(handleGetResponsiveLayouts(stateData, breakpoints));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateData]);

  useEffect(() => {
    setStateData(dataItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataItems]);

  useEffect(() => {
    if (JSON.stringify(dataItems) !== JSON.stringify(stateData)) {
      debounceUpdateData(stateData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateData]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceUpdateData = useCallback(debounce(updateDataItems, 500), []);

  const handleOnLayoutChange = (currentLayout: Layout[], updatedLayouts: Layouts) => {
    if (updatedLayouts?.[currentBreakpoint]?.[0]?.isBounded === false) {
      // There's a bug where newly added items have w,h set to 1.
      // We check if first item isBounded === false. If condition is false,
      // then we don't update the items. Another call is made afterwards, which
      // will have first item isBounded === false and correct w and h.
      // Then we update the items.

      const largeScreenBreakpoints = ["xl", "lg", "md"];
      // grid changes to mobile version at 900px or less => breakpoints.md
      const isLargeScreen = gridLayoutWidth > breakpoints.md;

      const formattedItemsLayoutData = Object.entries(updatedLayouts).reduce(
        (acc, curr) => {
          const [breakpoint, layouts] = curr;

          const isLargeBreakpoint = largeScreenBreakpoints.includes(breakpoint);
          let layoutToUse: Layout[] = layouts;
          if (isLargeScreen) {
            // use currentLayout layout for large breakpoints
            // and use curr.layouts for small breakpoints
            layoutToUse = isLargeBreakpoint ? currentLayout : layouts;
          } else {
            // do the opposite of above for small breakpoints
            layoutToUse = isLargeBreakpoint ? layouts : currentLayout;
          }

          const itemsLayout = layoutToUse.reduce((itemAcc, itemCurr) => {
            return {
              ...itemAcc,
              [itemCurr.i]: {
                ...acc[itemCurr.i],
                [breakpoint]: itemCurr,
              },
            };
          }, {} as Record<string, Layout>);

          return {
            ...acc,
            ...itemsLayout,
          };
        },
        {} as Record<string, Layout>
      );

      const result = stateData.map((item) => {
        return {
          ...item,
          layout: { ...item.layout, ...formattedItemsLayoutData[item.layout.xl.i] },
        };
      });

      setStateData(result);
    } else {
      setStateData((prev) => prev);
    }
  };

  return (
    <GridContext.Provider
      value={{
        gridWidgets: stateData,
        gridHeight: gridLayoutHeight,
        gridWidth: gridLayoutWidth,
      }}
    >
      <Box component="div" css={styles.layoutWrapper} ref={gridLayoutRef}>
        <ResponsiveGridLayout
          className="layout"
          layouts={responsiveLayouts}
          breakpoints={{
            xl: breakpoints.xl,
            lg: breakpoints.lg,
            md: breakpoints.md,
            sm: breakpoints.sm,
            xs: breakpoints.xs,
          }}
          cols={{ xl: 12, lg: 12, md: 12, sm: 6, xs: 6 }}
          isBounded
          draggableHandle=".drag-handle"
          resizeHandle={
            <Box
              component="div"
              css={styles.resizeHandle}
              id="grid-resize-handle"
              style={isStatic || !canMove ? { display: "none" } : undefined}
            >
              <ChevronRightOutlinedIcon css={styles.greyIcon} />
            </Box>
          }
          onLayoutChange={(currentLayout, allLayouts) => {
            handleOnLayoutChange(currentLayout, allLayouts);
          }}
          rowHeight={rowHeight}
          onDragStart={() => setIsUpdatingWidget(true)}
          onDragStop={() => setIsUpdatingWidget(false)}
          onResizeStart={() => setIsUpdatingWidget(true)}
          onResizeStop={() => setIsUpdatingWidget(false)}
        >
          {stateData.map((item, index) => (
            <Box component="div" key={item.layout.xl.i}>
              <WidgetsGridItem
                gridItem={item}
                rightMenu={
                  renderRightMenu ? renderRightMenu(item, index, 100, 100) : undefined
                }
                isStatic={isStatic}
                canMove={canMove}
              >
                {render(item, index, isUpdatingWidget)}
              </WidgetsGridItem>
            </Box>
          ))}
        </ResponsiveGridLayout>
      </Box>
    </GridContext.Provider>
  );
};

export default WidgetsGridLayout;
export const useWidgetsGridLayoutContext =
  (): WidgetsGridLayoutContext<GridItemGeneric> => useContext(GridContext);

//
// Functions
//
const handleGetResponsiveLayouts = (
  items: WidgetGridItem<GridItemGeneric>[],
  breakpoints: { [key in Breakpoint]: number }
) => {
  const result: Layouts = Object.keys(breakpoints).reduce((acc, curr) => {
    return {
      ...acc,
      [curr]: [],
    };
  }, {});

  for (const item of items) {
    result.xl.push(item.layout.xl);
    result.lg.push(item.layout.lg);
    result.md.push(item.layout.md);
    result.sm.push(item.layout.sm);
    result.xs.push(item.layout.xs);
  }
  return result;
};
