import { Box, Grid, IconButton, Stack, Typography, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import {
  getChartColor,
  getDatesForPeriod,
  ParetoBarChartConfigType,
  ParetoChartDataType,
  TimePeriodType,
} from "../../oEEUtils";
import { ComputedDatum, ResponsiveBar } from "@nivo/bar";
import { parseSecondsToHMS } from "../../../../../Global/Utils/commonFunctions";
import { format } from "date-fns";
import cssSpacingStyles from "../../../../../Global/Styles/spacing";
import cssComponentsStyles from "../../../../../Global/Styles/components";
import { PeriodModeDates } from "../../../../SmallComponents/DynamicGridLayout.tsx/dynamicGridExcellenceUtils";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import ChartPeriodViewer from "../../../../SmallComponents/DynamicGridLayout.tsx/ChartPeriodViewer";
import { FormStatuses } from "../../../../../Global/Types/commonTypes";
import callApi from "../../../../../Api/callApi";
import { GetQueryOEEParetoChart } from "../../../../../Api/OEE/apiOEESnippets";
import { getOEECategoryLossByAsset } from "../../../../../Api/OEE/apiOEEGetQueries";
import { useAuthedContext } from "../../../../../context/AuthContext";
import { useLanguageContext } from "../../../../../context/LanguageContext";
import Alert from "../../../../MaterialUI/Alert";
import { SerializedStyles } from "@emotion/react";

interface OEEBarChartParetoProps {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  config: ParetoBarChartConfigType;
  onUpdateConfig?: (updatedConfig: Partial<ParetoBarChartConfigType>) => void;
  isStatic?: boolean;
}

const OEEBarChartPareto: React.FC<OEEBarChartParetoProps> = ({
  config,
  onUpdateConfig,
  isStatic
}) => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = {
    ...cssSpacingStyles(theme),
    ...cssComponentsStyles(theme),
  };
  const { setAuthedUser } = useAuthedContext();
  const [barChartData, setBarChartData] = useState<ParetoChartDataType[]>([]);
  const [period, setPeriod] = useState<TimePeriodType | null>(config.period || "M");
  const [dates, setDates] = useState<PeriodModeDates | null>({
    startDate: new Date(config.startDate),
    endDate: new Date(config.endDate),
  });
  const [isChartPeriodViewerVisible, setIsChartPeriodViewerVisible] =
    useState<boolean>(false);
  const [formStatus, setFormStatus] = useState<FormStatuses>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);

  useEffect(() => {
    if (dates?.startDate && dates?.endDate && onUpdateConfig) {
      onUpdateConfig({
        startDate: dates.startDate.toISOString(),
        endDate: dates.endDate.toISOString(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dates]);

  useEffect(() => {
    if(onUpdateConfig) {
      onUpdateConfig({
        period: period
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period])

  useEffect(() => {
    fetchOEEData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config, dates]);

  const firstUpdate = useRef(true);

  const fetchOEEData = async () => {
    try {
      setFormStatus(null);
      setAlertMessage(null);
      if (config?.startDate && config.endDate) {
        let startDate = config.startDate;
        let endDate = config.endDate;
  
        if(firstUpdate.current) {
          firstUpdate.current = false
          const dates = getDatesForPeriod(config.period || "month")

          setDates({startDate: dates.start, endDate: dates.end})
        }

        const formattedStartDate = new Date(startDate).toISOString();
        const formattedEndDate = new Date(endDate).toISOString();

        const data = await callApi<GetQueryOEEParetoChart>({
          query: getOEECategoryLossByAsset(
            config.assetId,
            formattedStartDate,
            formattedEndDate
          ),
          auth: { setAuthedUser },
        });

        setBarChartData(data);
      }
    } catch (error) {
      console.log("There was an error fetching assets ", error);
      setFormStatus("error");
      setAlertMessage("Something went wrong");
    }
  };

  const toggleChartPeriodViewer = () => {
    setIsChartPeriodViewerVisible((prev) => !prev);
  };

  return (
    <Box component="div" ml={2}>
      <Grid container justifyContent="flex-start" alignItems="center">
        <Grid item mr={2}>
          <Grid container alignItems="center" spacing={2}>
            {isChartPeriodViewerVisible && dates ? (
              <Grid item>
                <Stack alignItems="flex-end">
                  <ChartPeriodViewer
                    dates={dates}
                    setDates={setDates}
                    period={period}
                    setPeriod={setPeriod}
                  />
                </Stack>
              </Grid>
            ) : dates ? (
              <Grid item>
                <Typography variant="body2" color={theme.palette.grey[700]}>
                  {`${format(dates.startDate, "dd.MM.yyyy")} - ${format(
                    dates.endDate,
                    "dd.MM.yyyy"
                  )}`}
                </Typography>
              </Grid>
            ) : null}

            {!isStatic && <Grid item>
              <IconButton
                aria-label="toggle period viewer"
                onClick={toggleChartPeriodViewer}
                size="small"
              >
                <SwapHorizIcon
                  css={styles.greyIcon}
                  style={{
                    color: isChartPeriodViewerVisible ? theme.palette.primary.main : "",
                  }}
                />
              </IconButton>
            </Grid>}
          </Grid>
        </Grid>
      </Grid>
      {barChartData.length > 0 ? (
        <Box component="div" height="250px">
          <ResponsiveBar
            isInteractive={!isStatic}
            data={barChartData}
            keys={["total_duration"]}
            indexBy="category"
            enableLabel={false}
            margin={{ top: 20, right: 40, bottom: 30, left: 60 }}
            layout="vertical"
            colors={({ data }: ComputedDatum<ParetoChartDataType>) =>
              getChartColor(data.category as string)
            }
            theme={{
              text: {
                fill: theme.palette.common.black,
              },
              tooltip: {
                container: {
                  background: theme.palette.common.white,
                },
              },
            }}
            padding={0.2}
            valueFormat={(value) => parseSecondsToHMS(value)}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              format: (value) => parseSecondsToHMS(value),
            }}
            layers={[
              "grid",
              "axes",
              "bars",
              CustomMarkerLayer,
              CustomRightAxisLayer,
              "markers",
              "legends",
              "annotations",
            ]}
          />
        </Box>
      ) : (
        !alertMessage && (
          <Typography variant="body2" mt={2} mb={1}>
            {t("This asset has no losses assigned yet.")}
          </Typography>
        )
      )}
      <Box component="div" mt={1}>
        <Alert
          message={alertMessage || ""}
          showAlert={!!alertMessage}
          severity={formStatus}
        />
      </Box>
    </Box>
  );
};

export default OEEBarChartPareto;

interface CustomMarkerLayerProps {
  innerHeight: number;
}

const CustomMarkerLayer: React.FC<CustomMarkerLayerProps> = ({ innerHeight }) => {
  const greenLineY = innerHeight - innerHeight * 0.8;

  return (
    <>
      <line
        x1={0}
        x2="100%"
        y1={greenLineY}
        y2={greenLineY}
        stroke="#33A161"
        strokeWidth={3}
      />
    </>
  );
};

// const CustomMarkerLayer: React.FC<CustomMarkerLayerProps> = ({
//   bars,
//   yScale,
// }) => {
//   const theme = useTheme();

//   const points = bars.map((bar, index) => ({
//     x: bar.x + bar.width / 2,
//     y: yScale(customParetoMarkers[index].value) as number,
//   }));

//   const linePath = points.reduce(
//     (acc, point) => `${acc} L ${point.x},${point.y}`,
//     `M ${points[0].x},${points[0].y}`
//   );

//   return (
//     <path d={linePath} fill="none" stroke={theme.palette.error.main} strokeWidth={2} />
//   );
// };

const CustomRightAxisLayer = ({ innerHeight, innerWidth, outerWidth }: any) => {
  const theme = useTheme();
  const ticks = Array.from({ length: 11 }, (_, i) => i * 10);
  const color =
    theme.palette.mode === "light"
      ? theme.palette.common.white
      : theme.palette.common.black;

  return (
    <g transform={`translate(${outerWidth - innerWidth}, 0)`}>
      <line
        x1={innerWidth}
        x2={innerWidth}
        y1={0}
        y2={innerHeight}
        stroke={color}
        strokeWidth={1}
      />
      {ticks.map((tick, index) => {
        const y = (innerHeight / 100) * tick;

        return (
          <g key={index} transform={`translate(${innerWidth}, ${innerHeight - y})`}>
            <line x1={0} x2={5} stroke={color} />
            <text
              x={10}
              textAnchor="start"
              dominantBaseline="middle"
              fontSize="10"
              fill={color}
            >
              {tick}%
            </text>
          </g>
        );
      })}
    </g>
  );
};
