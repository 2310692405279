import { SerializedStyles } from "@emotion/react";
import { Box, Typography } from "@mui/material";
import {
  DynamicGridHandleSaveOEEChartData,
  DynamicGridOEEChartOptions,
} from "../../../SmallComponents/DynamicGridLayout.tsx/dynamicGridUtils";
import { useLanguageContext } from "../../../../context/LanguageContext";
import EditAssetByHourBarChartForm from "./OEEBarChartAssetByHour/EditAssetByHourBarChartForm";
import EditBulletChartForm from "./OEEBulletChart/EditBulletChartForm";
import { AutocompleteOption } from "../../../../Global/Types/commonTypes";
import EditRadialChartForm from "./OEERadialChart/EditRadialChartForm";
import EditAvailabilityChartForm from "./OEEBarChartForAvailability/EditAvailabilityChartForm";
import EditParetoBarChartForm from "./OEEBarChartPareto/EditParetoBarChartForm";
import EditBarChartForAssetForm from "./OEEBarChartForAssetByType/EditBarChartForAssetForm";
import EditBarChartLossesByTypeForm from "./OEEBarChartLossesByType/EditBarChartLossesByTypeForm";
import EditBarChartLossesByPeriodForm from "./OEEBarChartLossesByPeriod/EditBarChartLossesByPeriodForm";
import EditLineChartForAssetForm from "./OEELineChartForSingleAsset/EditLineChartForAssetForm";

interface EditDynamicChartFormProps {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  chart: DynamicGridOEEChartOptions | undefined;
  widgetTitle: string;
  handleSaveChanges: DynamicGridHandleSaveOEEChartData;
  assetTypeOptions: AutocompleteOption[];
}

const EditDynamicOEEChartForm: React.FC<EditDynamicChartFormProps> = ({
  className,
  chart,
  widgetTitle,
  handleSaveChanges,
  assetTypeOptions,
}) => {
  const { t } = useLanguageContext();

  if (!chart) {
    return (
      <Box component="div" className={className}>
        <Typography variant="h3" color="error">
          {t("Wrong Chart Data Provided")}
        </Typography>
      </Box>
    );
  }

  return (
    <Box component="div" className={className}>
      {chart?.bulletChart ? (
        <EditBulletChartForm
          data={chart.bulletChart.data || null}
          config={chart.bulletChart.config || null}
          widgetTitle={widgetTitle}
          handleSaveChanges={handleSaveChanges}
          assetTypeOptions={assetTypeOptions}
        />
      ) : null}
      {chart?.radialChart ? (
        <EditRadialChartForm
          data={chart.radialChart.data || null}
          config={chart.radialChart.config || null}
          widgetTitle={widgetTitle}
          handleSaveChanges={handleSaveChanges}
        />
      ) : null}
      {chart?.availabilityBarChart ? (
        <EditAvailabilityChartForm
          data={chart.availabilityBarChart.data || null}
          config={chart.availabilityBarChart.config || null}
          widgetTitle={widgetTitle}
          handleSaveChanges={handleSaveChanges}
          assetTypeOptions={assetTypeOptions}
        />
      ) : null}
      {chart?.assetByHourBarChart ? (
        <EditAssetByHourBarChartForm
          data={chart.assetByHourBarChart.data || null}
          config={chart.assetByHourBarChart.config || null}
          widgetTitle={widgetTitle}
          handleSaveChanges={handleSaveChanges}
          assetTypeOptions={assetTypeOptions}
        />
      ) : null}
      {chart?.paretoBarChart ? (
        <EditParetoBarChartForm
          data={chart.paretoBarChart.data || null}
          config={chart.paretoBarChart.config || null}
          widgetTitle={widgetTitle}
          handleSaveChanges={handleSaveChanges}
          assetTypeOptions={assetTypeOptions}
        />
      ) : null}
      {chart?.lineChartForAsset ? (
        <EditLineChartForAssetForm
          data={chart.lineChartForAsset.data || null}
          config={chart.lineChartForAsset.config || null}
          widgetTitle={widgetTitle}
          handleSaveChanges={handleSaveChanges}
          assetTypeOptions={assetTypeOptions}
        />
      ) : null}
      {chart?.barChartForAssetByType ? (
        <EditBarChartForAssetForm
          data={chart.barChartForAssetByType.data || null}
          config={chart.barChartForAssetByType.config || null}
          widgetTitle={widgetTitle}
          handleSaveChanges={handleSaveChanges}
        />
      ) : null}
      {chart?.barChartLossesByType ? (
        <EditBarChartLossesByTypeForm
          data={chart.barChartLossesByType.data || null}
          config={chart.barChartLossesByType.config || null}
          widgetTitle={widgetTitle}
          handleSaveChanges={handleSaveChanges}
        />
      ) : null}
      {chart?.barChartLossesByPeriod ? (
        <EditBarChartLossesByPeriodForm
          data={chart.barChartLossesByPeriod.data || null}
          config={chart.barChartLossesByPeriod.config || null}
          widgetTitle={widgetTitle}
          handleSaveChanges={handleSaveChanges}
        />
      ) : null}
    </Box>
  );
};

export default EditDynamicOEEChartForm;
