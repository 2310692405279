import { Divider, Grid, InputAdornment, Stack, useTheme } from "@mui/material";
import cssSpacingStyles from "../../../../../Global/Styles/spacing";
import { useState } from "react";
import { AutocompleteOption } from "../../../../../Global/Types/commonTypes";
import Select from "../../../../MaterialUI/FormFields/Select";
import Button from "../../../../MaterialUI/Button";
import { useLanguageContext } from "../../../../../context/LanguageContext";
import cssLayoutStyles from "../../../../../Global/Styles/layout";
import { DynamicGridOEEChartOptions } from "../../../../SmallComponents/DynamicGridLayout.tsx/dynamicGridUtils";
import TextField from "../../../../MaterialUI/FormFields/TextFields";
import UnitsOfMeasureDropdown from "../../../../SmallComponents/UnitsOfMeasureDropdown/UnitsOfMeasureDropdown";
import { extractUnitSymbol } from "../../../../SmallComponents/UnitsOfMeasureDropdown/unitsOfMeasureDropdownUtils";
import Checkbox from "../../../../MaterialUI/FormFields/Checkbox";
import { LineChartForAssetConfigType, OEESingleAssetDataChart } from "../../oEEUtils";
import { OEEChartFormProps } from "../oeeDashboardUtils";

interface EditLineChartForAssetFormProps extends Omit<OEEChartFormProps, "data"> {
  data: OEESingleAssetDataChart;
  config: LineChartForAssetConfigType;
  assetTypeOptions: AutocompleteOption[];
}

const EditLineChartForAssetForm: React.FC<EditLineChartForAssetFormProps> = ({
  data,
  config,
  widgetTitle,
  handleSaveChanges,
  assetTypeOptions,
}) => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = {
    ...cssSpacingStyles(theme),
    ...cssLayoutStyles,
  };
  const [selectedAsset, setSelectedAsset] = useState<string>(config.assetId);
  const [title, setTitle] = useState<string>(widgetTitle);
  const [unitId, setUnitId] = useState<string | null>(null);
  const [selectedUnit, setSelectedUnit] = useState<string | null>(null);
  const [highThreshold, setHighThreshold] = useState<number | null>(config.high);
  const [lowThreshold, setLowThreshold] = useState<number | null>(config.low);
  const [configureThreshold, setConfigureThreshold] = useState<boolean>(
    !!highThreshold || !!lowThreshold
  );

  const handleAssetChange = (e: React.ChangeEvent<{ value: string }>) => {
    const selectedValue = e.target.value as string;
    const selectedOption = assetTypeOptions.find(
      (option) => option.value === selectedValue
    );
    if (selectedOption) {
      setSelectedAsset(selectedOption.value);
    }
  };

  const handleSubmit = () => {
    const updatedChart: DynamicGridOEEChartOptions = {
      lineChartForAsset: {
        data,
        config: {
          ...config,
          assetId: selectedAsset,
          high: highThreshold,
          low: lowThreshold,
          unit: selectedUnit,
        },
      },
    };
    handleSaveChanges(updatedChart, title);
  };

  const handleUnitChange = (option: AutocompleteOption | null, isEmpty: boolean) => {
    if (isEmpty) {
      setUnitId(null);
      setSelectedUnit!("");
    } else {
      setUnitId(option ? option.value : "");
      setSelectedUnit!(option ? option.description : "");
    }
  };

  const handleConfigureThresholdChange = (val: boolean) => {
    setConfigureThreshold(val);
  };

  return (
    <Stack alignItems="center" justifyContent="center">
      <Grid container spacing={3} mb={3}>
        <Grid item xs={6}>
          <TextField
            label={t("Title")}
            onChange={(e) => setTitle(e.target.value)}
            value={title}
          />
        </Grid>
        <Grid item xs={6}>
          <Select
            selectOptions={assetTypeOptions}
            label={t("Select Asset")}
            onChange={(e) => handleAssetChange(e)}
            value={selectedAsset}
          />
        </Grid>

        <Grid item xs={12}>
          <Divider flexItem />
        </Grid>

        <Grid item xs={6}>
          <UnitsOfMeasureDropdown
            css={styles.widthLimit20}
            targetUnitValue={unitId}
            handleOnUnitsChange={handleUnitChange}
          />
        </Grid>

        <Grid item xs={6}></Grid>

        <Grid item xs={12}>
          <Checkbox
            css={styles.width100}
            label={t("Configure threshold values")}
            onChange={(e) => handleConfigureThresholdChange(e.target.checked)}
            checked={configureThreshold}
          />
        </Grid>

        {configureThreshold ? (
          <>
            <Grid item xs={6}>
              <TextField
                label={t("Low Threshold")}
                numberField={true}
                onChange={(e) => setLowThreshold!(Number(e.target.value))}
                value={lowThreshold}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      {extractUnitSymbol(selectedUnit || "")}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                label={t("High Threshold")}
                numberField={true}
                onChange={(e) => setHighThreshold!(Number(e.target.value))}
                value={highThreshold}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      {extractUnitSymbol(selectedUnit || "")}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </>
        ) : null}
      </Grid>

      <Button css={styles.widthLimit50} onClick={handleSubmit}>
        {t("Save Changes")}
      </Button>
    </Stack>
  );
};

export default EditLineChartForAssetForm;
