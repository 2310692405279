import { SerializedStyles } from "@emotion/react";
import TextWidget from "./TextWidget/TextWidget";
import GaugeWidget from "./GaugeWidget/GaugeWidget";
import CalendarWidget from "./AdvancedWidgets/CalendarWidget/CalendarWidget";
import Funnel from "./AdvancedWidgets/Funnel/Funnel";
import Network from "./AdvancedWidgets/Network/Network";
import Sankey from "./AdvancedWidgets/Sankey/Sankey";
import ScatterPlot from "./AdvancedWidgets/ScatterPlot/ScatterPlot";
import Sunburst from "./AdvancedWidgets/Sunburst/Sunburst";
import SwarmPlot from "./AdvancedWidgets/SwarmPlot/SwarmPlot";
import TimeRange from "./AdvancedWidgets/TimeRange/TimeRange";
import TreeMap from "./AdvancedWidgets/TreeMap/TreeMap";
import { DynamicGridChartOptions } from "../SmallComponents/DynamicGridLayout.tsx/dynamicGridUtils";
import { ChartXScaleMinMax, TimeChartCircle } from "./TimeChart/timeChartTypes";
import ExcellenceTimeChart from "../PageComponents/Excellence/ExcellenceCharts/ExcellenceTimeChart/ExcellenceTimeChart";
import ExcellenceBarChart from "../PageComponents/Excellence/ExcellenceCharts/ExcellenceBarChart/ExcellenceBarChart";
import ExcellencePieChart from "../PageComponents/Excellence/ExcellenceCharts/ExcellencePieChart/ExcellencePieChart";
import ExcellenceBoxPlot from "../PageComponents/Excellence/ExcellenceCharts/ExcellenceBoxPlot/ExcellenceBoxPlot";
import ExcellenceRadarChart from "../PageComponents/Excellence/ExcellenceCharts/ExcellenceRadarChart/ExcellenceRadarChart";
import ExcellenceHeatmap from "../PageComponents/Excellence/ExcellenceCharts/ExcellenceHeatmap/ExcellenceHeatmap";
import ExcellenceIndicator from "../PageComponents/Excellence/ExcellenceCharts/ExcellenceIndicator/ExcellenceIndicator";
import ExcellenceTable from "../PageComponents/Excellence/ExcellenceCharts/ExcellenceTable/ExcellenceTable";
import ExcellenceLineChart from "../PageComponents/Excellence/ExcellenceCharts/ExcellenceLineChart/ExcellenceLineChart";
import ExcellenceAreaChart from "../PageComponents/Excellence/ExcellenceCharts/ExcellenceAreaChart/ExcellenceAreaChart";

interface StaticExcellenceWidgetProps {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  chart: DynamicGridChartOptions;
  chartWithLiveData?: DynamicGridChartOptions;
  isStatic?: boolean;
  chartAnimate?: boolean;
  xScaleMinMax?: ChartXScaleMinMax;
  circles?: {
    circles: TimeChartCircle[] | null;
    setCircles: React.Dispatch<React.SetStateAction<TimeChartCircle[] | null>>;
  };
  widgetID: string;
}

const StaticExcellenceWidget: React.FC<StaticExcellenceWidgetProps> = ({
  className,
  chart,
  chartWithLiveData,
  isStatic,
  widgetID,
}) => {
  // Excellence widgets
  if (chart.lineChart) {
    return (
      <ExcellenceLineChart
        className={className}
        configuration={chart.lineChart.config}
        data={chart.lineChart.data}
        isStatic={isStatic}
        schema={chart.lineChart.dataSchema}
        resetChartFetch={false}
        tempFilters={[]}
      />
    );
  }
  if (chart.timeChart) {
    return (
      <ExcellenceTimeChart
        className={className}
        timeChart={chart.timeChart}
        isStatic
        chartMode={null}
        loading={false}
        titleComponent={null}
        resetChartFetch={false}
        tempFilters={[]}
      />
    );
  }
  if (chart.barChart) {
    return (
      <ExcellenceBarChart
        className={className}
        configuration={chart.barChart.config}
        data={chart.barChart.data}
        schema={chart.barChart.dataSchema}
        isStatic
        resetChartFetch={false}
        tempFilters={[]}
      />
    );
  }
  if (chart.columnChart) {
    return (
      <ExcellenceBarChart
        className={className}
        configuration={chart.columnChart.config}
        data={chart.columnChart.data}
        schema={chart.columnChart.dataSchema}
        isStatic
        resetChartFetch={false}
        tempFilters={[]}
      />
    );
  }
  if (chart.table) {
    return (
      <ExcellenceTable
        className={className}
        configuration={chart.table.config}
        data={chart.table.data}
        schema={chart.table.dataSchema}
        isStatic
        resetChartFetch={false}
        widgetID={widgetID}
        tempFilters={[]}
      />
    );
  }
  if (chart.pieChart) {
    return (
      <ExcellencePieChart
        className={className}
        configuration={chart.pieChart.config}
        data={chartWithLiveData?.pieChart?.data || chart.pieChart.data}
        schema={chart.pieChart.dataSchema}
        isStatic
        resetChartFetch={false}
        isDonut={false}
        tempFilters={[]}
      />
    );
  }
  if (chart.donutChart) {
    return (
      <ExcellencePieChart
        className={className}
        configuration={chart.donutChart.config}
        data={chartWithLiveData?.donutChart?.data || chart.donutChart.data}
        schema={chart.donutChart.dataSchema}
        isStatic
        resetChartFetch={false}
        isDonut
        tempFilters={[]}
      />
    );
  }
  if (chart.radarChart) {
    return (
      <ExcellenceRadarChart
        className={className}
        configuration={chart.radarChart.config}
        data={chart.radarChart.data}
        isStatic={isStatic}
        schema={chart.radarChart.dataSchema}
        resetChartFetch={false}
        tempFilters={[]}
      />
    );
  }
  if (chart.areaChart) {
    return (
      <ExcellenceAreaChart
        className={className}
        configuration={chart.areaChart.config}
        data={chart.areaChart.data}
        isStatic={isStatic}
        schema={chart.areaChart.dataSchema}
        resetChartFetch={false}
        tempFilters={[]}
      />
    );
  }
  if (chart.textWidget) {
    return (
      <TextWidget
        className={className}
        configuration={chart.textWidget.config}
        data={chart.textWidget.dataSchema?.content || ""}
        isStatic={isStatic}
      />
    );
  }
  if (chart.gaugeWidget) {
    return (
      <GaugeWidget
        className={className}
        configuration={chart.gaugeWidget.config}
        data={chart.gaugeWidget.data}
        isStatic={isStatic}
      />
    );
  }
  if (chart.indicatorWidget) {
    return (
      <ExcellenceIndicator
        configuration={chart.indicatorWidget.config}
      />
    );
  }

  // Advanced widgets
  if (chart.boxPlot) {
    return (
      <ExcellenceBoxPlot
        className={className}
        configuration={chart.boxPlot.config}
        data={chart.boxPlot.data}
        isStatic
        schema={chart.boxPlot.dataSchema}
        resetChartFetch={false}
        tempFilters={[]}
      />
    );
  }
  if (chart.heatMap) {
    return (
      <ExcellenceHeatmap
        className={className}
        configuration={chart.heatMap.config}
        data={chart.heatMap.data}
        isStatic
        schema={chart.heatMap.dataSchema}
        resetChartFetch={false}
        tempFilters={[]}
      />
    );
  }
  if (chart.calendar) {
    return (
      <CalendarWidget
        className={className}
        configuration={chart.calendar.config}
        data={chart.calendar.data}
        isStatic={isStatic}
      />
    );
  }
  if (chart.funnel) {
    return (
      <Funnel
        className={className}
        configuration={chart.funnel.config}
        data={chart.funnel.data}
        isStatic={isStatic}
      />
    );
  }
  if (chart.network) {
    return (
      <Network
        className={className}
        configuration={chart.network.config}
        data={chart.network.data}
        isStatic={isStatic}
      />
    );
  }
  if (chart.sankey) {
    return (
      <Sankey
        className={className}
        configuration={chart.sankey.config}
        data={chart.sankey.data}
        isStatic={isStatic}
      />
    );
  }
  if (chart.scatterPlot) {
    return (
      <ScatterPlot
        className={className}
        configuration={chart.scatterPlot.config}
        data={chart.scatterPlot.data}
        isStatic={isStatic}
      />
    );
  }
  if (chart.sunburst) {
    return (
      <Sunburst
        className={className}
        configuration={chart.sunburst.config}
        data={chart.sunburst.data}
        isStatic={isStatic}
      />
    );
  }
  if (chart.swarmPlot) {
    return (
      <SwarmPlot
        className={className}
        configuration={chart.swarmPlot.config}
        data={chart.swarmPlot.data}
        isStatic={isStatic}
      />
    );
  }
  if (chart.timeRange) {
    return (
      <TimeRange
        className={className}
        configuration={chart.timeRange.config}
        data={chart.timeRange.data}
        isStatic={isStatic}
      />
    );
  }
  if (chart.treeMap) {
    return (
      <TreeMap
        className={className}
        configuration={chart.treeMap.config}
        data={chart.treeMap.data}
        isStatic={isStatic}
      />
    );
  }

  return null;
};

export default StaticExcellenceWidget;
