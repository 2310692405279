import { SerializedStyles } from "@emotion/react";
import { Box, Grid } from "@mui/material";
import {
  barChartAssetByHoursData,
  barChartLossesByPeriodDefaultData,
  barChartLossesByType,
  oEEAssetChartByAvailabilityDefaultData,
  oEEBarChartDataForAssetsDefaultData,
  OEEBulletChartDefaultData,
  oEERadialChartDefaultData,
  oEESingleAssetDefaultData,
  paretoChartData,
} from "./oEEChartsData";
import { DynamicGridOEEChartOptions } from "../../../SmallComponents/DynamicGridLayout.tsx/dynamicGridUtils";
import AddOEEChartIconButton from "./AddOEEChartIconButton";

interface AddNewOEEChartProps {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  handleAddNewOEEChart: (chart: DynamicGridOEEChartOptions, title: string) => void;
}

const AddNewOEEChart: React.FC<AddNewOEEChartProps> = ({
  className,
  handleAddNewOEEChart,
}) => {
  return (
    <Box component="div" className={className} sx={{ maxWidth: "600px" }}>
      <Grid container spacing={0.6}>
        <Grid item xs={2.4}>
          <AddOEEChartIconButton
            type="bulletChart"
            onClick={() =>
              handleAddNewOEEChart(
                {
                  bulletChart: OEEBulletChartDefaultData,
                },
                "Current Cycle Rate"
              )
            }
          />
        </Grid>
        <Grid item xs={2.4}>
          <AddOEEChartIconButton
            type="radialChart"
            onClick={() =>
              handleAddNewOEEChart(
                {
                  radialChart: oEERadialChartDefaultData,
                },
                "Overall"
              )
            }
          />
        </Grid>
        <Grid item xs={2.4}>
          <AddOEEChartIconButton
            type="availabilityBarChart"
            onClick={() =>
              handleAddNewOEEChart(
                {
                  availabilityBarChart: oEEAssetChartByAvailabilityDefaultData,
                },
                "Availability Bar Chart"
              )
            }
          />
        </Grid>
        <Grid item xs={2.4}>
          <AddOEEChartIconButton
            type="assetByHourBarChart"
            onClick={() =>
              handleAddNewOEEChart(
                {
                  assetByHourBarChart: barChartAssetByHoursData,
                },
                "Asset By Hour Bar Chart"
              )
            }
          />
        </Grid>
        <Grid item xs={2.4}>
          <AddOEEChartIconButton
            type="paretoBarChart"
            onClick={() =>
              handleAddNewOEEChart(
                {
                  paretoBarChart: paretoChartData,
                },
                "Pareto Bar Chart"
              )
            }
          />
        </Grid>
        <Grid item xs={2.4}>
          <AddOEEChartIconButton
            type="lineChartForAsset"
            onClick={() =>
              handleAddNewOEEChart(
                {
                  lineChartForAsset: oEESingleAssetDefaultData,
                },
                "Line Chart For Asset"
              )
            }
          />
        </Grid>
        <Grid item xs={2.4}>
          <AddOEEChartIconButton
            type="barChartForAssetByType"
            onClick={() =>
              handleAddNewOEEChart(
                {
                  barChartForAssetByType: oEEBarChartDataForAssetsDefaultData,
                },
                "Bar Chart For Asset By Type"
              )
            }
          />
        </Grid>
        <Grid item xs={2.4}>
          <AddOEEChartIconButton
            type="barChartLossesByType"
            onClick={() =>
              handleAddNewOEEChart(
                {
                  barChartLossesByType: barChartLossesByType,
                },
                "Bar Chart Losses By Type"
              )
            }
          />
        </Grid>
        <Grid item xs={2.4}>
          <AddOEEChartIconButton
            type="barChartLossesByPeriod"
            onClick={() =>
              handleAddNewOEEChart(
                {
                  barChartLossesByPeriod: barChartLossesByPeriodDefaultData,
                },
                "Bar Chart Losses By Period"
              )
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddNewOEEChart;
