import { Query } from "../callApi";
import {
  DeleteQueryOEEDashboard,
  PostQueryCreateAssetInput,
  PostQueryCreateLossInput,
  PostQueryCreateSubcategoryInput,
  PostQueryOEEDashboardInput,
  PutQueryAssetInput,
  PutQueryOEEDashboardInput,
  PutQuerySubcategoryInput,
} from "./apiOEEInputs";
import { PostQueryOEEWidgetsInput } from "./apiOEESnippets";

export const postQueryCreateSubcategory = (
  input: PostQueryCreateSubcategoryInput
): Query => ({
  endpoint: "oee/subcategory",
  method: "POST",
  variables: input,
});

export const postQueryCreateLoss = (input: PostQueryCreateLossInput): Query => ({
  endpoint: "oee/loss",
  method: "POST",
  variables: input,
});

export const postQueryCreateAsset = (
  assetId: string,
  input: PostQueryCreateAssetInput
): Query => ({
  endpoint: `oee/asset/${assetId}/categories`,
  method: "POST",
  variables: input,
});

export const postQuerySubcategoryDelete = (subcategoryId: string): Query => ({
  endpoint: `oee/subcategory/${subcategoryId}`,
  method: "DELETE",
});

export const putQuerySubcategory = (
  input: PutQuerySubcategoryInput,
  subcategoryId: string
): Query => ({
  endpoint: `oee/subcategory/${subcategoryId}`,
  method: "PUT",
  variables: input,
});

export const putQueryLoss = (input: PostQueryCreateLossInput, lossId: string): Query => ({
  endpoint: `oee/loss/${lossId}`,
  method: "PUT",
  variables: input,
});

export const putQueryAssets = (input: PutQueryAssetInput, mappingId: string): Query => ({
  endpoint: `oee/asset/mapping/${mappingId}`,
  method: "PUT",
  variables: input,
});

export const postQueryLossDelete = (lossId: string): Query => ({
  endpoint: `oee/loss/${lossId}`,
  method: "DELETE",
});

export const deleteQueryAsset = (mappingId: string): Query => ({
  endpoint: `oee/asset/mapping/${mappingId}`,
  method: "DELETE",
});

export const postQueryOEEDashboard = (input: PostQueryOEEDashboardInput): Query => ({
  endpoint: "oee/dashboard",
  method: "POST",
  variables: input,
});

export const putQueryOEEDashboard = (input: PutQueryOEEDashboardInput): Query => ({
  endpoint: `/oee/dashboard/${input.id}`,
  method: "PUT",
  variables: { name: input.name },
});

export const deleteQueryOEEDashboard = (id: DeleteQueryOEEDashboard): Query => ({
  endpoint: `oee/dashboard/${id}`,
  method: "DELETE",
});

export const postQueryOEEWidgets = (input: PostQueryOEEWidgetsInput): Query => ({
  endpoint: "oee/widgets",
  method: "POST",
  variables: input,
});
