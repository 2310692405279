import {
  Box,
  Grid,
  Stack,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { css } from "@emotion/react";
import { useEffect, useState } from "react";
import cssLayoutStyles from "../../../Global/Styles/layout";
import cssSpacingStyles from "../../../Global/Styles/spacing";
import {
  SelectOption,
  AutocompleteGroupedOption,
} from "../../../Global/Types/commonTypes";
import { handleGetSelectOption } from "../../../Global/Utils/commonFunctions";
import { ExcellenceParameter } from "../../../GraphQL/Excellence/graphQLTypesExcellence";
import AutocompleteGrouped from "../../MaterialUI/FormFields/AutocompleteGrouped";
import {
  ChartConfigFormValuesWithTitle,
  ExcellenceParamMapping,
} from "../EditExcellenceChartForms/excellenceChartFormUtils";
import {
  IndicatorParameter,
  IndicatorWidgetConfiguration,
} from "./indicatorWidgetTypes";
import Autocomplete from "../../MaterialUI/FormFields/Autocomplete";
import Alert from "../../MaterialUI/Alert";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useLanguageContext } from "../../../context/LanguageContext";

const cssStyles = (theme: Theme) => ({
  autocompleteGroup: css({
    position: "sticky",
    top: "-8px",
    padding: "4px 10px",
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.secondary.light,
  }),
  autocompleteList: css({
    padding: 0,
  }),
});
type ParamRow = AutocompleteGroupedOption | null;
const EMPTY_PARAM_ROW: ParamRow = null;

interface EditIndicatorWidgetDataSchemaProps {
  parameters: ExcellenceParameter[];
  selectedConnection: SelectOption;
  setSelectedConnection: React.Dispatch<React.SetStateAction<SelectOption>>;
  paramMapping: ExcellenceParamMapping | null;
  config: ChartConfigFormValuesWithTitle<IndicatorWidgetConfiguration>;
  setUpdatedConfig: React.Dispatch<
    React.SetStateAction<ChartConfigFormValuesWithTitle<IndicatorWidgetConfiguration>>
  >;
}

const EditIndicatorWidgetDataSchema: React.FC<EditIndicatorWidgetDataSchemaProps> = ({
  parameters,
  selectedConnection,
  setSelectedConnection,
  paramMapping,
  config,
  setUpdatedConfig
}) => {
  const theme = useTheme();
  const styles = {
    ...cssSpacingStyles(theme),
    ...cssStyles(theme),
    ...cssLayoutStyles,
  };
  const { t } = useLanguageContext();
  // options
  const [nodeNumbOptions, setNodeNumbOptions] = useState<AutocompleteGroupedOption[]>([]);
  const [connectionOptions, setConnectionOptions] = useState<SelectOption[]>([]);
  // selected values
  const [selectedParam, setSelectedParam] = useState<ParamRow>(EMPTY_PARAM_ROW);

  // system-used
  const [alertMessage, setAlertMessage] = useState<string | null>(null);

  useEffect(() => {
    if (paramMapping && config?.selectedParameter) {
      setSelectedConnection({
        value: paramMapping[config?.selectedParameter?.parameterId]?.connection,
        description: paramMapping[config?.selectedParameter?.parameterId]?.connectionName,
      });
      setSelectedParam({
        description: paramMapping[config?.selectedParameter?.parameterId]?.paramName,
        groupName: paramMapping[config?.selectedParameter?.parameterId]?.connectionName,
        value: paramMapping[config?.selectedParameter?.parameterId]?.paramID
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const connectionsRecord: Record<string, string> = {};
    parameters.forEach((item) => {
      connectionsRecord[item.container] = item.containerName;
    });

    const allConnectionOptions: SelectOption[] = Object.entries(connectionsRecord).map(
      ([key, value]) => ({
        value: key,
        description: value,
      })
    );

    handleGetSelectOption([...new Set(parameters.map((item) => item.container))]);
    setConnectionOptions(allConnectionOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parameters]);

  useEffect(() => {
    if (paramMapping) {
      const paramOptions = handleGetParamOptions(parameters, selectedConnection);
      const numbOnlyOptions = paramOptions.filter(
        (node) =>
          paramMapping?.[node.value]?.type && paramMapping[node.value].type === "number"
      );

      setNodeNumbOptions(numbOnlyOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedConnection, paramMapping]);

  useEffect(() => {
      if (!selectedConnection.value || !selectedParam?.value) {
        setUpdatedConfig((prev) => {
          const { selectedParameter, ...rest } = prev;
          return { ...rest };
        });
      }
      
    if (
      selectedConnection &&
      selectedParam &&
      paramMapping
    ) {
      if ( selectedParam.value && selectedConnection.value ) {
        const param: IndicatorParameter | null = {
          container: paramMapping[selectedParam.value].connection,
          parameterId: selectedParam.value,
        };

        if (param) {
          setUpdatedConfig((prev) => ({
            ...prev,
            selectedParameter: param
          }));
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedParam]);

  const handleOnConnectionChange = (val: SelectOption) => {
    setSelectedConnection(val || { value: "", description: "" });
    setSelectedParam(EMPTY_PARAM_ROW);
    setAlertMessage(null);
  };

  const handleOnParamChange = (val: AutocompleteGroupedOption) => {
    const newParam = val?.value
      ? {
        ...val,
      }
      : null;

    setSelectedParam(newParam);
  };

  if (!parameters?.length) {
    return (
      <Box component="div">
        <Typography variant="h3" color="error">
          {t("You don't have access to any active connections")}
        </Typography>
      </Box>
    );
  }

  return (
    <Stack
      css={styles.contentBreak}
      spacing={3}
      alignItems="center"
      justifyContent="center"
    >
      <Stack css={styles.width100} spacing={3} direction={{ xs: "column", sm: "row" }}>
        <Autocomplete
          css={styles.width100}
          label={t("Selected Connection")}
          options={connectionOptions}
          value={selectedConnection}
          handleOnChange={handleOnConnectionChange}
        />
      </Stack>

      {selectedConnection ? (
        <Grid style={{ margin: 0 }} container spacing={3}>
          <Grid style={{ paddingLeft: theme.spacing(1.5) }} item xs={12}>
            <Stack direction="row" spacing={2} alignItems="center">
              <AutocompleteGrouped
                css={styles.width100}
                label={t("Parameter")}
                options={nodeNumbOptions}
                value={selectedParam}
                handleOnChange={handleOnParamChange}
              />
              <Tooltip title={t("Only parameters with numeric values are available")}>
                <InfoOutlinedIcon style={{ color: theme.palette.text.secondary }} />
              </Tooltip>
            </Stack>
          </Grid>
        </Grid>
      ) : null}

      <Alert
        css={[styles.reverseContentBreak, styles.contentBreak]}
        message={alertMessage}
        showAlert={!!alertMessage}
        severity={"warning"}
      />
    </Stack>
  );
};

export default EditIndicatorWidgetDataSchema;

const handleGetParamOptions = (
  parameters: ExcellenceParameter[],
  selectedConnection: SelectOption
): AutocompleteGroupedOption[] => {
  const result =
    parameters
      .map((item) => ({
        groupName: item.container,
        value: item.id,
        description: item.name,
      }))
      .filter((node) =>
        // selectedConnectionsArr.some((sub) => sub.value === node.groupName)
        node.groupName === selectedConnection.value
      ) || [];

  const resultWithGroupNames = result.map((item) => {
    // const found = selectedConnectionsArr.find((conn) => conn.value === item.groupName);
    const found = selectedConnection.value === item.groupName ? selectedConnection : null;
    const name = found?.description || item.groupName;
    return {
      ...item,
      groupName: name,
    };
  });

  return resultWithGroupNames;
};
