import React, { useEffect, useRef, useState } from "react";
import { Box, Grid, IconButton, Stack, useMediaQuery } from "@mui/material";
import ExcellenceDashboardCard from "../../Components/PageComponents/MyDashboards/ExcellenceDashboardCard";
import useTheme from "@mui/material/styles/useTheme";
import cssSpacingStyles from "../../Global/Styles/spacing";
import Button from "../../Components/MaterialUI/Button";
import Modal from "../../Components/MaterialUI/Modal";
import cssLayoutStyles from "../../Global/Styles/layout";
import CreateCustomLayoutForm from "../../Components/Forms/DynamicGridLayoutForms/CreateDynamicGridLayoutForm";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import callApi from "../../Api/callApi";
import { postQueryExcellenceDashboard } from "../../Api/Excellence/apiExcellencePostQueries";
import { useAuthedContext } from "../../context/AuthContext";
import { PostQueryExcellenceDashboardInput } from "../../Api/Excellence/apiExcellenceInputs";
import { FormStatuses } from "../../Global/Types/commonTypes";
import {
  GetQueryExcellenceDashboardsSnippet,
  PostQueryExcellenceDashboard,
} from "../../Api/Excellence/apiExcellenceSnippets";
import { getQueryExcellenceDashboards } from "../../Api/Excellence/apiExcellenceGetQueries";
import Alert from "../../Components/MaterialUI/Alert";
import useContainerDimensions from "../../Global/Hooks/useContainerDimensions";
import { useLocation, useOutletContext } from "react-router-dom";
import { AppRouterProps } from "../../Layout/layoutVariables";
import TextField from "../../Components/MaterialUI/FormFields/TextFields";
import { useLanguageContext } from "../../context/LanguageContext";
import Select from "../../Components/MaterialUI/FormFields/Select";
import { handleGetSelectOption } from "../../Global/Utils/commonFunctions";
import CustomIconsTooltip from "../../Components/SmallComponents/Tooltip/CustomIconsTooltip";
import PageTitle from "../../Components/SmallComponents/PageTitle/PageTitle";

type SortOption =
  | "Alphabetic ASC"
  | "Alphabetic DESC"
  | "Last Updated ASC"
  | "Last Updated DESC"
  | "Last Created ASC"
  | "Last Created DESC";
const sortOptionValues: SortOption[] = [
  "Alphabetic ASC",
  "Alphabetic DESC",
  "Last Updated ASC",
  "Last Updated DESC",
  "Last Created ASC",
  "Last Created DESC",
];
const LOCAL_DASHBOARD_KEY = "dashboardsSort";

const MyDashboardsPage: React.FC = () => {
  const theme = useTheme();
  const styles = {
    ...cssSpacingStyles(theme),
    ...cssLayoutStyles,
  };
  const { t } = useLanguageContext();
  const location = useLocation();
  const [pageStatus, setPageStatus] = useState<FormStatuses>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [dashboards, setDashboards] = useState<GetQueryExcellenceDashboardsSnippet>([]);
  const [open, setOpen] = useState<boolean>(false);

  const [displayedDashboards, setDisplayedDashboards] =
    useState<GetQueryExcellenceDashboardsSnippet>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [blockFetch, setBlockFetch] = useState<boolean>(false);
  const [selectedSort, setSelectedSort] = useState<SortOption>(
    (localStorage.getItem(LOCAL_DASHBOARD_KEY) as SortOption) || "Last Updated ASC"
  );
  const { setAuthedUser } = useAuthedContext();
  const gridLayoutRef = useRef<HTMLDivElement>(null);
  const { width: gridLayoutWidth } = useContainerDimensions(gridLayoutRef);
  const breakpoints = theme.breakpoints.values;
  const mdMediaQuery = useMediaQuery("(max-width:899px)");
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);
  const { smMediaQuery, setExtraRightNavMenu, setExtraTopNavMenu } =
    useOutletContext<AppRouterProps>();

  const sortOptions = handleGetSelectOption(sortOptionValues).map((option) => ({
    ...option,
    description: t(option.value),
  }));

  useEffect(() => {
    (async () => {
      if (gridLayoutWidth && !blockFetch) {
        try {
          setPageStatus("loading");
          setAlertMessage(t("Loading..."));

          const isLargeScreen = gridLayoutWidth > breakpoints.md;
          const data = await callApi<GetQueryExcellenceDashboardsSnippet>({
            query: getQueryExcellenceDashboards(isLargeScreen ? "desktop" : "mobile"),
            auth: { setAuthedUser },
          });

          setDashboards(data);
          setPageStatus("success");
          setAlertMessage(null);
        } catch (err) {
          console.log("useEffect err ", err);
          setPageStatus("error");
          setAlertMessage(t("Something went wrong"));
        }
      }
      setBlockFetch(true);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridLayoutWidth, blockFetch]);

  useEffect(() => {
    if (smMediaQuery) {
      setExtraTopNavMenu(null);
      setExtraRightNavMenu(null);
    } else {
      setExtraTopNavMenu(<PageTitle title={t("My Dashboards")} />);
      setExtraRightNavMenu(<CreateNewDashboardMenu handleOpenModal={handleOpenModal} />);
    }

    return () => {
      setExtraTopNavMenu(null);
      setExtraRightNavMenu(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smMediaQuery]);

  useEffect(() => {
    setBlockFetch(false);
  }, [mdMediaQuery]);

  useEffect(() => {
    // 1. filter based on the search query
    const filteredDashboards = !searchQuery
      ? dashboards
      : dashboards.filter((item) =>
          item.name.toLowerCase().includes(searchQuery.toLowerCase())
        );

    // 2. sort
    const sortedDashboards = sortDashboards(filteredDashboards.slice(), selectedSort);

    setDisplayedDashboards(sortedDashboards);
  }, [dashboards, searchQuery, selectedSort]);

  const handleCreateNewDashboard = async (name: string) => {
    try {
      const input: PostQueryExcellenceDashboardInput = {
        name: name,
      };

      const data = await callApi<PostQueryExcellenceDashboard>({
        query: postQueryExcellenceDashboard(input),
        auth: { setAuthedUser },
      });
      setUnsavedChanges(false);
      return `${data.id}`;
    } catch (err) {
      console.log("MyDashboardsPage err: ", err);
    }
  };

  const handleOpenModal = () => {
    setOpen(true);
  };
  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleOnSort = (e: React.ChangeEvent<HTMLInputElement>) => {
    const sortOption = e.target.value as SortOption;
    setSelectedSort(sortOption);
    localStorage.setItem(LOCAL_DASHBOARD_KEY, sortOption);
  };

  const handleSetUnsavedChanges = (unsavedChanges: boolean) => {
    if (unsavedChanges) {
      setUnsavedChanges(true);
    }
  };

  return (
    <Box
      component="div"
      css={[styles.width100, styles.flexCenter]}
      ref={gridLayoutRef}
      flexDirection="column"
    >
      {smMediaQuery ? (
        <>
          <PageTitle css={styles.labelBreak} title={t("My Dashboards")} />
          <Button css={styles.sectionBreak} onClick={handleOpenModal}>
            {t("Create New Dashboard")}
          </Button>
        </>
      ) : null}
      <Alert
        css={[styles.width100, styles.widthLimit25]}
        message={alertMessage}
        showAlert={!!alertMessage}
        severity={pageStatus}
      />
      <Grid
        css={[styles.sectionBreak, styles.width100]}
        container
        spacing={2}
        zeroMinWidth
      >
        {dashboards.length ? (
          <>
            <Grid item xs={12} sm={8}>
              <TextField
                label={t("Search")}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Select
                label={t("Sort by")}
                value={selectedSort}
                selectOptions={sortOptions}
                onChange={handleOnSort}
              />
            </Grid>
          </>
        ) : null}

        {displayedDashboards.map((dashboard) => (
          <Grid
            css={styles.heightAuto}
            key={`key-${dashboard.id}`}
            item
            xs={12}
            sm={6}
            md={4}
          >
            <ExcellenceDashboardCard
              css={[styles.width100, styles.height100]}
              label={dashboard.name}
              id={`${dashboard.id}`}
              firstItem={dashboard.first_item || null}
              location={location.pathname}
            />
          </Grid>
        ))}
      </Grid>
      <Modal
        open={open}
        onClose={handleCloseModal}
        fullWidth
        maxWidth="sm"
        label={t("Create New Dashboard Label")}
        unsavedChanges={unsavedChanges}
        setUnsavedChanges={setUnsavedChanges}
      >
        <CreateCustomLayoutForm
          category="excellence"
          handleOnSubmit={handleCreateNewDashboard}
          handleSetUnsavedChanges={handleSetUnsavedChanges}
          setUnsavedChanges={setUnsavedChanges}
        />
      </Modal>
    </Box>
  );
};

export default MyDashboardsPage;

interface CreateNewDashboardMenuProps {
  handleOpenModal: () => void;
}

const CreateNewDashboardMenu: React.FC<CreateNewDashboardMenuProps> = ({
  handleOpenModal,
}) => {
  const { t } = useLanguageContext();
  return (
    <Stack alignItems="center">
      <CustomIconsTooltip tooltipText={t("Add")}>
        <IconButton aria-label="Create New Dashboard" onClick={handleOpenModal}>
          <AddOutlinedIcon />
        </IconButton>
      </CustomIconsTooltip>
    </Stack>
  );
};

const sortDashboards = (
  items: GetQueryExcellenceDashboardsSnippet,
  sortOption: SortOption
): GetQueryExcellenceDashboardsSnippet => {
  const getTimeOrMin = (date: Date | string | null) =>
    date ? new Date(date).getTime() : Number.MIN_SAFE_INTEGER;
  const getTimeOrMax = (date: Date | string | null) =>
    date ? new Date(date).getTime() : Number.MAX_SAFE_INTEGER;

  switch (sortOption) {
    case "Alphabetic ASC":
      return items.sort((a, b) => a.name.localeCompare(b.name));

    case "Alphabetic DESC":
      return items.sort((a, b) => b.name.localeCompare(a.name));

    case "Last Updated ASC":
      return items.sort((a, b) => {
        const aTime = getTimeOrMin(a?.updated_at || null);
        const bTime = getTimeOrMin(b?.updated_at || null);
        if (a?.updated_at && !b?.updated_at) return -1;
        if (!a?.updated_at && b?.updated_at) return 1;
        return aTime - bTime;
      });

    case "Last Updated DESC":
      return items.sort((a, b) => {
        const aTime = getTimeOrMax(a?.updated_at || null);
        const bTime = getTimeOrMax(b?.updated_at || null);
        if (a?.updated_at && !b?.updated_at) return -1;
        if (!a?.updated_at && b?.updated_at) return 1;
        return bTime - aTime;
      });

    case "Last Created ASC":
      return items.sort((a, b) => {
        const aTime = getTimeOrMin(a.created_at || null);
        const bTime = getTimeOrMin(b.created_at || null);
        if (a.created_at && !b.created_at) return -1;
        if (!a.created_at && b.created_at) return 1;
        return aTime - bTime;
      });
    case "Last Created DESC":
      return items.sort((a, b) => {
        const aTime = getTimeOrMax(a.created_at || null);
        const bTime = getTimeOrMax(b.created_at || null);
        if (a.created_at && !b.created_at) return -1;
        if (!a.created_at && b.created_at) return 1;
        return bTime - aTime;
      });

    default:
      return items;
  }
};
