import { format, subYears } from "date-fns";
import {
  AssetByHourBarChartDataType,
  AvailabilityBarChartDataType,
  BarChartForAssetByTypeDataType,
  BarChartForLossesByTypeDataType,
  BarChartLossesByPeriodDataType,
  BulletChartDataType,
  LineChartForAssetDataType,
  ParetoBarChartDataType,
  RadialChartDataType,
} from "./oeeDashboardUtils";

const currentDate = new Date();
const currentDateStr = format(currentDate, "yyyy-MM-dd");
const currentDateISO = currentDate.toISOString()
const dateAYearAgoISO = subYears(currentDate, 1).toISOString();

export const oEEBarChartDataForAssetsDefaultData: BarChartForAssetByTypeDataType = {
  config: {
    period: "M",
    endDate: currentDateISO,
    startDate: dateAYearAgoISO,
    type: "type",
  },
  data: [
    {
      asset: "Press Brake 1",
      Undeclared: 8700,
      Availability: 3600,
      Performance: 1140,
      Quality: 3600,
      "Outside Plant Control": 960,
    },
    {
      asset: "Laser Cutter 1",
      Undeclared: 7500,
      Availability: 3600,
      Performance: 1640,
      Quality: 3400,
      "Outside Plant Control": 660,
    },
    {
      asset: "Assembly Station 1",
      Undeclared: 6500,
      Availability: 2500,
      Performance: 2650,
      Quality: 2900,
      "Outside Plant Control": 1130,
    },
    {
      asset: "Labeling Machine 2",
      Undeclared: 4000,
      Availability: 3200,
      Performance: 1300,
      Quality: 2600,
      "Outside Plant Control": 1600,
    },
    {
      asset: "Packaging Line 2",
      Undeclared: 5000,
      Availability: 2090,
      Performance: 1800,
      Quality: 1200,
      "Outside Plant Control": 1400,
    },
  ],
};

export const barChartLossesByType: BarChartForLossesByTypeDataType = {
  config: {
    period: "M",
    endDate: currentDateISO,
    startDate: dateAYearAgoISO,
    type: "type",
  },
  data: [
    {
      type: "Outside Plant Control",
      duration: 8280,
    },
    {
      type: "Quality",
      duration: 10260,
    },
    {
      type: "Performance",
      duration: 12900,
    },
    {
      type: "Availability",
      duration: 13500,
    },
    {
      type: "Undeclared",
      duration: 23340,
    },
  ],
};

export const barChartLossesByPeriodDefaultData: BarChartLossesByPeriodDataType = {
  config: {
    period: "M",
    endDate: currentDateISO,
    startDate: dateAYearAgoISO,
    type: "type",
  },
  data: [
    {
      date: "7/2/2024",
      Undeclared: 8700,
      Availability: 3600,
      Performance: 1140,
      Quality: 3600,
      "Outside Plant Control": 960,
    },
    {
      date: "7/3/2024",
      Undeclared: 3360,
      Availability: 2040,
      Performance: 1500,
      Quality: 1560,
      "Outside Plant Control": 1320,
    },
    {
      date: "7/4/2024",
      Undeclared: 3720,
      Availability: 1920,
      Performance: 1860,
      Quality: 1200,
      "Outside Plant Control": 660,
    },
    {
      date: "7/5/2024",
      Undeclared: 1860,
      Availability: 3180,
      Performance: 1000,
      Quality: 780,
      "Outside Plant Control": 1060,
    },
    {
      date: "7/6/2024",
      Undeclared: 1140,
      Availability: 660,
      Performance: 1860,
      Quality: 2280,
      "Outside Plant Control": 1200,
    },
    {
      date: "7/7/2024",
      Undeclared: 1060,
      Availability: 1320,
      Performance: 1260,
      Quality: 1500,
      "Outside Plant Control": 1260,
    },
    {
      date: "7/8/2024",
      Undeclared: 1200,
      Availability: 780,
      Performance: 720,
      Quality: 1200,
      "Outside Plant Control": 480,
    },
  ],
};

export const oEERadialChartDefaultData: RadialChartDataType = {
  config: {
    period: "M",
    endDate: currentDateISO,
    startDate: dateAYearAgoISO,
    target: 80,
  },
  data: {
    chart_data: [
      {
        id: "Quality",
        data: [
          {
            x: "Quality",
            y: 83.2,
          },
        ],
      },
      {
        id: "Performance",
        data: [
          {
            x: "Performance",
            y: 87.8,
          },
        ],
      },
      {
        id: "Availability",
        data: [
          {
            x: "Availability",
            y: 92.8,
          },
        ],
      },
    ],
  },
};

export const oEEAssetChartByAvailabilityDefaultData: AvailabilityBarChartDataType = {
  config: {
    period: "M",
    assetId: "667020b8aafe32a08a125f2a",
    endDate: currentDateISO,
    startDate: dateAYearAgoISO,
  },
  data: [
    {
      date: "7/2/2024",
      workingTime: 5326,
    },
    {
      date: "7/3/2024",
      workingTime: 6453,
    },
    {
      date: "7/4/2024",
      workingTime: 6432,
    },
    {
      date: "7/5/2024",
      workingTime: 5121,
    },
    {
      date: "7/6/2024",
      workingTime: 5698,
    },
    {
      date: "7/7/2024",
      workingTime: 6539,
    },
    {
      date: "7/8/2024",
      workingTime: 6326,
    },
  ],
};

export const oEESingleAssetDefaultData: LineChartForAssetDataType = {
  config: {
    period: "M",
    assetId: "667020b8aafe32a08a125f2a",
    endDate: currentDateISO,
    startDate: dateAYearAgoISO,
    unit: null,
    low: null,
    high: null,
  },
  data: {
    high: 19,
    low: 14,
    chart_data: [
      {
        id: "Press Brake 1",
        data: [
          {
            x: "1/7/2024",
            y: 15,
          },
          {
            x: "2/7/2024",
            y: 20,
          },
          {
            x: "3/7/2024",
            y: 17,
          },
          {
            x: "4/7/2024",
            y: 14,
          },
          {
            x: "5/7/2024",
            y: 10,
          },
          {
            x: "6/7/2024",
            y: 12,
          },
          {
            x: "7/7/2024",
            y: 16,
          },
          {
            x: "8/7/2024",
            y: 18,
          },
        ],
      },
    ],
  },
};

export const paretoChartData: ParetoBarChartDataType = {
  config: {
    period: "M",
    assetId: "667020b8aafe32a08a125f2a",
    endDate: currentDateISO,
    startDate: dateAYearAgoISO,
  },
  data: {
    asset: "Press Brake 1",
    chartData: [
      {
        category: "Material Defects",
        total_duration: 10985,
      },
      {
        category: "Preventive Maintenance",
        total_duration: 9565,
      },
      {
        category: "Mechanical Error",
        total_duration: 8968,
      },
      {
        category: "Electrical Error",
        total_duration: 6596,
      },
      {
        category: "Software/Application Stops",
        total_duration: 5874,
      },
      {
        category: "Inspection",
        total_duration: 4963,
      },
      {
        category: "Slowdown due to Queuing",
        total_duration: 3612,
      },
      {
        category: "Material Shortages",
        total_duration: 2947,
      },
    ],
  },
};

export const barChartAssetByHoursData: AssetByHourBarChartDataType = {
  config: {
    date: currentDateStr,
    assetId: "667020b8aafe32a08a125f2a",
  },
  data: {
    asset: "Press Brake 1",
    chartData: [
      {
        hour: "08:00",
        values: [
          { category: "ProductChangeover", value: 19 },
          { category: "Production", value: 32 },
          { category: "Downtime", value: 5 },
          { category: "SpeedLoss", value: 4 },
        ],
      },
      {
        hour: "09:00",
        values: [
          { category: "Downtime", value: 15 },
          { category: "SpeedLoss", value: 5 },
          { category: "Production", value: 40 },
        ],
      },
      {
        hour: "10:00",
        values: [
          { category: "Production", value: 45 },
          { category: "SpeedLoss", value: 10 },
          { category: "Downtime", value: 5 },
        ],
      },
      {
        hour: "11:00",
        values: [
          { category: "Production", value: 50 },
          { category: "SpeedLoss", value: 10 },
        ],
      },
      {
        hour: "12:00",
        values: [
          { category: "Production", value: 30 },
          { category: "Break", value: 30 },
        ],
      },
      {
        hour: "13:00",
        values: [
          { category: "Production", value: 50 },
          { category: "SpeedLoss", value: 10 },
        ],
      },
      {
        hour: "14:00",
        values: [{ category: "Production", value: 60 }],
      },
      {
        hour: "15:00",
        values: [{ category: "Production", value: 60 }],
      },
      {
        hour: "16:00",
        values: [
          { category: "Production", value: 40 },
          { category: "SpeedLoss", value: 10 },
          { category: "Production", value: 10 },
        ],
      },
    ],
  },
};

export const OEEBulletChartDefaultData: BulletChartDataType = {
  data: {
    id: "L01-M05 - Press Brake 1",
    ranges: [0, 0.72, 1.14, 1.2, 1.44],
    measures: [1.02],
    markers: [1.2],
  },
  config: {
    assetId: "667020b8aafe32a08a125f2a",
  },
};
