import ContentBox from "../../../MaterialUI/ContentBox";
import { SerializedStyles, css } from "@emotion/react";
import { ErrorBoundary } from "react-error-boundary";
import { Box } from "@mui/material";
import Alert from "../../../MaterialUI/Alert";
import { DynamicGridItemData } from "../../../SmallComponents/DynamicGridLayout.tsx/dynamicGridUtils";
import StaticOEEWidget from "./StaticOEEWidget";

const cssStyles = {
  staticWidget: css({
    height: "300px",
    width: "100%",
    overflow: "hidden",
  }),
};

interface OEEDashboardCardProps {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  label: string;
  id: string;
  firstItem: DynamicGridItemData | null;
}

const OEEDashboardCard: React.FC<OEEDashboardCardProps> = ({
  className,
  label,
  id,
  firstItem,
}) => {
  const styles = { ...cssStyles };
  const redirectURL = `/GIANT-Operations-Suite/OEE/Dashboard/${id}`;

  return (
    <ContentBox className={className} label={label} clickAction={{ to: redirectURL }}>
      <ErrorBoundary
        FallbackComponent={() => (
          <Box component="div" css={styles.staticWidget}>
            <Alert severity="error" message="Something went wrong" showAlert />
          </Box>
        )}
      >
        {!firstItem?.widget?.oeeChart ? null : (
          <StaticOEEWidget
            css={styles.staticWidget}
            oeeChart={firstItem.widget.oeeChart}
            isStatic
            widgetID={firstItem.layout.lg.i}
          />
        )}
      </ErrorBoundary>
    </ContentBox>
  );
};

export default OEEDashboardCard;
