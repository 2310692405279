import React from "react";
import { KpiWidgetConfiguration } from "./kpiWidgetTypes";
import useTheme from "@mui/material/styles/useTheme";
import { Avatar, Theme, Tooltip } from "@mui/material";
import { css } from "@emotion/react";
import { TrendDirection } from "./KpiWidgetUtils";
import TrendingUpOutlinedIcon from "@mui/icons-material/TrendingUpOutlined";
import TrendingDownOutlinedIcon from "@mui/icons-material/TrendingDownOutlined";
import TrendingFlatOutlinedIcon from "@mui/icons-material/TrendingFlatOutlined";
import CrisisAlertIcon from "@mui/icons-material/CrisisAlert";

const ICON_SIZE = 38;

const cssStyles = (theme: Theme) => ({
  avatarStyle: css({
    width: theme.spacing(6),
    height: theme.spacing(6),
    backgroundColor:
      theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[900],
  }),
});

interface KpiWidgetAvatarIconProps {
  configuration: KpiWidgetConfiguration;
  trendAverage: string;
  trendLatest: string;
  threshold: number | null;
  latest: number;
  average: number;
}

const KpiWidgetAvatarIcon: React.FC<KpiWidgetAvatarIconProps> = ({
  configuration,
  trendAverage,
  trendLatest,
  threshold,
  latest,
  average,
}) => {
  const theme = useTheme();
  const styles = {
    ...cssStyles(theme),
  };

  const targetVal =
    configuration.positiveDirection === "target" &&
    configuration.targetValue !== undefined
      ? +configuration.targetValue
      : null;
  const calculatedTarget = targetVal
    ? calcTarget(configuration, targetVal, threshold || 0, latest, average)
    : null;

  const trendDirection =
    configuration.valueType === "Average" ? trendAverage : trendLatest;
  const upIsPositive = configuration.positiveDirection === TrendDirection.UP;

  const goodColor = theme.palette.success.main;
  const badColor = theme.palette.error.main;
  const neutralColor = theme.palette.primary.main;

  const icon =
    targetVal !== null ? (
      <CrisisAlertIcon
        sx={{
          color: calculatedTarget?.isWithinRange ? goodColor : badColor,
          fontSize: ICON_SIZE,
        }}
      />
    ) : trendDirection === TrendDirection.UP ? (
      <TrendingUpOutlinedIcon
        sx={{ color: upIsPositive ? goodColor : badColor, fontSize: ICON_SIZE }}
      />
    ) : trendDirection === TrendDirection.DOWN ? (
      <TrendingDownOutlinedIcon
        sx={{ color: upIsPositive ? badColor : goodColor, fontSize: ICON_SIZE }}
      />
    ) : (
      <TrendingFlatOutlinedIcon sx={{ color: neutralColor, fontSize: ICON_SIZE }} />
    );

  return (
    <Tooltip title={calculatedTarget?.allowableRange || ""}>
      <Avatar variant="rounded" css={styles.avatarStyle}>
        {icon}
      </Avatar>
    </Tooltip>
  );
};

export default KpiWidgetAvatarIcon;

const calcTarget = (
  configuration: KpiWidgetConfiguration,
  targetVal: number,
  threshold: number,
  latest: number,
  average: number
) => {
  const value = configuration.valueType === "Average" ? average : latest;

  // Calculate the absolute threshold from the percentage
  const absoluteThreshold = targetVal * (threshold / 100);
  const minVal = targetVal - absoluteThreshold;
  const maxVal = targetVal + absoluteThreshold;
  const isWithinRange = value >= minVal && value <= maxVal;

  // Format decimals
  const decimals = configuration?.fixedDecimalValue;
  const points = configuration?.decimalPoints;
  const properPoints = decimals && points ? Math.min(Math.round(+points), 20) : null;

  const formattedMinVal = properPoints !== null ? minVal.toFixed(properPoints) : minVal;
  const formattedMaxVal = properPoints !== null ? maxVal.toFixed(properPoints) : maxVal;

  return {
    isWithinRange,
    allowableRange: `Positive range: ${formattedMinVal} - ${formattedMaxVal}`,
  };
};
