import { Query } from "../callApi";

export const getQueryAssetsFunctionalLocations: Query = {
  endpoint: "assets/functional-locations",
  method: "GET",
};

export const getQueryOEEMainCategories: Query = {
  endpoint: "oee/maincategories",
  method: "GET",
};

export const getQueryOEEMappedCategoriesWithSubcategories: Query = {
  endpoint: "oee/categories",
  method: "GET",
};

export const getQueryOEEAddedCategoriesWithSubcategories: Query = {
  endpoint: "oee/assets/categories",
  method: "GET",
};

export const getQueryOEELosses: Query = {
  endpoint: "oee/losses",
  method: "GET",
};

export const getQueryMappedAssetToCategory = (assetId: string): Query => ({
  endpoint: `oee/asset/${assetId}/categories`,
  method: "GET",
});

export const getQueryAssetCycleRateData = (assetId: string): Query => ({
  endpoint: `oee/asset/${assetId}/cycle_rate`,
  method: "GET",
});

export const getOverallOEEChartData = (startTime: string, endTime: string): Query => ({
  endpoint: "oee/oee/overall",
  method: "GET",
  variables: {
    start_time: startTime,
    end_time: endTime,
  },
});

export const getOEEByAsset = (
  assetId: string,
  startTime: string,
  endTime: string
): Query => ({
  endpoint: `/oee/oee/period/asset/${assetId}`,
  method: "GET",
  variables: {
    start_date: startTime,
    end_date: endTime,
  },
});

export const getOEELossesByAsset = (
  start_time: string,
  end_time: string,
  type: string
): Query => ({
  endpoint: "oee/oee/losses/assets",
  method: "GET",
  variables: {
    start_time,
    end_time,
    category: type,
  },
});

export const getOEELossesByCategory = (
  type: string,
  start_time: string,
  end_time: string
): Query => ({
  endpoint: "oee/oee/losses/category",
  method: "GET",
  variables: {
    start_time,
    end_time,
    category: type,
  },
});

export const getOEELossesByPeriod = (
  type: string,
  start_time: string,
  end_time: string
): Query => ({
  endpoint: "oee/oee/losses/period",
  method: "GET",
  variables: {
    start_time,
    end_time,
    category: type,
  },
});

export const getOEECategoryLossByAsset = (
  assetId: string,
  start_time: string,
  end_time: string
): Query => ({
  endpoint: `oee/oee/losses/period/category/asset/${assetId}`,
  method: "GET",
  variables: {
    start_time,
    end_time,
  },
});

export const getAvailabilityLossByAsset = (
  assetId: string,
  start_time: string,
  end_time: string
): Query => ({
  endpoint: `oee/oee/losses/period/availability/asset/${assetId}`,
  method: "GET",
  variables: {
    start_time,
    end_time,
  },
});

export const getAssetStatusHourly = (assetId: string, date: string): Query => ({
  endpoint: `oee/oee/status/asset/${assetId}`,
  method: "GET",
  variables: { date },
});

export const getQueryOEEDashboards = (breakpoint: string): Query => ({
  endpoint: `oee/dashboards/${breakpoint}`,
  method: "GET",
});

export const getQueryOEEFullDashboard = (layoutID: string): Query => ({
  endpoint: `/oee/dashboard/${layoutID}`,
  method: "GET",
});
