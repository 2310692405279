import { SerializedStyles, css } from "@emotion/react";
import { Box, Typography, useTheme } from "@mui/material";
import {
  WIDGET_GRID_HEADING_MENU_HEIGHT,
  WIDGET_GRID_HEADING_MENU_MARGIN_BOTTOM,
} from "../../../LargeComponents/WidgetsGrid/widgetsGridUtils";
import cssLayoutStyles from "../../../../Global/Styles/layout";
import { ExtractChartConfig, OEEChartOptions } from "./oeeDashboardUtils";
import OEEBulletChart from "./OEEBulletChart/OEEBulletChart";
import OEERadialChart from "./OEERadialChart/OEERadialChart";
import OEEBarChartForAvailability from "./OEEBarChartForAvailability/OEEBarChartForAvailability";
import OEEBarChartAssetByHour from "./OEEBarChartAssetByHour/OEEBarChartAssetByHour";
import OEEBarChartPareto from "./OEEBarChartPareto/OEEBarChartPareto";
import OEELineChartForSingleAsset from "./OEELineChartForSingleAsset/OEELineChartForSingleAsset";
import OEEBarChartForAssetByType from "./OEEBarChartForAssetByType/OEEBarChartForAssetByType";
import OEEBarChartLossesByType from "./OEEBarChartLossesByType/OEEBarChartLossesByType";
import OEEBarChartLossesByPeriod from "./OEEBarChartLossesByPeriod/OEEBarChartLossesByPeriod";

const TITLE_HEIGHT = "24px";

const cssStyles = {
  chartStyle: css({
    width: "100%",
    height: `calc(100% - ${WIDGET_GRID_HEADING_MENU_HEIGHT} - ${WIDGET_GRID_HEADING_MENU_MARGIN_BOTTOM})`,
  }),
  itemTitle: css({
    height: TITLE_HEIGHT,
    paddingLeft: "20px",
    marginBottom: "10px",
  }),
};
interface OEEChartItemProps {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  chart: OEEChartOptions;
  title: string;
  widgetId: string;
  handleUpdateChartConfig: <K extends keyof OEEChartOptions>(
    widgetId: string,
    chartKey: K,
    updatedConfig: Partial<ExtractChartConfig<NonNullable<OEEChartOptions[K]>>>
  ) => void;
}

const OEEChartItem: React.FC<OEEChartItemProps> = ({
  className,
  chart,
  title,
  widgetId,
  handleUpdateChartConfig,
}) => {
  const theme = useTheme();
  const styles = { ...cssStyles, ...cssLayoutStyles };

  // OEE widgets
  if (chart.bulletChart) {
    return (
      <Box component="div" css={[styles.width100, styles.height100]}>
        <Typography
          css={styles.itemTitle}
          variant="h3"
          sx={{ color: theme.palette.customColors.greyText }}
        >
          {title}
        </Typography>
        <OEEBulletChart
          css={styles.chartStyle}
          className={className}
          data={chart.bulletChart.data}
          config={chart.bulletChart.config}
        />
      </Box>
    );
  }

  if (chart.radialChart) {
    return (
      <Box component="div" css={[styles.width100, styles.height100]}>
        <Typography
          css={styles.itemTitle}
          variant="h3"
          sx={{ color: theme.palette.customColors.greyText }}
        >
          {title}
        </Typography>
        <OEERadialChart
          css={styles.chartStyle}
          className={className}
          config={chart.radialChart.config}
          onUpdateConfig={(updatedConfig) =>
            handleUpdateChartConfig(widgetId, "radialChart", updatedConfig)
          }
        />
      </Box>
    );
  }
  if (chart.availabilityBarChart) {
    return (
      <Box component="div" css={[styles.width100, styles.height100]}>
        <Typography
          css={styles.itemTitle}
          variant="h3"
          sx={{ color: theme.palette.customColors.greyText }}
        >
          {title}
        </Typography>
        <OEEBarChartForAvailability
          css={styles.chartStyle}
          className={className}
          config={chart.availabilityBarChart.config}
          onUpdateConfig={(updatedConfig) =>
            handleUpdateChartConfig(widgetId, "availabilityBarChart", updatedConfig)
          }
        />
      </Box>
    );
  }
  if (chart.assetByHourBarChart) {
    return (
      <Box component="div" css={[styles.width100, styles.height100]}>
        <Typography
          css={styles.itemTitle}
          variant="h3"
          sx={{ color: theme.palette.customColors.greyText }}
        >
          {title}
        </Typography>
        <OEEBarChartAssetByHour
          css={styles.chartStyle}
          className={className}
          config={chart.assetByHourBarChart.config}
          onUpdateConfig={(updatedConfig) =>
            handleUpdateChartConfig(widgetId, "assetByHourBarChart", updatedConfig)
          }
        />
      </Box>
    );
  }
  if (chart.paretoBarChart) {
    return (
      <Box component="div" css={[styles.width100, styles.height100]}>
        <Typography
          css={styles.itemTitle}
          variant="h3"
          sx={{ color: theme.palette.customColors.greyText }}
        >
          {title}
        </Typography>
        <OEEBarChartPareto
          css={styles.chartStyle}
          className={className}
          config={chart.paretoBarChart.config}
          onUpdateConfig={(updatedConfig) =>
            handleUpdateChartConfig(widgetId, "paretoBarChart", updatedConfig)
          }
        />
      </Box>
    );
  }
  if (chart.lineChartForAsset) {
    return (
      <Box component="div" css={[styles.width100, styles.height100]}>
        <Typography
          css={styles.itemTitle}
          variant="h3"
          sx={{ color: theme.palette.customColors.greyText }}
        >
          {title}
        </Typography>
        <OEELineChartForSingleAsset
          css={styles.chartStyle}
          className={className}
          config={chart.lineChartForAsset.config}
          onUpdateConfig={(updatedConfig) =>
            handleUpdateChartConfig(widgetId, "lineChartForAsset", updatedConfig)
          }
        />
      </Box>
    );
  }
  if (chart.barChartForAssetByType) {
    return (
      <Box component="div" css={[styles.width100, styles.height100]}>
        <Typography
          css={styles.itemTitle}
          variant="h3"
          sx={{ color: theme.palette.customColors.greyText }}
        >
          {title}
        </Typography>
        <OEEBarChartForAssetByType
          css={styles.chartStyle}
          className={className}
          config={chart.barChartForAssetByType.config}
          onUpdateConfig={(updatedConfig) =>
            handleUpdateChartConfig(widgetId, "barChartForAssetByType", updatedConfig)
          }
        />
      </Box>
    );
  }
  if (chart.barChartLossesByType) {
    return (
      <Box component="div" css={[styles.width100, styles.height100]}>
        <Typography
          css={styles.itemTitle}
          variant="h3"
          sx={{ color: theme.palette.customColors.greyText }}
        >
          {title}
        </Typography>
        <OEEBarChartLossesByType
          css={styles.chartStyle}
          className={className}
          config={chart.barChartLossesByType.config}
          onUpdateConfig={(updatedConfig) =>
            handleUpdateChartConfig(widgetId, "barChartLossesByType", updatedConfig)
          }
        />
      </Box>
    );
  }
  if (chart.barChartLossesByPeriod) {
    return (
      <Box component="div" css={[styles.width100, styles.height100]}>
        <Typography
          css={styles.itemTitle}
          variant="h3"
          sx={{ color: theme.palette.customColors.greyText }}
        >
          {title}
        </Typography>
        <OEEBarChartLossesByPeriod
          css={styles.chartStyle}
          className={className}
          config={chart.barChartLossesByPeriod.config}
          onUpdateConfig={(updatedConfig) =>
            handleUpdateChartConfig(widgetId, "barChartLossesByPeriod", updatedConfig)
          }
        />
      </Box>
    );
  }
  return null;
};

export default OEEChartItem;
