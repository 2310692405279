import { Box, Stack, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { GetQueryOEEBulletChartSnippet } from "../../../../../Api/OEE/apiOEESnippets";
import callApi from "../../../../../Api/callApi";
import { getQueryAssetCycleRateData } from "../../../../../Api/OEE/apiOEEGetQueries";
import { BulletChartConfigType } from "../../oEEUtils";
import { useAuthedContext } from "../../../../../context/AuthContext";
import { ResponsiveBullet } from "@nivo/bullet";
import { FormStatuses } from "../../../../../Global/Types/commonTypes";
import { useLanguageContext } from "../../../../../context/LanguageContext";
import { OEEBulletChartType } from "../../../../../Api/OEE/apiOEEDataTypes";
import { SerializedStyles } from "@emotion/react";
import Alert from "../../../../MaterialUI/Alert";

interface OEEBulletChartProps {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  data: OEEBulletChartType;
  config: BulletChartConfigType;
  isStatic?: boolean;
}

const OEEBulletChart: React.FC<OEEBulletChartProps> = ({ className, data, config, isStatic }) => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const { setAuthedUser } = useAuthedContext();
  const [bulletChartData, setBulletChartData] = useState<OEEBulletChartType | null>(data);
  const measureAndMarkerColors =
    theme.palette.mode === "light" ? theme.palette.grey[800] : theme.palette.grey[200];
  const [formStatus, setFormStatus] = useState<FormStatuses>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);

  const bulletChartColors = [
    theme.palette.error.light,
    theme.palette.warning.light,
    theme.palette.success.light,
    theme.palette.success.dark,
  ];

  useEffect(() => {
    fetchChartData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config]);

  const fetchChartData = async () => {
    try {
      const chartData = await callApi<GetQueryOEEBulletChartSnippet>({
        query: getQueryAssetCycleRateData(config.assetId),
        auth: { setAuthedUser },
      });

      setBulletChartData({
        ...chartData,
        title: (
          <text>
            <tspan
              style={{
                fill: theme.palette.text.primary,
                fontWeight: 500,
                fontSize: "12px",
              }}
            >
              {chartData.id}
            </tspan>
            <tspan
              x={0}
              dy={18}
              style={{
                fill: theme.palette.text.secondary,
                fontSize: "12px",
              }}
            >
              {t("Parts/min")}
            </tspan>
          </text>
        ),
      });
    } catch (error) {
      console.error("Error fetching data: ", error);
      setFormStatus("error");
      setAlertMessage("Something went wrong");
    }
  };

  return (
    <Box component="div" className={className} ml={2}>
      <Box component="div" mt={1}>
        <Alert
          message={alertMessage || ""}
          showAlert={!!alertMessage}
          severity={formStatus}
        />
      </Box>
      <Stack direction="row" style={{ width: "100%", height: "100%" }} spacing={0}>
        <Box
          component="div"
          style={{
            flexGrow: 1,
          }}
        >
          {bulletChartData ? (
            <Box component="div" height="80px">
              <ResponsiveBullet
                isInteractive={!isStatic}
                data={[bulletChartData]}
                margin={{ top: 30, right: 20, bottom: 20, left: 190 }}
                titleOffsetX={-185}
                measureSize={0.2}
                markerSize={1}
                rangeColors={bulletChartColors}
                measureColors={measureAndMarkerColors}
                markerColors={measureAndMarkerColors}
                theme={{
                  text: {
                    fill: theme.palette.common.black,
                  },
                  tooltip: {
                    container: {
                      background: theme.palette.common.white,
                    },
                  },
                }}
              />
            </Box>
          ) : (
            !alertMessage && (
              <Typography variant="body2" mt={2} mb={1}>
                {t("Loading...")}
              </Typography>
            )
          )}
        </Box>
      </Stack>
    </Box>
  );
};
export default OEEBulletChart;
