import ExcellenceChartFormWrapper from "../EditExcellenceChartForms/ExcellenceChartFormWrapper";
import {
  ExcellenceChartFormProps,
  ExcellenceParamMapping,
} from "../EditExcellenceChartForms/excellenceChartFormUtils";
import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { DynamicGridChartOptions } from "../../SmallComponents/DynamicGridLayout.tsx/dynamicGridUtils";
import EditIndicatorWidgetConfig from "./EditIndicatorWidgetConfig";
import {
  IndicatorDataSchema,
  IndicatorWidgetConfiguration,
} from "./indicatorWidgetTypes";
import { AutocompleteOption } from "../../../Global/Types/commonTypes";
import { getUnitsOfMeasure } from "../../../Api/Excellence/apiExcellenceGetQueries";
import { GetUnitsOfMeasureSnippet } from "../../../Api/Excellence/apiExcellenceSnippets";
import callApi from "../../../Api/callApi";
import { useAuthedContext } from "../../../context/AuthContext";
import ExcellenceIndicator from "../../PageComponents/Excellence/ExcellenceCharts/ExcellenceIndicator/ExcellenceIndicator";
import EditIndicatorWidgetDataSchema from "./EditIndicatorWidgetDataSchema";
import { useLanguageContext } from "../../../context/LanguageContext";
import useExcellenceChartUnsavedChanges from "../../../Global/Hooks/useExcellenceChartUnsavedChanges";

interface EditIndicatorWidgetFormProps
  extends Omit<ExcellenceChartFormProps, "dataSchema"> {
  config: IndicatorWidgetConfiguration;
  isColumnChart?: boolean;
  dataSchema: IndicatorDataSchema | null;
}

const EditIndicatorWidgetForm: React.FC<EditIndicatorWidgetFormProps> = ({
  config,
  dataSchema,
  handleSaveChanges,
  parameters,
  handleSetUnsavedChanges,
}) => {
  const { t } = useLanguageContext();
  const [tabValue, setTabValue] = useState(0);

  const { updatedConfig, setUpdatedConfig, updatedSchema } =
    useExcellenceChartUnsavedChanges<
      IndicatorWidgetConfiguration,
      IndicatorDataSchema | null
    >({
      initialConfig: {
        ...config,
      },
      initialSchema: dataSchema as IndicatorDataSchema,
      handleSetUnsavedChanges,
    });

  // tab number 0
  const [fieldAlert, setFieldAlert] = useState<string | null>(null);

  // tab number 1
  const [unitsOfMeasure, setUnitsOfMeasure] = useState<GetUnitsOfMeasureSnippet | null>(
    null
  );
  // data tab
  const [selectedConnection, setSelectedConnection] = useState<AutocompleteOption>({
    value: '',
    description: ''
  });
  const [paramMapping, setParamMapping] = useState<ExcellenceParamMapping | null>(null);

  const { setAuthedUser } = useAuthedContext();

  useEffect(() => {
    const mapping: ExcellenceParamMapping = {};
    parameters?.parameters.forEach((param) => {
      if (param.id && !mapping?.[param.id]) {
        mapping[param.id] = {
          connection: param.container,
          paramID: param.id,
          paramName: param.name,
          connectionName: param.containerName,
          type: param.type,
        };
      }
    });
    setParamMapping(mapping);
  }, [parameters]);

  useEffect(() => {
    (async () => {
      try {
        const units = await callApi<GetUnitsOfMeasureSnippet>({
          query: getUnitsOfMeasure,
          auth: { setAuthedUser },
        });
        setUnitsOfMeasure(units);
      } catch (err) {
        console.error(err);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = () => {
    const { title, ...otherConfig } = updatedConfig;
    const updatedChart: DynamicGridChartOptions = {
      indicatorWidget: {
        config: { ...{ ...otherConfig }, title },
        dataSchema: updatedSchema,
      },
    };

    // do some field checks
    if (!title) {
      setFieldAlert(t("Title is required"));
    } else {
      // all fields checks have passed
      handleSaveChanges(updatedChart, title);
    }
  };

  return (
    <ExcellenceChartFormWrapper
      alertMessage={fieldAlert}
      handleSubmit={handleSubmit}
      tabValue={tabValue}
      setTabValue={setTabValue}
      disabledDataTab={false}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          {tabValue === 0 ? (
            <EditIndicatorWidgetConfig
              config={updatedConfig}
              setUpdatedConfig={setUpdatedConfig}
              unitsOfMeasure={unitsOfMeasure}
            />
          ) : null}

          {tabValue === 1 ? (
            <EditIndicatorWidgetDataSchema
              parameters={parameters?.parameters || []}
              selectedConnection={selectedConnection}
              setSelectedConnection={setSelectedConnection}
              paramMapping={paramMapping}
              config={updatedConfig}
              setUpdatedConfig={setUpdatedConfig}
            />
          ) : null}
        </Grid>

        <Grid item xs={12} sm={6}>
          <ExcellenceIndicator
            configuration={updatedConfig}
          />
        </Grid>
      </Grid>

    </ExcellenceChartFormWrapper>
  );
};

export default EditIndicatorWidgetForm;
