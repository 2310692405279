import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import FeedOutlinedIcon from "@mui/icons-material/FeedOutlined";
import TaskOutlinedIcon from "@mui/icons-material/TaskOutlined";
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";
import WarehouseOutlinedIcon from "@mui/icons-material/WarehouseOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import IntegrationInstructionsOutlinedIcon from "@mui/icons-material/IntegrationInstructionsOutlined";
import FolderCopyOutlinedIcon from "@mui/icons-material/FolderCopyOutlined";
import FolderSharedOutlinedIcon from "@mui/icons-material/FolderSharedOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import EditCalendarOutlinedIcon from "@mui/icons-material/EditCalendarOutlined";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import ListOutlinedIcon from "@mui/icons-material/ListOutlined";
import { LeftNavList } from "../layoutVariables";
import BallotOutlinedIcon from "@mui/icons-material/BallotOutlined";
import CastConnectedIcon from "@mui/icons-material/CastConnected";
import ClassIcon from "@mui/icons-material/Class";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import BuildOutlinedIcon from "@mui/icons-material/BuildOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import RuleIcon from "@mui/icons-material/Rule";
import LowPriorityIcon from "@mui/icons-material/LowPriority";
import DeveloperBoardIcon from "@mui/icons-material/DeveloperBoard";
import RunningWithErrorsIcon from "@mui/icons-material/RunningWithErrors";
import ResetTvIcon from "@mui/icons-material/ResetTv";
import QueuePlayNextIcon from "@mui/icons-material/QueuePlayNext";
import InstallDesktopIcon from "@mui/icons-material/InstallDesktop";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import HiveIcon from "@mui/icons-material/Hive";
import PinDropIcon from "@mui/icons-material/PinDrop";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import LocalAtmOutlinedIcon from "@mui/icons-material/LocalAtmOutlined";
import ElectricBoltOutlinedIcon from "@mui/icons-material/ElectricBoltOutlined";
import DashboardCustomizeOutlinedIcon from "@mui/icons-material/DashboardCustomizeOutlined";
import TrendingDownOutlinedIcon from "@mui/icons-material/TrendingDownOutlined";
import PieChartOutlineOutlinedIcon from "@mui/icons-material/PieChartOutlineOutlined";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import ViewKanbanIcon from "@mui/icons-material/ViewKanban";
import ListAltIcon from "@mui/icons-material/ListAlt";
import StreamIcon from "@mui/icons-material/Stream";
import DesignServicesIcon from "@mui/icons-material/DesignServices";
import ChecklistOutlinedIcon from "@mui/icons-material/ChecklistOutlined";
import QueueOutlinedIcon from "@mui/icons-material/QueueOutlined";
import DisplaySettingsOutlinedIcon from "@mui/icons-material/DisplaySettingsOutlined";
import SchemaIcon from "@mui/icons-material/Schema";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import StackedLineChartIcon from "@mui/icons-material/StackedLineChart";
import SyncOutlinedIcon from "@mui/icons-material/SyncOutlined";
import HubIcon from "@mui/icons-material/Hub";
import FolderCopyIcon from "@mui/icons-material/FolderCopy";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import ReceiptIcon from "@mui/icons-material/Receipt";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import StoreIcon from "@mui/icons-material/Store";
import MarkunreadMailboxIcon from "@mui/icons-material/MarkunreadMailbox";
import PowerInputIcon from "@mui/icons-material/PowerInput";
import ViewListIcon from "@mui/icons-material/ViewList";
import PaymentsIcon from "@mui/icons-material/Payments";
import StarRateIcon from "@mui/icons-material/StarRate";
import HvacIcon from "@mui/icons-material/Hvac";
import Groups2Icon from "@mui/icons-material/Groups2";
import ReduceCapacityIcon from "@mui/icons-material/ReduceCapacity";
import ContactsIcon from "@mui/icons-material/Contacts";
import BadgeIcon from "@mui/icons-material/Badge";
import RestoreIcon from "@mui/icons-material/Restore";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import NextPlanOutlinedIcon from "@mui/icons-material/NextPlanOutlined";
import PsychologyAltIcon from "@mui/icons-material/PsychologyAlt";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import DynamicFormIcon from "@mui/icons-material/DynamicForm";
import DnsIcon from "@mui/icons-material/Dns";
import BookmarksIcon from "@mui/icons-material/Bookmarks";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import AssessmentIcon from "@mui/icons-material/Assessment";
import SegmentIcon from "@mui/icons-material/Segment";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import SettingsIcon from "@mui/icons-material/Settings";
import AccountBoxOutlinedIcon from "@mui/icons-material/AccountBoxOutlined";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import GasMeterIcon from "@mui/icons-material/GasMeter";
import WindPowerIcon from "@mui/icons-material/WindPower";
import HeatPumpIcon from "@mui/icons-material/HeatPump";
import FoundationIcon from "@mui/icons-material/Foundation";
import AppsIcon from "@mui/icons-material/Apps";
import SolarPowerIcon from "@mui/icons-material/SolarPower";
import EnergySavingsLeafIcon from "@mui/icons-material/EnergySavingsLeaf";
import TroubleshootIcon from "@mui/icons-material/Troubleshoot";
import AddTaskIcon from "@mui/icons-material/AddTask";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import CompareIcon from "@mui/icons-material/Compare";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import WbIridescentIcon from "@mui/icons-material/WbIridescent";
import SettingsRemoteIcon from "@mui/icons-material/SettingsRemote";
import RoomPreferencesIcon from "@mui/icons-material/RoomPreferences";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import AssignmentIcon from "@mui/icons-material/Assignment";
import SplitscreenIcon from "@mui/icons-material/Splitscreen";
import FindReplaceIcon from "@mui/icons-material/FindReplace";
import CenterFocusStrongIcon from "@mui/icons-material/CenterFocusStrong";
import CenterFocusWeakIcon from "@mui/icons-material/CenterFocusWeak";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import ViewTimelineIcon from "@mui/icons-material/ViewTimeline";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import ConstructionIcon from "@mui/icons-material/Construction";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import InputIcon from "@mui/icons-material/Input";
import ControlCameraIcon from "@mui/icons-material/ControlCamera";
import NumbersIcon from "@mui/icons-material/Numbers";
import ThreeSixtyIcon from "@mui/icons-material/ThreeSixty";
import HolidayVillageIcon from "@mui/icons-material/HolidayVillage";
import VillaIcon from "@mui/icons-material/Villa";
import PictureInPictureAltIcon from "@mui/icons-material/PictureInPictureAlt";
import EngineeringIcon from "@mui/icons-material/Engineering";
import ArchitectureIcon from "@mui/icons-material/Architecture";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import SettingsInputSvideoIcon from "@mui/icons-material/SettingsInputSvideo";
import SecurityIcon from "@mui/icons-material/Security";
import SensorWindowIcon from "@mui/icons-material/SensorWindow";
import ChromeReaderModeOutlinedIcon from "@mui/icons-material/ChromeReaderModeOutlined";
import DeveloperBoardOutlinedIcon from "@mui/icons-material/DeveloperBoardOutlined";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";
import LoopIcon from "@mui/icons-material/Loop";
import StraightenOutlinedIcon from "@mui/icons-material/StraightenOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import InventoryOutlinedIcon from "@mui/icons-material/InventoryOutlined";
import ChecklistRtlIcon from '@mui/icons-material/ChecklistRtl';
import ListIcon from '@mui/icons-material/List';
import MonitorIcon from '@mui/icons-material/Monitor';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import RouteIcon from '@mui/icons-material/Route';
import DepartureBoardIcon from '@mui/icons-material/DepartureBoard';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import DescriptionIcon from '@mui/icons-material/Description';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CarCrashIcon from '@mui/icons-material/CarCrash';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import CommuteIcon from '@mui/icons-material/Commute';
import GroupsIcon from '@mui/icons-material/Groups';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import CalculateIcon from '@mui/icons-material/Calculate';
import DifferenceIcon from '@mui/icons-material/Difference';
import VerifiedIcon from '@mui/icons-material/Verified';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import TaskIcon from '@mui/icons-material/Task';

enum LEFT_NAV_SECTION {
  "Home" = "Home",
  "GIANT Analytics" = "GIANT Analytics",
  "GIANT Planning" = "GIANT Planning",
  "GIANT Operations Suite" = "GIANT Operations Suite",
  "GIANT Toolbox Manager" = "GIANT Toolbox Manager",
}

type LeftNavMenu = {
  title: keyof typeof LEFT_NAV_SECTION;
  list: LeftNavList;
};

export const NAV_GIANT_HOME: LeftNavMenu = {
  title: LEFT_NAV_SECTION["Home"],
  list: [
    {
      text: "Home",
      url: "/",
      Icon: HomeOutlinedIcon,
      disabled: false,
    },
  ],
};

export const NAV_GIANT_ANALYTICS: LeftNavMenu = {
  title: LEFT_NAV_SECTION["GIANT Analytics"],
  list: [
    {
      text: "Standard KPIs",
      url: null,
      Icon: BallotOutlinedIcon,
      disabled: false,
      nested: [
        {
          text: "Production KPIs",
          url: "/GIANT-Analytics/Production-KPIs",
          Icon: PrecisionManufacturingIcon,
          disabled: false,
        },
        {
          text: "Financial KPIs",
          url: "/GIANT-Analytics/Financial-KPIs",
          Icon: LocalAtmOutlinedIcon,
          disabled: false,
        },
        {
          text: "Sales KPIs",
          url: "/GIANT-Analytics/Sales-KPIs",
          Icon: MonetizationOnOutlinedIcon,
          disabled: false,
        },
        {
          text: "Inventory KPIs",
          url: "/GIANT-Analytics/Inventory-KPIs",
          Icon: InventoryOutlinedIcon,
          disabled: false,
        },
        {
          text: "Quality KPIs",
          url: "/GIANT-Analytics/Quality-KPIs",
          Icon: VerifiedOutlinedIcon,
          disabled: false,
        },
        {
          text: "Energy KPIs",
          url: "/GIANT-Analytics/Energy-KPIs",
          Icon: ElectricBoltOutlinedIcon,
          disabled: false,
        },
        {
          text: "Maintenance KPIs",
          url: "/GIANT-Analytics/Maintenance-KPIs",
          Icon: AssessmentOutlinedIcon,
          disabled: false,
        },
        {
          text: "Performance KPIs",
          url: "/GIANT-Analytics/Performance-KPIs",
          Icon: TrendingUpIcon,
          disabled: false,
        },
      ],
    },
    {
      text: "Excellence",
      url: "/GIANT-Analytics/Excellence/",
      Icon: DashboardOutlinedIcon,
      disabled: false,
    },
    {
      text: "Advanced Analytics",
      url: "/GIANT-Analytics/Advanced-Analytics",
      Icon: StackedLineChartIcon,
      disabled: false,
    },
    {
      text: "Vision Control",
      url: "/GIANT-Analytics/Vision-Control",
      Icon: CastConnectedIcon,
      disabled: false,
    },
    {
      text: "DoE",
      url: "/GIANT-Analytics/DoE",
      Icon: SyncOutlinedIcon,
      disabled: false,
    },
    {
      text: "Anomaly Detection",
      url: "/GIANT-Analytics/Anomaly-Detection",
      Icon: InfoOutlinedIcon,
      disabled: false,
    },
    {
      text: "Text Recognition (OCR)",
      url: "/GIANT-Analytics/Text-Recognition",
      Icon: ClassIcon,
      disabled: false,
    },
    {
      text: "Facilitator (SPC)",
      url: "/GIANT-Analytics/Facilitator",
      Icon: DeveloperBoardIcon,
      disabled: false,
    },
  ],
};

export const NAV_GIANT_PLANNING: LeftNavMenu = {
  title: LEFT_NAV_SECTION["GIANT Planning"],
  list: [
    {
      text: "Human Resources",
      url: null,
      Icon: Groups2Icon,
      disabled: false,
      nested: [
        {
          text: "Personnel Management",
          url: "/GIANT-Planning/Personnel-Management",
          Icon: ReduceCapacityIcon,
          disabled: false,
        },
        {
          text: "Shift Planning",
          url: "/GIANT-Planning/Shift-Planning",
          Icon: ContactsIcon,
          disabled: false,
        },
        {
          text: "Work Plans",
          url: "/GIANT-Planning/Work-Plans",
          Icon: NextPlanOutlinedIcon,
          disabled: false,
        },
        {
          text: "Time Tracking",
          url: "/GIANT-Planning/Time-Tracking",
          Icon: RestoreIcon,
          disabled: false,
        },
        {
          text: "Employee Qualifications",
          url: "/GIANT-Planning/Employee-Qualifications",
          Icon: BadgeIcon,
          disabled: false,
        },
        {
          text: "Payroll",
          url: "/GIANT-Planning/Payroll",
          Icon: CreditCardIcon,
          disabled: false,
        },
      ],
    },
    {
      text: "Project Hub",
      url: null,
      Icon: HubIcon,
      disabled: false,
      nested: [
        {
          text: "Project Planning",
          url: "/GIANT-Planning/Project-Planning",
          Icon: ChecklistRtlIcon,
          disabled: false,
        },
        {
          text: "Project Management",
          url: "/GIANT-Planning/Project-Management",
          Icon: ListIcon,
          disabled: false,
        },
        {
          text: "Project Monitoring",
          url: "/GIANT-Planning/Project-Monitoring",
          Icon: MonitorIcon,
          disabled: false,
        },
      ],
    },
    {
      text: "Sales and Orders",
      url: null,
      Icon: LowPriorityIcon,
      disabled: false,
      nested: [
        {
          text: "Products",
          url: "/GIANT-Planning/Products",
          Icon: ProductionQuantityLimitsIcon,
          disabled: false,
        },
        {
          text: "Pricing",
          url: "/GIANT-Planning/Pricing",
          Icon: PriceCheckIcon,
          disabled: false,
        },
        {
          text: "Customer Management",
          url: "/GIANT-Planning/Customer-Management",
          Icon: ListOutlinedIcon,
          disabled: false,
        },
        {
          text: "Quotes and Orders",
          url: "/GIANT-Planning/Quotes-And-Orders",
          Icon: ChecklistOutlinedIcon,
          disabled: false,
        },
        {
          text: "Shipping Management",
          url: "/GIANT-Planning/Shipping-Management",
          Icon: QueueOutlinedIcon,
          disabled: false,
        },
        {
          text: "Sales Reports",
          url: "/GIANT-Planning/Sales-Reports",
          Icon: ReceiptIcon,
          disabled: false,
        },
      ],
    },
    {
      text: "Warehouse",
      url: null,
      Icon: WarehouseOutlinedIcon,
      disabled: false,
      nested: [
        {
          text: "Stock Control",
          url: "/GIANT-Planning/Stock-Control",
          Icon: ControlCameraIcon,
          disabled: false,
        },
        {
          text: "Locations",
          url: "/GIANT-Planning/Locations",
          Icon: PinDropIcon,
          disabled: false,
        },
        {
          text: "Inventory Counting",
          url: "/GIANT-Planning/Inventory-Counting",
          Icon: NumbersIcon,
          disabled: false,
        },
        {
          text: "Real-Time Tracking",
          url: "/GIANT-Planning/Real-Time-Tracking",
          Icon: ThreeSixtyIcon,
          disabled: false,
        },
        {
          text: "Object Tracking",
          url: "/GIANT-Planning/Object-Tracking",
          Icon: DocumentScannerIcon,
          disabled: false,
        },
      ],
    },
    {
      text: "Resource Planning",
      url: null,
      Icon: ReceiptLongIcon,
      disabled: false,
      nested: [
        {
          text: "Long-Term Planning",
          url: "/GIANT-Planning/Long-Term-Planning",
          Icon: DnsIcon,
          disabled: false,
        },
        {
          text: "Detailed Planning",
          url: "/GIANT-Planning/Detailed-Planning",
          Icon: DynamicFormIcon,
          disabled: false,
        },
        {
          text: "What-if-Simulation",
          url: "/GIANT-Planning/What-if-Simulation",
          Icon: PsychologyAltIcon,
          disabled: false,
        },
        {
          text: "Capacities",
          url: "/GIANT-Planning/Capacities",
          Icon: EqualizerIcon,
          disabled: false,
        },
        {
          text: "Bookings",
          url: "/GIANT-Planning/Bookings",
          Icon: BookmarksIcon,
          disabled: false,
        },
      ],
    },
    {
      text: "Lifecycle",
      url: null,
      Icon: FindReplaceIcon,
      disabled: false,
      nested: [
        {
          text: "eBOM",
          url: "/GIANT-Planning/eBOM",
          Icon: CenterFocusStrongIcon,
          disabled: false,
        },
        {
          text: "mBOM",
          url: "/GIANT-Planning/mBOM",
          Icon: CenterFocusWeakIcon,
          disabled: false,
        },
        {
          text: "Product Tracking",
          url: null,
          Icon: GpsFixedIcon,
          disabled: false,
          nested: [
            {
              text: "Production History",
              url: "/GIANT-Planning/Production-History",
              Icon: ViewTimelineIcon,
              disabled: false,
            },
            {
              text: "Quality History",
              url: "/GIANT-Planning/Quality-History",
              Icon: TimelapseIcon,
              disabled: false,
            },
            {
              text: "Movements",
              url: "/GIANT-Planning/Movements",
              Icon: LocalShippingIcon,
              disabled: false,
            },
          ],
        },
      ],
    },
    {
      text: "Transportation",
      url: null,
      Icon: DriveEtaIcon,
      disabled: false,
      nested: [
        {
          text: "Fleet Management",
          url: "/GIANT-Planning/Fleet-Management",
          Icon: CommuteIcon,
          disabled: false,
        },
        {
          text: "Route Planning",
          url: "/GIANT-Planning/Route-Planning",
          Icon: RouteIcon,
          disabled: false,
        },
        {
          text: "Maintenance Scheduling",
          url: "/GIANT-Planning/Maintenance-Scheduling",
          Icon: DepartureBoardIcon,
          disabled: false,
        },
        {
          text: "Fuel and Expense Tracking",
          url: "/GIANT-Planning/Fuel-and-Expense-Tracking",
          Icon: LocalGasStationIcon,
          disabled: false,
        },
        {
          text: "Driver Management",
          url: "/GIANT-Planning/Driver-Management",
          Icon: GroupsIcon,
          disabled: false,
        },
        {
          text: "Compliance and Documentation",
          url: "/GIANT-Planning/Compliance-and-Documentation",
          Icon: DescriptionIcon,
          disabled: false,
        },
        {
          text: "GPS and Tracking Integration",
          url: "/GIANT-Planning/GPS-and-Tracking Integration",
          Icon: LocationOnIcon,
          disabled: false,
        },
        {
          text: "Incident and Issue Management",
          url: "/GIANT-Planning/Incident-and-Issue-Management",
          Icon: CarCrashIcon,
          disabled: false,
        }
      ]
    },
    {
      text: "Finance",
      url: null,
      Icon: AccountBalanceIcon,
      disabled: false,
      nested: [
        {
          text: "Accounting",
          url: "/GIANT-Planning/Accounting",
          Icon: AccountBalanceWalletIcon,
          disabled: false,
        },
        {
          text: "Cost Controlling",
          url: "/GIANT-Planning/Cost-Controlling",
          Icon: AttachMoneyIcon,
          disabled: false,
        },
        {
          text: "Financial Reports",
          url: "/GIANT-Planning/Financial-Reports",
          Icon: AssessmentIcon,
          disabled: false,
        },
        {
          text: "Budgeting and Forecasting",
          url: "/GIANT-Planning/Budgeting-And-Forecasting",
          Icon: SegmentIcon,
          disabled: false,
        },
        {
          text: "Payment and Receivables",
          url: "/GIANT-Planning/Payment-And-Receivables",
          Icon: CreditScoreIcon,
          disabled: false,
        },
        {
          text: "Invoices",
          url: "/GIANT-Planning/Invoices",
          Icon: ReceiptIcon,
          disabled: false,
        },
        {
          text: "Currencies",
          url: "/GIANT-Planning/Currencies",
          Icon: MonetizationOnIcon,
          disabled: false,
        },
      ],
    },
    {
      text: "Materials and Procurement",
      url: null,
      Icon: DisplaySettingsOutlinedIcon,
      disabled: false,
      nested: [
        {
          text: "Purchase Orders",
          url: "/GIANT-Planning/Purchase-Orders",
          Icon: StoreIcon,
          disabled: false,
        },
        {
          text: "Purchase Requisitions",
          url: "/GIANT-Planning/Purchase-Requisitions",
          Icon: ShoppingBasketIcon,
          disabled: false,
        },
        {
          text: "Goods Receipt",
          url: "/GIANT-Planning/Goods-Receipt",
          Icon: MarkunreadMailboxIcon,
          disabled: false,
        },
        {
          text: "Materials Management",
          url: "/GIANT-Planning/Materials-Management",
          Icon: HiveIcon,
          disabled: false,
        },
        {
          text: "Supplier Management",
          url: "/GIANT-Planning/Supplier-Management",
          Icon: PowerInputIcon,
          disabled: false,
        },
        {
          text: "Purchasing Details",
          url: null,
          Icon: ViewListIcon,
          disabled: false,
          nested: [
            {
              text: "Prices",
              url: "/GIANT-Planning/Purchasing-Details/Prices",
              Icon: PaymentsIcon,
              disabled: false,
            },
            {
              text: "Special",
              url: "/GIANT-Planning/Purchasing-Details/Special",
              Icon: StarRateIcon,
              disabled: false,
            },
            {
              text: "Conditions",
              url: "/GIANT-Planning/Purchasing-Details/Conditions",
              Icon: HvacIcon,
              disabled: false,
            },
          ],
        },
      ],
    },
  ],
};

export const NAV_GIANT_OPERATIONS: LeftNavMenu = {
  title: LEFT_NAV_SECTION["GIANT Operations Suite"],
  list: [
    {
      text: "OEE",
      url: null,
      Icon: RunningWithErrorsIcon,
      disabled: false,
      nested: [
        {
          text: "Dashboards",
          url: "/GIANT-Operations-Suite/OEE/Dashboard",
          Icon: DashboardCustomizeOutlinedIcon,
          disabled: false,
        },
        {
          text: "Losses",
          url: "/GIANT-Operations-Suite/OEE/Losses",
          Icon: TrendingDownOutlinedIcon,
          disabled: false,
        },
        {
          text: "Assets",
          url: "/GIANT-Operations-Suite/OEE/Assets",
          Icon: PieChartOutlineOutlinedIcon,
          disabled: false,
        },
        {
          text: "Categories",
          url: "/GIANT-Operations-Suite/OEE/Categories",
          Icon: CategoryOutlinedIcon,
          disabled: false,
        },
      ],
    },
    {
      text: "Energy Management",
      url: null,
      Icon: FlashOnIcon,
      disabled: false,
      nested: [
        {
          text: "Energy Monitoring",
          url: "/GIANT-Operations-Suite/Energy-Monitoring",
          Icon: EnergySavingsLeafIcon,
          disabled: false,
        },
        {
          text: "Energy Analytics",
          url: "/GIANT-Operations-Suite/Energy-Analytics",
          Icon: SolarPowerIcon,
          disabled: false,
        },
        {
          text: "Energy Optimization",
          url: "/GIANT-Operations-Suite/Energy-Optimization",
          Icon: GasMeterIcon,
          disabled: false,
        },
        {
          text: "Energy Cost Management",
          url: "/GIANT-Operations-Suite/Energy-Cost-Management",
          Icon: FoundationIcon,
          disabled: false,
        },
        {
          text: "Energy Forecasting",
          url: "/GIANT-Operations-Suite/Energy-Forecasting",
          Icon: WindPowerIcon,
          disabled: false,
        },
        {
          text: "Energy Consumption",
          url: "/GIANT-Operations-Suite/Energy-Consumption",
          Icon: HeatPumpIcon,
          disabled: false,
        },
        {
          text: "Settings",
          url: "/GIANT-Operations-Suite/Settings",
          Icon: AppsIcon,
          disabled: false,
        },
      ],
    },
    {
      text: "Maintenance",
      url: null,
      Icon: BuildOutlinedIcon,
      disabled: false,
      nested: [
        {
          text: "Machine Park",
          url: "/GIANT-Operations-Suite/Maintenance",
          Icon: AssignmentOutlinedIcon,
          disabled: false,
        },
        {
          text: "Maintenance Plan",
          url: "/GIANT-Operations-Suite/Maintenance-Plan",
          Icon: QueuePlayNextIcon,
          disabled: false,
        },
        {
          text: "Asset Monitoring",
          url: "/GIANT-Operations-Suite/Asset-Monitoring",
          Icon: InputIcon,
          disabled: false,
        },
        {
          text: "Maintenance Logs",
          url: "/GIANT-Operations-Suite/Maintenance-Logs",
          Icon: HomeRepairServiceIcon,
          disabled: false,
        },
        {
          text: "Spare Parts Management",
          url: "/GIANT-Operations-Suite/Spare-Parts-Management",
          Icon: ConstructionIcon,
          disabled: false,
        },
        {
          text: "Condition Monitoring",
          url: "/GIANT-Operations-Suite/Condition-Monitoring",
          Icon: ResetTvIcon,
          disabled: false,
        },
        {
          text: "Predictive Maintenance",
          url: "/GIANT-Operations-Suite/Predictive-Maintenance",
          Icon: InstallDesktopIcon,
          disabled: false,
        },
      ],
    },
    {
      text: "Organizer",
      url: null,
      Icon: RoomPreferencesIcon,
      disabled: false,
      nested: [
        {
          text: "Task Designer",
          url: "/GIANT-Operations-Suite/Task-Designer",
          Icon: DesignServicesIcon,
          disabled: false,
        },
        {
          text: "Work Instructions",
          url: "/GIANT-Operations-Suite/Work-Instructions",
          Icon: AssignmentTurnedInIcon,
          disabled: false,
        },
        {
          text: "Task Manager",
          url: "/GIANT-Operations-Suite/Task-Manager",
          Icon: ListAltIcon,
          disabled: false,
        },
        {
          text: "Kanban Board",
          url: "/GIANT-Operations-Suite/Kanban-Board",
          Icon: ViewKanbanIcon,
          disabled: false,
        },
        {
          text: "Workflow Modeler",
          url: "/GIANT-Operations-Suite/Workflow-Modeler",
          Icon: StreamIcon,
          disabled: false,
        },
        {
          text: "Measures Tracker",
          url: "/GIANT-Operations-Suite/Measures-Tracker",
          Icon: StraightenOutlinedIcon,
          disabled: false,
        },
      ],
    },
    {
      text: "Operational Excellence",
      url: null,
      Icon: DeveloperBoardOutlinedIcon,
      disabled: false,
      nested: [
        {
          text: "Production Management",
          url: null,
          Icon: ManageAccountsIcon,
          disabled: false,
          nested: [
            {
              text: "Production Orders",
              url: "/GIANT-Operations-Suite/Production-Orders",
              Icon: ShoppingCartIcon,
              disabled: false,
            },
            {
              text: "Production Planning",
              url: "/GIANT-Operations-Suite/Production-Planning",
              Icon: ManageHistoryIcon,
              disabled: false,
            },
            {
              text: "Production Control",
              url: "/GIANT-Operations-Suite/Production-Control",
              Icon: ChromeReaderModeOutlinedIcon,
              disabled: false,
            },
            {
              text: "Production Progress",
              url: "/GIANT-Operations-Suite/Production-Progress",
              Icon: LoopIcon,
              disabled: false,
            },
          ]
        },
        {
          text: "Resource Management",
          url: null,
          Icon: RequestQuoteIcon,
          disabled: false,
          nested: [
            {
              text: "Asset Utilization",
              url: "/GIANT-Operations-Suite/Asset-Utilization",
              Icon: CurrencyExchangeIcon,
              disabled: false,
            },
            {
              text: "Workforce Planning",
              url: "/GIANT-Operations-Suite/Workforce-Planning",
              Icon: PeopleOutlineIcon,
              disabled: false,
            },
            {
              text: "Material Resource Allocation",
              url: "/GIANT-Operations-Suite/Material-Resource-Allocation",
              Icon: SettingsApplicationsIcon,
              disabled: false,
            },
          ]
        },
        {
          text: "Product Definitions",
          url: null,
          Icon: TaskIcon,
          disabled: false,
          nested: [
            {
              text: "eBOM",
              url: "/GIANT-Operations-Suite/eBOM",
              Icon: CalculateIcon,
              disabled: false,
            },
            {
              text: "mBOM",
              url: "/GIANT-Operations-Suite/mBOM",
              Icon: DifferenceIcon,
              disabled: false,
            },
            {
              text: "Recipes",
              url: "/GIANT-Operations-Suite/Recipes",
              Icon: LocalLibraryIcon,
              disabled: false,
            },
          ]
        }
      ],
    },
    {
      text: "Quality Management",
      url: null,
      Icon: VerifiedIcon, 
      disabled: false,
      nested: [
        {
          text: "Quality Inspection Plan",
          url: "/GIANT-Operations-Suite/Quality-Inspection-Plan",
          Icon: TroubleshootIcon,
          disabled: false,
        },
        {
          text: "Certificates",
          url: "/GIANT-Operations-Suite/Certificates",
          Icon: AddTaskIcon,
          disabled: false,
        },
        {
          text: "Quality Parameters",
          url: "/GIANT-Operations-Suite/Quality-Parameters",
          Icon: SettingsSuggestIcon,
          disabled: false,
        },
        {
          text: "Quality Inspection Plan",
          url: "/GIANT-Operations-Suite/Quality-Inspection-Plan",
          Icon: CompareIcon,
          disabled: false,
        },
        {
          text: "Quality Reports",
          url: "/GIANT-Operations-Suite/Quality-Reports",
          Icon: WbIridescentIcon,
          disabled: false,
        },
        {
          text: "Traceability and Conformance",
          url: "/GIANT-Operations-Suite/Traceability-And-Conformance",
          Icon: DisplaySettingsIcon,
          disabled: false,
        },
        {
          text: "NCM",
          url: "/GIANT-Operations-Suite/NCM",
          Icon: SettingsRemoteIcon,
          disabled: false,
        },
      ],
    },
    {
      text: "Forms and Flows",
      url: null,
      Icon: AssignmentIcon,
      disabled: false,
      nested: [
        {
          text: "Forms Designer",
          url: "/GIANT-Operations-Suite/Forms-Designer",
          Icon: FeedOutlinedIcon,
          disabled: false,
        },
        {
          text: "Workflows",
          url: "/GIANT-Operations-Suite/Workflows",
          Icon: AccountTreeOutlinedIcon,
          disabled: false,
        },
        {
          text: "Work Manager",
          url: "/GIANT-Operations-Suite/Work-Manager",
          Icon: SplitscreenIcon,
          disabled: false,
        },
      ],
    },
    {
      text: "Layouts",
      url: null,
      Icon: HolidayVillageIcon,
      disabled: false,
      nested: [
        {
          text: "P&ID",
          url: "/GIANT-Operations-Suite/PID",
          Icon: SchemaIcon,
          disabled: false,
        },
        {
          text: "Plant Overview",
          url: "/GIANT-Operations-Suite/Plant-Overview",
          Icon: VillaIcon,
          disabled: false,
        },
      ],
    },
  ],
};

export const NAV_GIANT_TOOLBOX_MANAGER: LeftNavMenu = {
  title: LEFT_NAV_SECTION["GIANT Toolbox Manager"],
  list: [
    {
      text: "Integrator",
      url: null,
      Icon: IntegrationInstructionsOutlinedIcon,
      disabled: false,
      nested: [
        {
          text: "Data Sources",
          url: "/GIANT-Toolbox-Manager/Integrator/Data-Sources",
          Icon: FolderCopyOutlinedIcon,
          disabled: false,
        },
        {
          text: "Connections",
          url: "/GIANT-Toolbox-Manager/Integrator/Connections",
          Icon: FolderSharedOutlinedIcon,
          disabled: false,
        },
      ],
    },
    {
      text: "Data Manager",
      url: null,
      Icon: DescriptionOutlinedIcon,
      disabled: false,
      nested: [
        {
          text: "Entity Definition",
          url: "/GIANT-Toolbox-Manager/Entity-Definition",
          Icon: PictureInPictureAltIcon,
          disabled: false,
        },
        {
          text: "Forms and Manual Entries",
          url: "/GIANT-Toolbox-Manager/Forms-And-Manual-Entries",
          Icon: EditCalendarOutlinedIcon,
          disabled: false,
        },
        {
          text: "Parameters Management",
          url: "/GIANT-Toolbox-Manager/Parameters-Management",
          Icon: DriveFileRenameOutlineOutlinedIcon,
          disabled: false,
        },
        {
          text: "Data Engineering",
          url: "/GIANT-Toolbox-Manager/Data-Engineering",
          Icon: EngineeringIcon,
          disabled: false,
        },
        {
          text: "Rules",
          url: "/GIANT-Toolbox-Manager/Rules",
          Icon: RuleIcon,
          disabled: false,
        },
        {
          text: "Actions",
          url: "/GIANT-Toolbox-Manager/Actions",
          Icon: ArchitectureIcon,
          disabled: false,
        },
        {
          text: "Notifications",
          url: "/GIANT-Toolbox-Manager/Notifications",
          Icon: NotificationsNoneIcon,
          disabled: false,
        },
      ],
    },
    {
      text: "Admin Panel",
      url: null,
      Icon: SettingsIcon,
      disabled: false,
      nested: [
        {
          text: "User Manager",
          url: "/GIANT-Toolbox-Manager/User-Manager",
          Icon: AccountBoxOutlinedIcon,
          disabled: false,
        },
        {
          text: "Role Manager",
          url: "/GIANT-Toolbox-Manager/Role-Manager",
          Icon: ManageAccountsOutlinedIcon,
          disabled: false,
        },
        {
          text: "Security and Access Control",
          url: "/GIANT-Toolbox-Manager/Security-And-Access-Control",
          Icon: SecurityIcon,
          disabled: false,
        },
        {
          text: "System Configuration",
          url: "/GIANT-Toolbox-Manager/System-Configuration",
          Icon: SettingsInputSvideoIcon,
          disabled: false,
        },
        {
          text: "Process Criticality",
          url: "/GIANT-Toolbox-Manager/Process-Criticality",
          Icon: TaskOutlinedIcon,
          disabled: false,
        },
        {
          text: "Audit Trail",
          url: "/GIANT-Toolbox-Manager/Audit-Trail",
          Icon: SensorWindowIcon,
          disabled: false,
        },
      ],
    },
  ],
};

export const NAV_GIANT_ELPROM: LeftNavMenu = {
  title: NAV_GIANT_PLANNING.title,
  list: [
    {
      text: "Currencies",
      url: "/GIANT-Planning/Currencies",
      Icon: MonetizationOnIcon,
      disabled: false,
    },
    {
      text: "Project Hub",
      url: "/GIANT-Planning/project-hub-data",
      Icon: FolderCopyIcon,
      disabled: false,
    },
  ],
};
