import { IndicatorWidgetData } from "../EditExcellenceChartForms/excellenceChartFormUtils";

export const indicatorWidgetDefaultData: IndicatorWidgetData = {
  simpleIndicatorLiveData: {
    name: "Simulation.Random",
    parameterId: "2d67ae72-c4e8-4e11-bca6-a0c7b231a36b",
    timestamp: "2024-12-17T06:46:40.403",
    value: -1.005892
  }
};

export default indicatorWidgetDefaultData;
